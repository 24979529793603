import { Component, OnInit, Input, TemplateRef, Directive, OnDestroy } from '@angular/core';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Subscription } from 'rxjs';

@Directive()
export class Tile {
  @Input()
  tileTemplate: TemplateRef<any>;

  @Input()
  colspan: number;

  constructor() {
    this.colspan = 1;
  }

  get width(): number {
    return this.colspan * 175 + (this.colspan - 1) * 16;
  }

}

@Component({
  selector: 'app-tiles',
  templateUrl: './tiles.component.html',
  styleUrls: ['./tiles.component.css']
})
export class TilesComponent implements OnInit, OnDestroy {

  @Input()
  tiles: Tile[] = [];

  watcher: Subscription;
  gap: number;

  constructor(private mediaObserver: MediaObserver) { this.gap = 16; }

  ngOnDestroy(): void {
    this.watcher.unsubscribe();
  }

  ngOnInit(): void {
    this.watcher = this.mediaObserver.asObservable().subscribe((change: MediaChange[]) => {
      this.deriveGap();
    });
  }

  deriveGap() {
    if (this.mediaObserver.isActive('xs') || this.mediaObserver.isActive('sm')) {
      this.gap = 3;
    } else {
      this.gap = 16;
    }
  }

  applyGap(gap: number) {

  }

}
