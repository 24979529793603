import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
    HttpClient as B1HttpClient,
    HttpResponse as B1HttpResponse,
    BusinessOneClientFactory,
    BusinessOneCloud,
} from '@businessone-io/businessoneio';
import { AccountService } from "./account.service";
import { environment } from "../environments/environment";
import { OrganizationService } from "./organization.service";
import { interval } from "rxjs";

class HttpClientImpl implements B1HttpClient {
    private httpClient: HttpClient;

    constructor(httpClient: HttpClient) {
        this.httpClient = httpClient;
    }

    async request(
        url: string,
        method: 'POST' | 'GET' | 'PATCH' | 'DELETE',
        payload?: unknown,
        responseType?: 'json' | 'text' | 'arraybuffer',
        headers?: Map<string, string>
    ): Promise<B1HttpResponse> {
        if (!headers) {
            headers = new Map<string, string>();
        }

        let httpHeaders = new HttpHeaders();
        for (const keyValue of headers.entries()) {
            httpHeaders = httpHeaders.set(keyValue[0], keyValue[1]);
        }

        try {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const response = await this.httpClient
                .request(method, url, {
                    headers: httpHeaders,
                    observe: responseType === 'arraybuffer' ? 'body' : 'response',
                    responseType,
                    body: payload,
                })
                .toPromise();

            const responseHeaders = new Map<string, string>();
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (response.headers) {
                for (const key of response.headers.keys()) {
                    responseHeaders.set(key, response.headers.get(key));
                }
            }
            return {
                data: responseType === 'arraybuffer' ? response : response.body,
                headers: responseHeaders,
                status: response.status,
                error: false,
            };
        } catch (error) {
            if (error instanceof HttpErrorResponse) {
                const responseHeaders = new Map<string, string>();
                if (error.headers) {
                    for (const key of error.headers.keys()) {
                        const value = error.headers.get(key);
                        if (value !== null) {
                            responseHeaders.set(key, value);
                        }
                    }
                }
                return {
                    data: error.error,
                    headers: responseHeaders,
                    status: error.status,
                    error: true,
                };
            } else {
                throw error;
            }
        }
    }
}

@Injectable({
    providedIn: 'root',
})
export class APIService {

    private b1HttpClient: B1HttpClient;

    public pendingSupportTickets: number = 0;

    constructor(httpClient: HttpClient,
        private accountService: AccountService,
        private organizationService: OrganizationService,) {
        this.b1HttpClient = new HttpClientImpl(httpClient,);

        organizationService.organizationChanged.subscribe(() => {
            void this.refreshPendingSupportTickets();
        });

        if (organizationService.activeOrganization != null) {
            void this.refreshPendingSupportTickets();
        }

        /*
        interval(60000).subscribe(() => {
            void this.refreshPendingSupportTickets();
        });
        */
    }

    private createCloudClient(): BusinessOneCloud {
        return BusinessOneCloud.createClient(this.b1HttpClient, () => this.accountService.getBusinessOneIoAccessToken(), `https://api.${environment.apiBase}`);

    }

    private async refreshPendingSupportTickets(): Promise<void> {
        try {
            this.pendingSupportTickets = await this.queryPendingSupportTickets();
        }
        catch (error) {
            this.pendingSupportTickets = 0;
        }
    }

    public async queryPendingSupportTickets(): Promise<number> {
        const activeOrg = this.organizationService.activeOrganization?.id ?? null;
        if (activeOrg == null) {
            return 0;
        }
        const client = this.createCloudClient();
        const supportClient = client.createSupportClient();
        const results = await supportClient.findTickets({
            offset: 0,
            limit: 1,
            include: ['id'],
            organization: activeOrg,
            count: true,
            state: ['open', 'inprogress'],
            lastModifiedRole: this.accountService.account.isOperator ? 'customer' : 'support'
        });
        return (results as any).count ?? 0;
    }
}

