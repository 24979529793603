import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, Subscribable, Subscription } from 'rxjs';
import { License, OrganizationService } from './organization.service';
import { AccountService } from './account.service';

export interface AppRole {
    id: string;
    displayName: string;
    description: string;
}

export interface AppVariant {
    variant: string;
    name: string;
    description: string;
}

export interface App {
    id: string;
    applicationType: string;
    installationMode: string;
    name: string;
    description: string;
    isTenantApp: boolean;
    tenantInstallationUrl: string;
    accessUrl: string;
    accessUrlTest: string | null;
    licenses: License[];
    applicationRoles?: AppRole[];
    variants: { [key: string] : AppVariant} | null;
}

@Injectable({
    providedIn: 'root'
})
export class AppsService {

    public apps: App[] = [];

    public appsLoaded: Subject<App[]> = new Subject<App[]>();

    constructor(private httpClient: HttpClient, private organizationService: OrganizationService, private accountService: AccountService,@Inject(LOCALE_ID) public locale: string) {
        accountService.accountChanged.subscribe(_ => {
            if (accountService.account) {
                this.getAllApps().then(a => {
                    this.apps = a;
                    this.appsLoaded.next(this.apps);
                });
            }
        });
       /*if (accountService.account) {
           // this.getAllApps().then(a => this.apps = a);
        }*/
    }

    public async getAllApps(): Promise<App[]> {        
        return this.httpClient.get<App[]>(`/api/v1/apps?locale=${this.locale}`).toPromise();
    }

    public getInstallableApps(): App[] {
        if (!this.organizationService.activeOrganization) {
            return [];
        }
        return this.getAppsFromIds(this.organizationService.activeOrganization.availableApps).filter(
            a => a.installationMode !== 'None' && a.installationMode !== 'ConfigureOnly');
    }

    public getConfigureOnlyApps(): App[] {
        if (!this.organizationService.activeOrganization) {
            return [];
        }
        return this.getAppsFromIds(this.organizationService.activeOrganization.availableApps).filter(a => a.installationMode === 'ConfigureOnly');
    }

    public getAppsFromIds(ids: string[]): App[] {
        const result: App[] = [];
        for (const id of ids) {
            const app = this.apps.find(a => a.id === id);
            if (app) {
                result.push(app);
            }
        }
        return result;
    }

    public getAppFromId(id: string): App | null {
        const result: App[] = [];
        const app = this.apps.find(a => a.id === id);
        if (app) {
            return app;
        }
        return null;
    }

}
