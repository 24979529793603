<h1 i18n="login component|header" style="text-align: center;">Melden Sie sich wahlweise mit einer der folgenden Optionen an:
</h1>
<br fxHide.lt-sm>
<div fxLayout="row wrap" fxLayoutGap="20px" fxLayoutAlign="center start">
    
    <button (click)="signinMicrosoft()"  [disabled]="this.disabled"  mat-stroked-button class="microsoft">
        <div fxLayout="row" fxLayoutAlign="start center" style="height: 38px;">
            <img src="assets/ms-symbollockup_mssymbol_19.svg" style="height: 50%;" />
            <div i18n="login component|microsoft" style="margin-left: 12px;">Bei Microsoft anmelden</div>
        </div>
    </button>
    <button (click)="signinGoogle()" [disabled]="this.disabled" mat-stroked-button class="google">
        <div fxLayout="row" fxLayoutAlign="start center" style="height: 38px;">
            <img src="assets/btn_google_light_normal.svg" style="height: 100%;" />
            <div i18n="login component|google">Bei Google anmelden</div>
        </div>
    </button>
    <div class="linkedin"  (click)="signinLinkedIn()">
    </div>
    <div fxLayout="column" class="b1">
        <button (click)="createAccount()" [disabled]="this.disabled" style="height: 38px;" mat-stroked-button class="b1">
            <div fxLayout="row" fxLayoutAlign="start center" style="height: 38px;">
                <img src="assets/b1service.png" style="width: 20px;padding-right: 10px;">
                <div i18n="login component|create account">SAP Business One Account anlegen</div>
            </div>
        </button>
        <!--
        <p style="text-align: center;margin: 12px;">oder mit bestehendem</p>
        <button (click)="signinB1()" style="height: 41px;" mat-stroked-button class="b1">
            <div fxLayout="row" fxLayoutAlign="start center" style="height: 38px;">
                <div>SAP Business One Account anmelden</div>
            </div>
        </button> -->
    </div>
</div>