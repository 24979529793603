import { Component, Inject, LOCALE_ID } from '@angular/core';

@Component({
    selector: 'app-privacy',
    templateUrl: 'privacy.component.html',
    styleUrls: ['./privacy.component.css']
})
export class PrivacyComponent {

    constructor(@Inject(LOCALE_ID) public locale: string
    ) {
    }

    public scroll(id: string): void {
        const element = document.getElementById(id);
        element.scrollIntoView({ behavior: 'smooth' });
    }
}
