<h1 mat-dialog-title>Organisation Anlegen</h1>
<div mat-dialog-content fxLayout="column">
    <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput placeholder="Unternehmen" [value]="organization.displayName" (input)="organization.displayName = $event.target.value">
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-button mat-dialog-close>Abbrechen</button>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial [disabled]="!isValid()">Ok</button>
</div>