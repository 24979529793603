import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export class DailyBackupSchedule {
    public isScheduled: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class AdministrationService {

    constructor(private httpClient: HttpClient) { }

    public getBackupSchedule(): Observable<DailyBackupSchedule> {
        return this.httpClient.get<DailyBackupSchedule>('/api/v1/administration/dailyBackupStatus');
    }

    public scheduleDailyBackup(): Observable<any> {
        return this.httpClient.post<any>('/api/v1/administration/scheduleDailyBackup', null);
    }

    public unscheduleDailyBackup(): Observable<any> {
        return this.httpClient.post<any>('/api/v1/administration/unscheduleDailyBackup', null);
    }

    public cleanup(): Observable<any> {
        return this.httpClient.post<any>('/api/v1/administration/cleanup', null);
    }

    public stopTenants(): Observable<any> {
        return this.httpClient.post<any>('/api/v1/administration/stoptenants', null);
    }

    public startTenants(): Observable<any> {
        return this.httpClient.post<any>('/api/v1/administration/starttenants', null);
    }


    public cleanupTrial(): Observable<any> {
        return this.httpClient.post<any>('/api/v1/administration/trialCleanup', null);
    }

    public async checkServiceUnitQueues(): Promise<any> {
        return this.httpClient.post<any>('/api/v1/administration/checkserviceunitqueues', null).toPromise();
    }

    public async terminateInstance(instanceId: string): Promise<any> {
        return this.httpClient.post<any>('/api/v1/administration/terminateInstance', {  instanceId }).toPromise();
    }

}
