<div fxLayout="column" fxLayoutGap="32px">
    <app-tiles [tiles]="tiles">

        <ng-template #training>
            <div fxLayout="column" fxFill (click)="openTraining()" class="normalTile training">
                <div class="tileheader">
                </div>
                <div fxFlex="1 0" fxLayout="row">
                    <strong i18n="Training courses|Start page" style="margin-top: 60px;margin-left: 20px;">Schulungsangebot</strong>
                </div>
                <div fxLayout="row" class="tilefooter">
                    <mat-icon fxFlexAlign="start" i18n i18n-matTooltip="Training" matTooltip="Schulung">help_outline</mat-icon>
                    <div fxFlex="1 0"></div>
                    <div fxFlexAlign="end" fxLayout="row">
                        <span i18n="@@TileOpen">Öffnen</span>
                        <mat-icon>open_in_new</mat-icon>

                    </div>
                </div>
            </div>
        </ng-template>

        <ng-template #manuals>
            <div fxLayout="column" fxFill (click)="openManuals()" class="normalTile manuals">
                <div class="tileheader">
                </div>
                <div fxFlex="1 0" fxLayout="row">
                    <strong i18n="Training material|Start page" style="margin-top: 60px;margin-left: 20px;">Schulungsunterlagen und -videos</strong>
                </div>
                <div fxLayout="row" class="tilefooter">
                    <mat-icon fxFlexAlign="start" i18n i18n-matTooltip="Training material help|Start page" matTooltip="Schulungsunterlagen">help_outline</mat-icon>
                    <div fxFlex="1 0"></div>
                    <div fxFlexAlign="end" fxLayout="row">
                        <span i18n="@@TileOpen">Öffnen</span>
                        <mat-icon>open_in_new</mat-icon>

                    </div>
                </div>
            </div>
        </ng-template>

        <ng-template #demo>
            <div fxLayout="column" fxFill (click)="openOnlineDemo()" class="normalTile onlineDemo">
                <div class="tileheader">
                </div>
                <div fxFlex="1 0" fxLayout="row">
                    <strong i18n="Online Demo|Start page" style="margin-top: 60px;margin-left: 20px;">Online-Demo</strong>
                </div>
                <div fxLayout="row" class="tilefooter">
                    <mat-icon fxFlexAlign="start" i18n i18n-matTooltip="Online Demo|Start page" matTooltip="Online-Demo">help_outline</mat-icon>
                    <div fxFlex="1 0"></div>
                    <div fxFlexAlign="end" fxLayout="row">
                        <span i18n="@@TileOpen">Öffnen</span>
                        <mat-icon>open_in_new</mat-icon>
                    </div>
                </div>
            </div>
        </ng-template>

        <ng-template #quotation>
            <div fxLayout="column" fxFill (click)="openQuotation()" class="normalTile quotation">
                <div class="tileheader">
                </div>
                <div fxFlex="1 0" fxLayout="row">
                    <strong i18n="Quotation|Start page" style="margin-top: 60px;margin-left: 20px;">Preisanfrage</strong>
                </div>
                <div fxLayout="row" class="tilefooter">
                    <mat-icon fxFlexAlign="start" i18n i18n-matTooltip="Quotation|Start page" matTooltip="Preisanfrage">help_outline</mat-icon>
                    <div fxFlex="1 0"></div>
                    <div fxFlexAlign="end" fxLayout="row">
                        <span i18n="@@TileOpen">Öffnen</span>
                        <mat-icon>open_in_new</mat-icon>
                    </div>
                </div>
            </div>
        </ng-template>

    </app-tiles>

</div>