import { Component, OnInit, Inject, ViewChild, ElementRef, Input } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DatabaseService, Database, Extension } from '../database.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { FormControl } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { App } from '../apps.service';

@Component({
    selector: 'app-app-select',
    templateUrl: './app-selection.component.html',
    styleUrls: ['./app-selection.component.css']
})
export class AppSelectionComponent implements OnInit {

    appsCtrl = new FormControl();
    separatorKeysCodes: number[] = [ENTER, COMMA];

    selectedApps: App[];
    availableApps: App[];

    @Input()
    enabled: true;

    get allApps() {
        return this.availableApps;
    }

    @Input()
    set allApps(apps: App[]) {
        this.availableApps = apps;
        this.refreshFilter();
    }

    get selection() {
        return this.selectedApps;
    }

    @Input()
    set selection(apps: App[]) {
        this.selectedApps = apps;
        this.refreshFilter();
    }


    filteredApps: App[] = [];

    @ViewChild('appInput') appInput: ElementRef<HTMLInputElement>;

    ngOnInit(): void {
        this.refreshFilter();
    }

    constructor() {
        this.availableApps = [];
        this.selectedApps = [];

        this.appsCtrl.valueChanges.subscribe(() => this.refreshFilter());
    }

    remove(app: App) {
        const index = this.selectedApps.indexOf(app);

        if (index >= 0) {
            this.selectedApps.splice(index, 1);
            this.refreshFilter();
        }
    }

    addApp(app: App) {
        const index = this.selectedApps.findIndex(e => e.id === app.id);
        if (index !== -1) {
            return;
        }
        this.selectedApps.push(app);
        this.refreshFilter();
    }

    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        const inputValue = (value || '').trim();

        if (inputValue) {

            const match = this.availableApps.filter(e => e.name === inputValue);
            if (match.length === 1) {

                this.addApp(match[0]);

                if (input) {
                    input.value = '';
                }

                this.appsCtrl.setValue(null);
            }
        }
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        this.addApp(event.option.value);
        this.appsCtrl.setValue(null);
        this.appInput.nativeElement.blur();
        this.appInput.nativeElement.value = '';
    }

    refreshFilter() {
        this.filteredApps = this._filter(this.appsCtrl.value);
    }

    private _filter(value: any): any[] {
        let filterValue = '';
        if (typeof value === 'string') {
            filterValue = value;
        } else {
            filterValue = value?.name || '';
        }
        filterValue = filterValue.toLocaleLowerCase();

        return this.availableApps.filter(e => e.name.toLowerCase().indexOf(filterValue) === 0
            && this.selectedApps.indexOf(e) === -1);
    }

}