<div fxLayout="column" style="margin: 16px;">
    <span>Benutzername</span>
    <div fxLayout="row">
      <input matInput readonly="true" size="20" [value]="user" style="height: 20px;" fxFlexAlign="center"/>
      <button mat-icon-button (click)="copyUsername()">
        <mat-icon>content_copy</mat-icon>
      </button>
    </div>
    <span style="margin-top: 5px;">Passwort</span>
    <div fxLayout="row">
      <input matInput readonly="true" size="12" [value]="password" [type]="showPassword ? 'text' : 'password'"
        style="height: 20px;" fxFlexAlign="center" />
      <button mat-icon-button (click)="togglePassword()">
        <mat-icon>{{showPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
      <button mat-icon-button (click)="copyPassword()">
        <mat-icon>content_copy</mat-icon>
      </button>
      <button mat-icon-button (click)="resetCredentials()">
        <mat-icon>wifi_protected_setup</mat-icon>
      </button>
    </div>
  </div>