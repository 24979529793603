<h1 mat-dialog-title>Mitglied hinzufügen</h1>
<div mat-dialog-content fxLayout="column">
    <section fxLayout="column">
        <p>Name und Rolle festlegen</p>
        <div fxLayout="row wrap">
            <mat-form-field class="spacer" style="width: 200px;">
                <mat-label>Vorname</mat-label>
                <input matInput [(ngModel)]="data.firstName" required>
            </mat-form-field>
            <mat-form-field class="spacer" style="width: 200px;">
                <mat-label>Nachname</mat-label>
                <input matInput [(ngModel)]="data.lastName" required>
            </mat-form-field>
        </div>
        <div fxLayout="row wrap">
            <mat-form-field class="spacer" style="width: 200px;">
                <mat-label>Business One Benutzercode</mat-label>
                <input matInput [(ngModel)]="data.userCode" required>
            </mat-form-field>
            <mat-form-field class="spacer">
                <mat-label>Rolle</mat-label>
                <mat-select [(ngModel)]="data.role" [disabled]="!data.canEditRole">
                    <mat-option value="member">{{ 'member' | userRoleText }}</mat-option>
                    <mat-option value="administrator">{{ 'administrator' | userRoleText }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxLayout="row" class="spacer" style="height: 70px;">
            <!--<mat-form-field class="spacer" style="width: 300px;">
                <mat-label>Lizenz</mat-label>-->
                <app-license-select [allLicenses]="data.allLicenses" [selection]="data.licenses" style="width: 100%;" fxLayout="row" [enabled]="true"></app-license-select>
<!--                <mat-select allLicenses="" [(ngModel)]="data.license">
                    <mat-option value=""></mat-option>
                    <mat-option *ngFor="let l of data.licenses;" [value]="l.licensedProductSKU">{{  l.licensedProductSKU | businessOneLicenseText }} </mat-option>
                </mat-select>
            </mat-form-field> -->
        </div>
    </section>
    <!--
    <section fxLayout="column">
        <mat-checkbox #sendInvitation class="spacer" labelPosition="after" [(ngModel)]="data.sendInvitation">Einladung senden ?</mat-checkbox>
        <div fxLayout="row">
            <mat-form-field class="spacer" fxFill>
                <mat-label>E-Mail Adresse</mat-label>
                <input matInput [(ngModel)]="data.email">
            </mat-form-field>
        </div>
    </section>
-->
</div>
<div mat-dialog-actions>
    <button mat-button mat-dialog-close>Abbrechen</button>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial [disabled] = "!isValid()" >Ok</button>
</div>