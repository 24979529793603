import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { DatabaseService, MobileAccess } from '../database.service';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-config-crystal-dialog',
  templateUrl: 'config-crystal.component.html',
  styleUrls: ['./config-crystal.component.css'],
})
export class ConfigCrystalDialogComponent implements OnInit {
  public connectionString: string;
  public tenant: string;
  public serviceUnit: string;

  constructor(
    public dialogRef: MatDialogRef<ConfigCrystalDialogComponent>,
    private clipboard: Clipboard,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.tenant = data.tenant as string;
    this.serviceUnit = data.serviceUnit as string;
  }
  ngOnInit(): void {
    const server = this.serviceUnit === 'SU012' ? 'h03' : 'h04';
    this.connectionString = `DRIVER={B1CRHPROXY};SERVERNODE=${server}.cloud.conesprit.com:30013;DATABASENAME=${this.serviceUnit};DATABASE=${this.tenant}`;
  }

  copyConnectionString(): void {
    this.clipboard.copy(this.connectionString);
  }
}
