<ng-template #stepWelcomeTemplate>
    <div class="stepperContent">
        <div *ngIf="microsoftStoreToken == null">
            <div *ngIf="account == null" fxLayout="column" fxLayoutAlign="start center">
                <div [ngClass.gt-xs]="'step1top'" fxLayout="row">
                    <div fxLayout="column" [ngClass.gt-xs]="'topBox'" [ngClass.xs]="'topBoxSmall'">
                        <div fxLayout="column" style="background-color: rgba(77,103,126,0.7);" class="mat-elevation-z4">
                            <div style="background-color: rgb(77, 103,126);color: #F9FBFC;font-size: 16pt;font-weight: 500;padding: 15px;"
                                fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                <div class="cloudIcon">

                                </div>
                                <span i18n="@@signup_demo_system">SAP Business One Cloud
                                    Demosystem</span>
                            </div>
                            <div style="max-width: 800px;padding: 20px; color: #F9FBFC;" fxFlexAlign="center">
                                <h1 i18n="@@signup_create_demo_system" [ngClass.gt-xs]="'topBox'"
                                    [ngClass.lt-sm]="'topBoxSmall'">Mit diesem Wizard können Sie sich in drei
                                    einfachen
                                    Schritten
                                    eigenständig ein Demosystem
                                    einrichten und sofort mit Ihrem Browser loslegen</h1>
                            </div>
                        </div>
                        <div style="margin-top: 20px;" fxHide.gt-xs></div>
                        <button style="margin-top: 20px; border-radius: 10;" fxFlexAlign="end" fxHide.lt-sm
                            i18n="@@signup_stepper_next" mat-raised-button (click)="welcomeNext()">Weiter</button>
                    </div>
                </div>
                <div fxLayout="row" style="max-width:1200px;width: 100%;" fxLayouAlign="center start" fxHide.lt-sm>
                    <div fxLayout="row wrap" fxLayoutGap="30px grid" style="width: 100%;" fxLayoutAlign="center start">

                        <div style="height: 100%;max-height: 300px; background-color: transparent;">
                            <div fxLayout="column" class="box mat-elevation-z4" fxLayoutAlign="start center">
                                <div class="modules"></div>
                                <h2 style="text-align: center;" i18n="@@signup_modules">Module</h2>
                                <p style="text-align: center;" i18n="@@signup_modules_detail">
                                    Sie erhalten so Zugang zu den Modulen Einkauf, Verkauf, Finanzmanagement, Lager und
                                    Logistik, Produktion sowie CRM.
                                </p>
                            </div>
                        </div>

                        <div style="height: 100%;max-height: 300px;background-color: transparent;">
                            <div fxLayout="column" class="box mat-elevation-z4" fxLayoutAlign="start center">
                                <div class="cloud"></div>
                                <h2 style="text-align: center;" i18n="@@signup_module_cloud">Cloud System</h2>
                                <p style="text-align: center;" i18n="@@signup_module_cloud_detail">
                                    Für die Demo ist kein Download notwendig, da das Demosystem in der Microsoft Cloud
                                    Azure
                                    läuft und sich die Benutzeroberfläche direkt mit Ihrem Browser öffnet.
                                </p>
                            </div>
                        </div>

                        <div style="height: 100%;max-height: 300px;background-color: transparent;">
                            <div fxLayout="column" class="box mat-elevation-z4" fxLayoutAlign="start center">
                                <div class="demoimage"></div>
                                <h2 style="text-align: center;" i18n="@@signup_module_demo">Demosystem</h2>
                                <p style="text-align: center;" i18n="@@signup_module_demo_detail">
                                    Das Demosystem umfasst Demodaten zu Computerhardware und Zubehör. Sie erwartet ein
                                    Handelsunternehmen, in dem Sie Rechnungen schreiben, Lieferungen nachverfolgen,
                                    Wareneingänge verbuchen können und vieles mehr!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="margin-top: 130px;" fxHide.lt-sm></div>
                <div fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="20px">
                    <a i18n="@@link_imprint" [routerLink]="'/imprint'" mat-flat-button color="#F9FBFC">Impressum</a>
                    <a i18n="@@ling_privacy" [routerLink]="'/privacy'" mat-flat-button color="#F9FBFC">Datenschutz</a>
                </div>
            </div>
        </div>

        <div *ngIf="microsoftStoreToken != null" fxLayout="column" fxLayoutAlign="start center">
            <div *ngIf="microsoftResolvingToken === true">
                <div style="margin-top: 100px;" fxHide.lt-sm></div>
                <h1 style="text-align: center;">Willkommen in der SAP BUSINESS ONE CLOUD</h1>
                <br>
                <p style="text-align: center;">
                    Sie wurden vom Microsoft Marketplace weitergeleitet.
                </p>
                <p style="text-align: center;">
                    Einen Moment bitte...
                </p>
            </div>
            <div *ngIf="microsoftStoreTokenInvalid === true && microsoftResolvingToken === false">
                <div style="margin-top: 100px;" fxHide.lt-sm></div>
                <h1 style="text-align: center;">Willkommen beim BUSINESS ONE SERVICE</h1>
                <br>
                <p style="text-align: center;">
                    Ungültige Weiterleitung vom Microsoft Marketplace.
                </p>
                <p style="text-align: center;">
                    Bitte versuchen Sie es später wieder.
                </p>
            </div>
            <div *ngIf="account == null && microsoftStoreTokenInvalid === false && microsoftResolvingToken === false"
                fxLayout="column" fxLayoutAlign="start center">
                <div style="margin-top: 100px;" fxHide.lt-sm></div>
                <h1 style="text-align: center;">Willkommen beim BUSINESS ONE SERVICE</h1>
                <br>
                <p style="text-align: center;">
                    Sie wurden vom Microsoft Marketplace weitergeleitet.
                </p>
                <p style="text-align: center;">
                    {{ microsoftSubscription.subscriptionName }}
                </p>

                <p style="text-align: center;">Um mit der Einrichtung zu beginnen melden Sie sich bitte mit Ihrem
                    Microsoft Konto an:
                </p>
                <button (click)="signinMicrosoft()" style="height: 41px;" mat-stroked-button class="microsoft">
                    <div fxLayout="row" fxLayoutAlign="start center" style="height: 38px;">
                        <img src="assets/ms-symbollockup_mssymbol_19.svg" style="height: 50%;" />
                        <div style="margin-left: 12px;">Bei Microsoft anmelden</div>
                    </div>
                </button>

            </div>
        </div>
    </div>
</ng-template>


<!--
    <ng-template #stepWelcomeTemplate>
    <div class="stepperContent">
        <div *ngIf="microsoftStoreToken == null">
            <div *ngIf="account == null" fxLayout="column">
                <div fxLayout="row" fxLayoutAlign="center start">
                    <div class="desk" fxHide.lt-lg></div>
                    <div class="demo" fxLayout="column" fxFlexAlign="stretch" ngClass.xs="xs">
                        <div fxLayout="row" fxLayoutAlign="space-between">
                            <div class="sappartner"></div>
                            <div class="mspartner"></div>
                        </div>
                        <h1 style="text-align: center;">SAP Business One Demosystem</h1>
                        <h2 style="text-align: center;">30 Tage unverbindlich kostenlos testen</h2>
                        <br fxHide.lt-sm>
                        <p style="text-align: center;font-size: 18px; padding-left: 30px;padding-right: 30px;"
                            fxHide.lt-sm>In fünf Minuten zum eigenständigen Demosystem !</p>
                        <p style="text-align: center;font-size: 18px; padding-left: 50px;padding-right: 50px;"
                            fxHide.gt-xs>... in 5 Minuten</p>
                    </div>
                </div>
                <div style="margin-top: 20px;" fxHide.lt-sm></div>
                <div style="margin-top: 5px;" fxHide.gt-xs></div>
                <div style="max-width: 1200px;" fxFlexAlign="center">
                    <app-login></app-login>
                </div>
                <div style="margin-top: 20px;"></div>
                <div fxLayout="column" fxLayoutAlign="start center">
                    <h1 style="text-align: center;">Ihr SAP Business One Demosystem</h1>
                    <h2 style="text-align: center;">Bereitstellung in 5 Minuten</h2>
                    <div style="margin-top: 20px;" fxHide.lt-sm></div>
                    <div fxLayout="row wrap" fxLayoutGap="80px grid" style="max-width: 1200px;">
                        <div class="box" fxFlex="400px" fxLayout="column" fxLayoutAlign="start center">
                            <div class="cloud"></div>
                            <h2 style="text-align: center;">Cloud System</h2>
                            <p style="text-align: center;">
                                Für die Demo ist kein Download notwendig, da das Demosystem in der Microsoft Cloud Azure
                                läuft und sich die Benutzeroberfläche direkt mit Ihrem Browser öffnet.
                            </p>
                        </div>
                        <div class="box" fxFlex="400px" fxLayout="column" fxLayoutAlign="start center">
                            <div class="config"></div>
                            <h2 style="text-align: center;">Einfache Konfiguration</h2>
                            <p style="text-align: center;">
                                Die Konfiguration des ERPs erfolgt in einem Wizard. Für die Demo müssen lediglich drei
                                Schritte durchlaufen werden. Danach wird Ihr individuelles Demosystem innerhalb von
                                einer Minute bereitgestellt.
                            </p>
                        </div>
                        <div class="box" fxFlex="400px" fxLayout="column" fxLayoutAlign="start center">
                            <div class="demoimage"></div>
                            <h2 style="text-align: center;">Demosystem</h2>
                            <p style="text-align: center;">
                                Das Demosystem umfasst Demodaten zu Computerhardware und Zubehör. Sie erwartet ein
                                Handelsunternehmen, in dem Sie Rechnungen schreiben, Lieferungen nachverfolgen,
                                Wareneingänge verbuchen können und vieles mehr!
                            </p>
                        </div>
                        <div class="box" fxFlex="400px" fxLayout="column" fxLayoutAlign="start center">
                            <div class="access"></div>
                            <h2 style="text-align: center;">Zugangsarten</h2>
                            <p style="text-align: center;">
                                Nach der Bereitstellung können Sie SAP Business One wahlweise mit einem der folgenden
                                Clients öffnen: Web Client, Browser Access, mobile App oder Desktop Client (RDP).
                            </p>
                        </div>
                        <div class="box" fxFlex="400px" fxLayout="column" fxLayoutAlign="start center">
                            <div class="modules"></div>
                            <h2 style="text-align: center;">Module</h2>
                            <p style="text-align: center;">
                                Sie erhalten so Zugang zu den Modulen Einkauf, Verkauf, Finanzmanagement, Lager und
                                Logistik, Produktion sowie CRM.
                            </p>
                        </div>
                        <div class="box" fxFlex="400px" fxLayout="column" fxLayoutAlign="start center">
                            <div class="valid"></div>
                            <h2 style="text-align: center;">Gültigkeit</h2>
                            <p style="text-align: center;">
                                Die Testversion ist 30 Tage gültig.
                            </p>
                        </div>
                    </div>
                    <div fxLayout="row wrap" fxLayoutGap="80px grid" style="max-width: 1200px;">
                        <div class="box" fxFlex="400px" fxLayout="column" fxLayoutAlign="start center">
                            <div class="onlinedemo"></div>
                            <h2 style="text-align: center;">Online Demo</h2>
                            <p style="text-align: center;">
                                Sehr gerne zeigen wir Ihnen SAP Business One auch in einer individuellen <a
                                    href="https://business-one-consulting.com/service/online-demo/"
                                    target="_blank">online Demo.</a>
                            </p>
                        </div>
                    </div>
                    <div style="margin-top: 200px;" fxHide.lt-sm></div>
                    <div fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="20px">
                        <a [routerLink]="'/imprint'" mat-flat-button color="#F9FBFC">Impressum</a>
                        <a [routerLink]="'/privacy'" mat-flat-button color="#F9FBFC">Datenschutz</a>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="microsoftStoreToken != null" fxLayout="column" fxLayoutAlign="start center">
            <div *ngIf="microsoftResolvingToken === true">
                <div style="margin-top: 100px;" fxHide.lt-sm></div>
                <h1 style="text-align: center;">Willkommen beim BUSINESS ONE SERVICE</h1>
                <br>
                <p style="text-align: center;">
                    Sie wurden vom Microsoft Marketplace weitergeleitet.
                </p>
                <p style="text-align: center;">
                    Einen Moment bitte...
                </p>
            </div>
            <div *ngIf="microsoftStoreTokenInvalid === true && microsoftResolvingToken === false">
                <div style="margin-top: 100px;" fxHide.lt-sm></div>
                <h1 style="text-align: center;">Willkommen beim BUSINESS ONE SERVICE</h1>
                <br>
                <p style="text-align: center;">
                    Ungültige Weiterleitung vom Microsoft Marketplace.
                </p>
                <p style="text-align: center;">
                    Bitte versuchen Sie es später wieder.
                </p>
            </div>
            <div *ngIf="account == null && microsoftStoreTokenInvalid === false && microsoftResolvingToken === false"
                fxLayout="column" fxLayoutAlign="start center">
                <div style="margin-top: 100px;" fxHide.lt-sm></div>
                <h1 style="text-align: center;">Willkommen beim BUSINESS ONE SERVICE</h1>
                <br>
                <p style="text-align: center;">
                    Sie wurden vom Microsoft Marketplace weitergeleitet.
                </p>
                <p style="text-align: center;">
                    {{ microsoftSubscription.subscriptionName }}
                </p>

                <p style="text-align: center;">Um mit der Einrichtung zu beginnen melden Sie sich bitte mit Ihrem
                    Microsoft Konto an:
                </p>
                <button (click)="signinMicrosoft()" style="height: 41px;" mat-stroked-button class="microsoft">
                    <div fxLayout="row" fxLayoutAlign="start center" style="height: 38px;">
                        <img src="assets/ms-symbollockup_mssymbol_19.svg" style="height: 50%;" />
                        <div style="margin-left: 12px;">Bei Microsoft anmelden</div>
                    </div>
                </button>

            </div>
        </div>
    </div>
</ng-template>

-->

<ng-template #stepUserAndCompany>
    <form [formGroup]="companyFormGroup">

        <div class="stepperContent" fxLayout="column" fxLayoutAlign="start center">
            <div [ngClass.gt-xs]="'step2top'" fxLayout="row" fxHide.lt-sm>
                <div fxLayout="column" [ngClass.gt-xs]="'topBox'" [ngClass.xs]="'topBoxSmall'">
                    <div fxLayout="column" style="background-color: rgba(77,103,126,0.7);" class="mat-elevation-z4">
                        <div style="background-color: rgb(77, 103,126);color: #F9FBFC;font-size: 16pt;font-weight: 500;padding: 15px;"
                            fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                            <div class="cloudIcon">

                            </div>

                            <span i18n="@@signup_company">Unternehmensdaten</span>
                        </div>
                        <div style="margin-top: 20px;" fxHide.lt-sm></div>
                        <div style="margin-top: 5px;" fxHide.gt-xs></div>
                        <div style="max-width: 800px;padding: 20px;color: #F9FBFC;" fxFlexAlign="center">
                            <h1 [ngClass.gt-xs]="'topBox'" [ngClass.xs]="'topBoxSmall'" i18n="@@signup_company_details">
                                Diese Unternehmensdaten werden in Ihrem Demosystem für Sie hinterlegt</h1>
                        </div>
                    </div>
                    <button style="margin-top: 20px; border-radius: 10;" fxFlexAlign="end" i18n="@@signup_stepper_next"
                        mat-raised-button (click)="companyNext()">Weiter</button>
                </div>
            </div>

            <section *ngIf="activeOrganization !== null" style="margin-top: 20px;margin-bottom: 30px;">
                <div fxLayout="row" fxLayaoutAlign="center center">
                    <mat-icon class="icon" color="warn" style="font-size: 50px;">info</mat-icon>
                    <div fxLayaout="column" style="margin: 10px">
                        <h3 i18n="@@signup_demo_system_exists">Sie haben bereits ein Demo System angelegt</h3>
                        <p>{{ activeOrganization?.displayName }} </p>
                        <a i18n="@@signup_demo_system_exists_open_now" mat-raised-button color="warn" href="/">Jetzt
                            öffnen</a>
                    </div>
                </div>
            </section>
            <!--
            <section>
                <div fxLayout="row">
                    <div class="user" fxHide.lt-xs></div>
                    <div fxLayout="column">
                        <h2>Benutzer:</h2>
                        <div fxLayout="row wrap">
                            <td>
                                <mat-form-field>
                                    <mat-label>Vorname</mat-label>
                                    <input #firstName matInput placeholder="Max" formControlName="firstNameCtrl"
                                        required>
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field>
                                    <mat-label>Nachname</mat-label>
                                    <input #lastName matInput placeholder="Maier" formControlName="lastNameCtrl"
                                        required>
                                </mat-form-field>
                            </td>
                        </div>
                        <div fxLayout="row wrap">
                            <td>
                                <mat-form-field>
                                    <mat-label>Business One Benutzercode</mat-label>
                                    <input #b1user matInput placeholder="max.maier" formControlName="b1userCtrl"
                                        required>
                                </mat-form-field>
                            </td>
                            <td></td>
                        </div>
                    </div>
                </div>
            </section> -->
            <section style="margin-top: 57px;">
                <div fxLayout="row">
                    <div class="company" fxHide.lt-xs></div>
                    <div fxLayout="column">
                        <!--                        <mat-radio-group (change)="companyRadioChanged($event)">
                            <mat-radio-button value="demo" [checked]="true" style="margin-left: 16px;margin-top: 16px;">Demo Unternehmen
                            </mat-radio-button>
                            <mat-radio-button value="custom" style="margin-left: 16px;margin-top: 16px;">Eigenes Unternehmen
                            </mat-radio-button>-->
                        <div style="margin-top: 10px;"></div>
                        <div fxLayout="row" matAutocompleteOrigin #origin="matAutocompleteOrigin">
                            <td colspan="2">
                                <mat-form-field fxFill>
                                    <mat-label i18n="@@signup_address_company">Unternehmen</mat-label>
                                    <input #companyName type="text" matInput placeholder="Name"
                                        formControlName="nameCtrl" required [matAutocomplete]="autoCompany"
                                        [matAutocompleteConnectedTo]="origin">
                                </mat-form-field>
                                <mat-autocomplete #autoCompany="matAutocomplete"
                                    (optionSelected)="updateFromAutocomplete($event)"
                                    [displayWith]="displayCompanyAutocomplete">
                                    <mat-option *ngFor="let result of companyAutcompleteResults" [value]="result">
                                        {{result.description}}
                                    </mat-option>
                                </mat-autocomplete>
                            </td>
                        </div>
                        <div fxLayout="row wrap">
                            <td>
                                <mat-form-field>
                                    <mat-label i18n="@@ignup_address_street">Strasse</mat-label>
                                    <input #companyStreet matInput placeholder="Strasse" formControlName="streetCtrl">
                                </mat-form-field>
                            </td>
                        </div>
                        <div fxLayout="row wrap">
                            <td>
                                <mat-form-field>
                                    <mat-label i18n="@@signup_address_zip_code">Postleitzahl</mat-label>
                                    <input #companyPostCode matInput placeholder="12345" formControlName="postCodeCtrl">
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field>
                                    <mat-label i18n="@@signup_address_city">Ort</mat-label>
                                    <input #companyCity matInput placeholder="Stadt" formControlName="cityCtrl">
                                </mat-form-field>
                            </td>
                        </div>
                        <div fxLayout="row wrap">
                            <td>
                                <mat-form-field>
                                    <mat-label i18n="@@signup_country_code">Ländercode</mat-label>
                                    <input #companyCountryCode matInput placeholder="DE"
                                        formControlName="countryCodeCtrl">
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field>
                                    <mat-label i18n="@@signup_country">Land</mat-label>
                                    <input #companyCountry matInput placeholder="Deutschland"
                                        formControlName="countryCtrl">
                                </mat-form-field>
                            </td>
                        </div>
                        <div fxLayout="row wrap">
                            <td>
                                <mat-form-field>
                                    <mat-label i18n="@@signup_address_state">Bundesland</mat-label>
                                    <input #companyState matInput placeholder="DE" formControlName="stateCtrl">
                                </mat-form-field>
                            </td>
                        </div>
                        <!--                        </mat-radio-group> -->
                    </div>
                </div>
            </section>
        </div>
    </form>

</ng-template>


<ng-template #stepTemplate>
    <form [formGroup]="customizeTemplateFormGroup">
        <div class="stepperContent" fxLayout="column" fxLayoutAlign="start center">
            <h2 i18n="signup|select template" style="text-align: center;">Vorlage auswählen</h2>
            <div fxLayout="column">
                <!--
                <mat-checkbox #create labelPosition="after" [checked]="createTenant"
                    (change)="onCreateTenantChanged($event)">Mandant anlegen</mat-checkbox>
                -->
                <mat-radio-group>
                    <div fxLayout="row">
                        <div class="templatedemoicon" fxHide.lt-xs></div>
                        <mat-radio-button value="demo" [checked]="true" style="margin-left: 16px;margin-top: 16px;">
                        </mat-radio-button>
                        <div i18n="signup|demo template description" style="margin-top: 12px;">
                            <h3>Demosystem mit 30-tägiger Gültigkeit (empfohlen für Interessenten)</h3>
                            <p>Diese Vorlage beinhaltet Spieldaten aus der Branche Computerhandel</p>
                        </div>
                    </div>

                    <div fxLayout="row">
                        <div class="templateicon" fxHide.lt-xs></div>
                        <mat-radio-button value="template" [disabled]="true"
                            style="margin-left: 16px;margin-top: 16px;">
                        </mat-radio-button>
                        <div style="margin-top: 12px;" fxLayout="column" i18n="signup|template description">
                            <h3 style="word-wrap:break-word;">Vorkonfiguriertes Template (deaktiviert)</h3>
                            <mat-form-field style="width: 80%;">
                                <mat-label>Firmenname</mat-label>
                                <input #dbName matInput [required]="false" [readonly]="true" formControlName="nameCtrl">
                            </mat-form-field>
                            <div fxLayout="row wrap" style="height: 200px;">
                                <mat-form-field>
                                    <mat-label>Vorlage</mat-label>
                                    <mat-select #templateSelect value="skuva" formControlName="templateCtrl"
                                        [required]="false" [attr.disabled]="true" disableOptionCentering="true">
                                        <mat-option value="skuva">SKUVA</mat-option>
                                        <!--<mat-option value="template_1">Leeres Template</mat-option>-->
                                    </mat-select>
                                </mat-form-field>
                                <div class="spacer"></div>
                                <div>
                                    <div *ngIf="templateSelect.value == 'skuva'">
                                        <p>Start-up und kleine Unternehmen Variante A</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-radio-group>
            </div>
        </div>
    </form>


</ng-template>

<!--
<ng-template #stepReview 
    let-firstName="companyForm.get('firstNameCtrl')" 
    let-lastName="companyForm.get('lastNameCtrl')"
    let-b1user="companyForm.get('b1userCtrl')" let-companyStreet="companyForm.get('streetCtrl')"
    let-companyPostCode="companyForm.get('postCodeCtrl')" let-companyCity="companyForm.get('cityCtrl')"
    let-companyCountryCode="companyForm.get('countryCodeCtrl')" let-companyCountry="companyForm.get('countryCtrl')"
    let-companyState="companyForm.get('stateCtrl')">
    <form [formGroup]="reviewFormGroup">

        <div class="stepperContent" fxLayout="column" fxLayoutAlign="start center">

            <div *ngIf="!provisionStarted">
                
                <section fxLayout="row">
                    <div class="user" fxHide.lt-xs></div>
                    <div fxLayout="column">
                        <h2 class="spacer">Benutzer</h2>
                        <div fxLayout="row wrap">
                            <mat-form-field class="spacer" fxLayout="row wrap">
                                <mat-label>Vorname</mat-label>
                                <input matInput disabled [value]="firstName.value">
                            </mat-form-field>
                            <mat-form-field class="spacer" fxLayout="row wrap">
                                <mat-label>Nachname</mat-label>
                                <input matInput disabled [value]="lastName.value">
                            </mat-form-field>
                            <mat-form-field class="spacer" fxLayout="row wrap">
                                <mat-label>Business One Benutzercode</mat-label>
                                <input matInput disabled [value]="b1user.value">
                            </mat-form-field>
                        </div>
                    </div>
                </section>
                <section fxLayout="row">
                    <div class="company" fxHide.lt-xs></div>
                    <div fxLayout="column">

                        <h2 class="spacer">Unternehmen</h2>
                        <div fxLayout="column">
                            <mat-form-field class="spacer" fxLayout="row wrap">
                                <mat-label>Unternehmen</mat-label>
                                <input matInput disabled [value]="companyName">
                            </mat-form-field>
                            <mat-form-field class="spacer" fxLayout="row wrap">
                                <mat-label>Strasse</mat-label>
                                <input matInput disabled [value]="companyStreet.value">
                            </mat-form-field>
                            <div fxLayout="row wrap">
                                <mat-form-field class="spacer">
                                    <mat-label>Postleitzahl</mat-label>
                                    <input matInput disabled [value]="companyPostCode.value">
                                </mat-form-field>
                                <mat-form-field class="spacer">
                                    <mat-label>Ort</mat-label>
                                    <input matInput disabled [value]="companyCity.value">
                                </mat-form-field>
                            </div>
                            <div fxLayout="row wrap">
                                <mat-form-field class="spacer">
                                    <mat-label>Ländercode</mat-label>
                                    <input matInput disabled [value]="companyCountryCode.value">
                                </mat-form-field>
                                <mat-form-field class="spacer">
                                    <mat-label>Land</mat-label>
                                    <input matInput disabled [value]="companyCountry.value">
                                </mat-form-field>
                                <mat-form-field class="spacer">
                                    <mat-label>Bundesland</mat-label>
                                    <input matInput disabled [value]="companyState.value">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </section>

                <section fxLayout="row">
                    <div class="templatedemoicon" fxHide.lt-xs></div>
                    <div fxLayout="column">

                        <h2 class="spacer">Vorlage</h2>
                        <div fxLayout="column">
                            <div fxLayout="row wrap" *ngIf="createTenant">
                                <mat-form-field class="spacer">
                                    <mat-label>Name</mat-label>
                                    <input matInput disabled [value]="companyName">
                                </mat-form-field>
                                <mat-form-field class="spacer">
                                    <mat-label>Vorlage</mat-label>
                                    <input matInput disabled [value]="'Demosystem'">
                                </mat-form-field>
                            </div>
                            <div fxLayout="row wrap" *ngIf="!createTenant">
                                <p>Keinen Mandanten anlegen</p>
                            </div>
                        </div>
                    </div>
                </section>

            </div>

            <div *ngIf="provisionStarted && !provisionCompleted" style="width: 100%;">
                <p>Ihr Demosystem wird innerhalb einer Minute bereitgestellt.</p>
                <mat-progress-bar style="width: 100%;" mode="determinate" [value]="progress"
                    *ngIf="provisionCompleted == false">
                </mat-progress-bar>
                <video width="100%" autoplay loop>
                    <source src="https://stbusinessonetestcdn.blob.core.windows.net/signup/InstallationVideo.mp4"
                        type="video/mp4">
                </video>
            </div>
        </div>
    </form>

</ng-template> -->

<ng-template #stepReview let-firstName="companyForm.get('firstNameCtrl')" let-lastName="companyForm.get('lastNameCtrl')"
    let-b1user="companyForm.get('b1userCtrl')" let-companyStreet="companyForm.get('streetCtrl')"
    let-companyPostCode="companyForm.get('postCodeCtrl')" let-companyCity="companyForm.get('cityCtrl')"
    let-companyCountryCode="companyForm.get('countryCodeCtrl')" let-companyCountry="companyForm.get('countryCtrl')"
    let-companyState="companyForm.get('stateCtrl')">
    <form [formGroup]="reviewFormGroup">

        <div class="stepperContent" fxLayout="column" fxLayoutAlign="start center">

            <div [ngClass.gt-xs]="locale === 'de' ? 'step3top' : 'step3top_en'" fxLayout="row">
                <div fxLayout="column" [ngClass.gt-xs]="'topBox'" [ngClass.xs]="'topBoxSmall'">
                    <div fxLayout="column" style="background-color: rgba(77,103,126,0.7);" class="mat-elevation-z4">
                        <div style="background-color: rgb(77, 103,126);color: #F9FBFC;font-size: 16pt;font-weight: 500;padding: 15px;"
                            fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                            <div class="cloudIcon">

                            </div>

                            <span i18n="@@signup_demo_login" *ngIf="!provisionStarted && !loggedIn">Am Demosystem anmelden</span>
                            <span i18n="@@signup_demo_creating" *ngIf="provisionStarted">Demosystem wird eingerichtet</span>
                        </div>
                        <div style="margin-top: 20px;" fxHide.lt-sm></div>
                        <div style="margin-top: 5px;" fxHide.gt-xs></div>
                        <div style="max-width: 800px;padding: 20px;color: #F9FBFC;" fxFlexAlign="center">
                            <h1 *ngIf="!provisionStarted && !loggedIn" style="text-align: center;" [ngClass.gt-xs]="'topBox'" [ngClass.xs]="'topBoxSmall'"
                                i18n="@@signup_demo_login_details">Bestätigen Sie die Datenschutzbestimmungen und melden
                                Sie sich mit einer der angegebenen Optionen an Ihrem Demosystem an</h1>

                            <div *ngIf="provisionStarted" fxLayout="column">
                                <p i18n="@@signup_demo_system_creating" style="font-size: 14pt;">Ihr Demosystem wird für
                                    die erste Anmeldung
                                    vorbereitet und steht
                                    innerhalb einer Minute zu Ihrer
                                    Verfügung.</p>
                                <mat-progress-bar class="green-progress" style="width: 100%;" mode="determinate"
                                    [value]="progress" *ngIf="provisionCompleted == false">
                                </mat-progress-bar>
                                <div style="margin-top: 30px;"></div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="!provisionStarted && !loggedIn" fxLayout="column" fxLayoutAlign="start center">
                <div style="margin-top: 50px;" fxHide.lt-sm></div>
                <div style="margin-top: 10px;" fxHide.gt-xs></div>
                <div fxLayout="row" fxLayoutGap="20px">
                    <mat-checkbox [checked]="dataProtection" (change)="dataProtection = $event.checked">
                    </mat-checkbox>
                    <div i18n="@@signup_accept_dataprotection">
                        <div class="privacy">Hiermit erklären Sie sich mit
                            unseren </div> <a href="./privacy" target="_blank"
                            class="privacy">Datenschutzbestimmungen</a><span class="privacy"> einverstanden</span>
                    </div>
                </div>
                <div style="margin-top: 50px;" fxHide.lt-sm></div>
                <div style="margin-top: 5px;" fxHide.gt-xs></div>
                <app-login [disabled]="!dataProtection"></app-login>
            </div>

            <div *ngIf="!provisionStarted && loggedIn && accountChecked">
                <section *ngIf="activeOrganization !== null" style="margin-top: 20px;margin-bottom: 30px;">
                    <div fxLayout="row" fxLayaoutAlign="center center">
                        <mat-icon class="icon" color="warn" style="font-size: 50px;">info</mat-icon>
                        <div fxLayaout="column" style="margin: 10px">
                            <h3 i18n="@@signup_demo_system_exists">Sie haben bereits ein Demo System angelegt</h3>
                            <p>{{ activeOrganization?.displayName }} </p>
                            <a i18n="@@signup_demo_system_exists_open_now" mat-raised-button color="warn" href="/">Jetzt
                                öffnen</a>
                        </div>
                    </div>
                </section>
                <button i18n="@@signup_start_provisioning" color="primary" style="margin-top: 30px;" mat-raised-button
                    [disabled]="provisionStarted" (click)="provision()">Bereitstellung
                    starten</button>
            </div>
            <!--
            <div *ngIf="provisionStarted && !provisionCompleted" style="width: 100%;">
                <video width="100%" autoplay controls>
                    <source
                        src="https://stbusinessonetestcdn.blob.core.windows.net/signup/campaigns/lcgk/LCGK_720p_en.mp4"
                        type="video/mp4">
                </video>                            
            </div>-->
        </div>
    </form>

</ng-template>

<div fxLayout="row" fxLayoutAlign="center start">
    <mat-vertical-stepper linear #stepperVertical class="signup" (selectionChange)="onStepChange($event)" fxFill
        fxHide.gt-xs style="background-color: white;">
        <mat-step [completed]="true" [editable]="!provisionStarted && !loggedIn">
            <ng-template i18n="@@signup_stepper_welcome" matStepLabel>Willkommen</ng-template>
            <ng-container *ngTemplateOutlet="stepWelcomeTemplate"></ng-container>
        </mat-step>

        <!--
        <mat-step [stepControl]="customizeTemplateFormGroup" [editable]="!provisionStarted">
            <ng-template i18n="signup|stepper template" matStepLabel>Vorlage auswählen</ng-template>
            <ng-container *ngTemplateOutlet="stepTemplate"></ng-container>
        </mat-step>
        -->

        <mat-step [stepControl]="companyFormGroup" [editable]="!provisionStarted">
            <ng-template i18n="@@signup_stepper_company" matStepLabel>Unternehmen</ng-template>
            <ng-container *ngTemplateOutlet="stepUserAndCompany"></ng-container>
        </mat-step>

        <mat-step [stepControl]="reviewFormGroup" [editable]="!provisionStarted">
            <ng-template i18n="signup|stepper test" matStepLabel>Testen</ng-template>
            <ng-container *ngTemplateOutlet="stepReview; context: { companyForm: companyFormGroup }"></ng-container>
            <div *ngIf="isVertical && provisionStarted && !provisionCompleted" style="width: 100%;">
                <!--
                <video width="100%" autoplay controls preload="none" fxHide.gt-xs>
                    <source
                        src="https://stbusinessonetestcdn.blob.core.windows.net/signup/campaigns/aec/aec_long_version_1080p.mp4"
                        type="video/mp4">
                </video>
            -->
            </div>
            <!--
            <div fxLayout="row" fxLayoutAlign="space-between center" *ngIf="!provisionStarted">
                <button color="primary" mat-raised-button [disabled]="provisionStarted"
                    (click)="provision()">Bereitstellung
                    starten</button>
            </div>
            <div *ngIf="provisionStarted" style="height: 100px;">

            </div>
            -->
        </mat-step>
    </mat-vertical-stepper>

    <mat-horizontal-stepper linear #stepperHorizontal class="signup_horizontal" (selectionChange)="onStepChange($event)"
        fxHide.lt-sm>
        <mat-step [completed]="true" [editable]="!provisionStarted && !loggedIn">
            <ng-template i18n="@@signup_stepper_welcome" matStepLabel>Willkommen</ng-template>
            <div class="stepperContentHorizontal">
                <ng-container *ngTemplateOutlet="stepWelcomeTemplate"></ng-container>
            </div>
            <div style="height: 20px;">
                &nbsp;
            </div>
        </mat-step>

        <!--
        <mat-step [stepControl]="customizeTemplateFormGroup" [editable]="!provisionStarted">
            <ng-template i18n="signup|stepper template" matStepLabel>Vorlage auswählen</ng-template>
            <div class="stepperContentHorizontal">
                <ng-container *ngTemplateOutlet="stepTemplate"></ng-container>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center">
                <button i18n="signup|stepper next" color="primary" mat-raised-button matStepperNext>Weiter</button>
            </div>
        </mat-step>
    -->

        <mat-step [stepControl]="companyFormGroup" [editable]="!provisionStarted">
            <ng-template i18n="@@signup_stepper_company" matStepLabel>Unternehmen</ng-template>
            <div class="stepperContentHorizontal">
                <ng-container *ngTemplateOutlet="stepUserAndCompany"></ng-container>
            </div>
            <div style="height: 56px;">
                &nbsp;
            </div>
        </mat-step>


        <mat-step [stepControl]="reviewFormGroup" [editable]="!provisionStarted">
            <ng-template i18n="@@signup_stepper_test" matStepLabel>Testen</ng-template>
            <div class="stepperContentHorizontal">
                <ng-container *ngTemplateOutlet="stepReview; context: { companyForm: companyFormGroup }"></ng-container>
            </div>
            <div *ngIf="isHorizontal && provisionStarted && !provisionCompleted" style="width: 100%;">                
                <video width="100%" autoplay controls preload="none" fxHide.lt-sm>
                    <source
                        src="https://stbusinessonetestcdn.blob.core.windows.net/signup/campaigns/aec/aec_long_version_1080p.mp4"
                        type="video/mp4">
                </video>
            </div>
            <div style="height: 194px;">
                &nbsp;
            </div>
            <!--
            <div fxLayout="row" fxLayoutAlign="space-between center" *ngIf="!provisionStarted">
                <button color="primary" mat-raised-button matStepperPrevious
                    [disabled]="provisionStarted">Zurück</button>
                <button color="primary" mat-raised-button [disabled]="provisionStarted"
                    (click)="provision()">Bereitstellung
                    starten</button>
            </div>
            -->
        </mat-step>
    </mat-horizontal-stepper>

</div>