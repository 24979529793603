import { Component, OnInit } from '@angular/core';
import { OrganizationService } from '../organization.service';
import { DatabaseService } from '../database.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { AccountService, Account } from '../account.service';


@Component({
    selector: 'app-access-key',
    templateUrl: './access-key.component.html',
    styleUrls: ['./access-key.component.css']
})
export class AccessKeyComponent implements OnInit {

    showAccessKey: boolean;
    accessKey: string;

    public get account(): Account {
        return this.accountService.account;
      }

    public get domain(): string {
        const d = this.accountService.account?.member?.adUser?.domain;
        if (d === null) {
            return 'BUSINESS-ONE';
        }
        return d;
    }

    public get user(): string {
        const d = this.accountService.account?.member?.adUser?.name;
        if (d === null) {
            return '';
        }
        return d;
    }


    constructor(private accountService: AccountService, private orgService: OrganizationService, private clipboard: Clipboard
    ) {
    }

    ngOnInit(): void {


    }

    toggleAccessKey() {
        if (!this.showAccessKey) {
            this.orgService.loadAccessKey().toPromise().then(k => {
                this.accessKey = k;
                this.showAccessKey = true;
            });
        } else {
            this.accessKey = 'xxxxxxxxxxxx';
            this.showAccessKey = false;
        }
    }  

    resetAccessKey() {
        this.orgService.resetAccessKey().toPromise().then(k => {
            this.accessKey = 'xxxxxxxxxxxx';
            this.showAccessKey = false;
        }).catch(err => alert(err));
    }

    copyAccessKey() {
        this.orgService.loadAccessKey().toPromise().then(k => {
            this.clipboard.copy(k);
        });
    }

    copyAccessID() {
        this.clipboard.copy( this.domain + '\\' + this.user );
    }

}
