
import { Injectable, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, Subscription } from 'rxjs';
import { delay, tap, repeat } from 'rxjs/operators';


export class OperationStatus {
    id: string;
    isRunning: boolean;
    success: boolean;
    failed: boolean;
    errorMessage: string;
}

export class Operation {
    id: string;
    status: OperationStatus;
    state: any;
    text: string;
    completed: EventEmitter<Operation>;
}

@Injectable({
    providedIn: 'root'
})
export class OperationsService {

    private m_activeOperations: Map<string, Operation> = new Map();
    
    private m_operations: Operation[] = [];

    private polling: Subscription = null;

    public operationStatusUpdate = new EventEmitter<Operation>();

    constructor(private httpClient: HttpClient) {
    }

    private refreshStatus(): void {
        if (this.m_activeOperations.size === 0) {
            this.polling.unsubscribe();
            this.polling = null;
            return;
        }
        for (const [key, value] of this.m_activeOperations) {
            this.httpClient.get<OperationStatus>('/api/v1/operations/' + key + '/status').toPromise().then(s => {
                if (value.status === null || (
                    value.status.isRunning !== s.isRunning
                )) {
                    value.status = s;
                    this.operationStatusUpdate.emit(value);
                    if (!value.status.isRunning) {
                        this.m_activeOperations.delete(key);
                        value.completed.emit(value);
                    }
                }
            });
        }
    }

    public get activeOperations(): Operation[] {
        return Array.from( this.m_activeOperations.values());
    }

    public get operations(): Operation[] {
        return this.m_operations;
    }

    public register(initial: OperationStatus, state: any, text: string): Operation {
        if (!this.m_activeOperations.has(initial.id)) {
            const op = new Operation();
            op.id = initial.id;
            op.status = initial;
            op.state = state;
            op.text = text;
            op.completed = new EventEmitter<Operation>();
            this.m_activeOperations.set(initial.id, op);
            this.m_operations.push(op);
            this.operationStatusUpdate.emit(op);
            if (this.polling == null) {
                const poll = of({}).pipe(delay(1000), tap(_ => this.refreshStatus()), repeat());
                this.polling = poll.subscribe();
            }
            return op;
        }
        else {
            return this.m_activeOperations.get(initial.id);
        }

    }
}
