import { Injectable, OnDestroy, OnInit, EventEmitter } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { Subscription } from 'rxjs';
import {
  OrganizationService,
  OrganizationMember,
} from './organization.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { AuthenticationResult } from '@azure/msal-browser';
import { filter } from 'rxjs/operators';

export class Account {
  public name: string;
  public username: string;
  public domain: string | null;
  public email: string | null;
  public displayName: string;
  public signedInWithMicrosoftAccount: boolean;
  public microsoftTenantId: string;
  public firstName: string;
  public lastName: string;
  public isOperator: boolean;
  public member: OrganizationMember;
  public id: string;
  public homeAccountId: string;

  public get isOwner(): boolean {
    return this.member?.role === 'owner';
  }

  public get isAdministrator(): boolean {
    return this.member?.role === 'administrator';
  }
}

export enum AccountType {
  Microsoft = 1,
  Google = 2,
  LinkedIn = 3,
  BusinessOne = 4,
}

export interface AccountOption {
  name: string;
  firstName: string;
  lastName: string;
  email?: string;
  isActive: boolean;
  id: string;
}

@Injectable({
  providedIn: 'root',
})
export class AccountService implements OnDestroy {
  private subscription: Subscription;
  private mAccountOptions: AccountOption[] = [];

  constructor(
    private broadcastService: MsalBroadcastService,
    private msalService: MsalService,
    private orgService: OrganizationService,
    private httpClient: HttpClient
  ) {
    this.subscription = broadcastService.msalSubject$.subscribe((payload) => {
      console.log(payload);
      if (payload.eventType === 'msal:loginSuccess') {
        const authPayload = payload.payload as AuthenticationResult;
        this.msalService.instance.setActiveAccount(authPayload.account);
        void this.finishLogin();
      }
    });
    broadcastService.inProgress$
      .pipe(filter((i) => i === 'none'))
      .subscribe((i) => this.rebuildAccountOptions());

    orgService.organizationChangedInit.subscribe(() => {
      const members = this.orgService.activeOrganization.members.filter(
        (m) => m.accountId === this.mAccount.id
      );
      if (members.length === 0) {
        this.mAccount.member = null;
      } else {
        console.log(`set active member ${members[0].id}`);
        this.mAccount.member = members[0];
      }
    });

    this.rebuildAccountOptions();
    void this.finishLogin();
  }

  private rebuildAccountOptions() {
    const active = this.msalService.instance.getActiveAccount();
    const allAccounts = this.msalService.instance.getAllAccounts();
    this.mAccountOptions = allAccounts.map((a) => ({
      name: a.name ?? '',
      email: a.idTokenClaims?.email as string,
      firstName: (a.idTokenClaims?.given_name as string) ?? '',
      lastName: (a.idTokenClaims?.family_name as string) ?? '',
      isActive: active != null && active.homeAccountId == a.homeAccountId,
      id: a.homeAccountId,
    }));
    console.debug(this.mAccountOptions);
  }

  private async finishLogin(): Promise<void> {
    if (await this.validateAccount()) {
      await this.initializeAccount();
    }
    this.rebuildAccountOptions();
    this.accountChanged.emit(this.account);
  }

  private async validateAccount(): Promise<boolean> {
    const active = this.msalService.instance.getActiveAccount();
    if (active == null) {
      return false;
    }
    try {
      const result = await this.msalService.instance.acquireTokenSilent({
        scopes: [environment.clientId],
      });
      if (result?.accessToken != null) {
        return true;
      }
    } catch (error) {
      console.debug(error);
      this.msalService.instance.setActiveAccount(null);
    }
  }

  private async initializeAccount(): Promise<void> {
    const active = this.msalService.instance.getActiveAccount();
    if (active == null) {
      return;
    }
    this.mAccount = new Account();
    this.mAccount.name = active.name;
    this.mAccount.username = active.username;
    this.mAccount.domain = active.idTokenClaims.domain as string;
    this.mAccount.email = active.idTokenClaims.email as string;
    this.mAccount.firstName = active.idTokenClaims.given_name as string;
    this.mAccount.lastName = active.idTokenClaims.family_name as string;
    this.mAccount.signedInWithMicrosoftAccount = false;
    this.mAccount.id = active.localAccountId;
    this.mAccount.homeAccountId = active.homeAccountId;
    const operatorClaim = active.idTokenClaims.isOperator as unknown;
    this.mAccount.isOperator = operatorClaim === true;
    if (active.idTokenClaims.idp !== undefined) {
      this.mAccount.signedInWithMicrosoftAccount =
        active.idTokenClaims.idp.startsWith(
          'https://login.microsoftonline.com/'
        );
    }
    if (this.mAccount.signedInWithMicrosoftAccount) {
      this.mAccount.microsoftTenantId = active.idTokenClaims.tid;
    }

    const givenName = active.idTokenClaims.given_name as string;
    const lastName = active.idTokenClaims.family_name as string;

    if (givenName == null && lastName == null) {
      this.mAccount.displayName = active.name;
    } else {
      this.mAccount.displayName = givenName + ' ' + lastName;
    }
    await this.orgService.selectDefaultCompany();
    if (this.orgService.activeOrganization != null) {
      const members = this.orgService.activeOrganization.members.filter(
        (m) => m.accountId === active.localAccountId
      );
      if (members.length === 0) {
        this.mAccount.member = null;
      } else {
        this.mAccount.member = members[0];
      }
    }
  }

  public accountChanged = new EventEmitter<Account>();

  public get availableAccounts(): AccountOption[] {
    return this.mAccountOptions;
  }

  public get canMaintainOrganization(): boolean {
    return (
      this.account?.isOperator ||
      (this.account?.isOwner &&
        this.orgService.activeOrganization.appAuthorizationsEnabled === true)
    );
  }

  private mAccount: Account = null;
  public get account(): Account {
    return this.mAccount;
  }

  public set account(v: Account) {
    this.mAccount = v;
  }

  public async loginAccount(account: AccountOption): Promise<void> {
    let ai = this.msalService.instance.getAccountByHomeId(account.id);
    if (ai == null) {
      return;
    }
    try {
      const result = await this.msalService
        .loginPopup({
          account: ai,
          scopes: [],
        })
        .toPromise();
      ai = this.msalService.instance.getAccountByHomeId(account.id);
      if (ai != null) {
        this.msalService.instance.setActiveAccount(ai);
      }
      await this.finishLogin();
    } catch (error) {
      if (error.errorMessage.indexOf('AADB2C90118') > -1) {
        try {
          await this.msalService
            .loginPopup({
              account: ai,
              scopes: [],
              authority: environment.authorityPasswordReset,
            })
            .toPromise();
        } catch (error) {
          console.debug(error);
        }
      }
    }
  }

  public async removeAccount(account: AccountOption): Promise<void> {
    const ai = this.msalService.instance.getAccountByHomeId(account.id);
    if (ai != null) {
      await this.msalService.instance.logoutPopup({
        account: ai,
      });
    }
  }

  public async login(domain: string): Promise<void> {
    if (domain == null) {
      try {
        const result = await this.msalService
          .loginPopup({
            scopes: [],
            prompt: 'select_account',
          })
          .toPromise();
        if (result.account != null) {
          this.msalService.instance.setActiveAccount(result.account);
          await this.finishLogin();
        }
      } catch (error) {
        if (error.errorMessage.indexOf('AADB2C90118') > -1) {
          try {
            const result = await this.msalService
              .loginPopup({
                scopes: [],
                authority: environment.authorityPasswordReset,
              })
              .toPromise();
            if (result.account != null) {
              this.msalService.instance.setActiveAccount(result.account);
              await this.finishLogin();
            }
          } catch (error) {
            console.debug(error);
          }
        }
      }
    } else {
      try {
        const result = await this.msalService
          .loginPopup({
            scopes: [],
            prompt: 'select_account',
            extraQueryParameters: { domain_hint: domain },
          })
          .toPromise();
        if (result.account != null) {
          this.msalService.instance.setActiveAccount(result.account);
          await this.finishLogin();
        }
      } catch (error) {
        console.debug(error);
      }
    }
  }

  async logout(): Promise<void> {
    try {
      await this.httpClient.get(environment.idpLogoutUri).toPromise();
    } catch (error) { }
    try {
      await this.msalService
        .logoutPopup({
          account: this.msalService.instance.getActiveAccount(),
        })
        .toPromise();
      this.msalService.instance.setActiveAccount(null);
      window.location.reload();
    } catch (error) {
      console.debug(error);
    }
  }

  public async getBusinessOneIoAccessToken(): Promise<string | null> {
    if (this.account == null) {
      return null;
    }
    const activeAccount = this.msalService.instance.getActiveAccount();
    if (activeAccount == null) {
      return null;
    }
    try {
      const tokenResponse = await this.msalService.instance.acquireTokenSilent({
        scopes: environment.apiScopes,
        account: activeAccount,        
      });
      return tokenResponse.accessToken;
    } catch (error) {
      console.info("Failed to get access token silently");
    }
    // refresh token maybe expired: try ssoSilent
    try {
      const tokenResponse = await this.msalService.instance.ssoSilent({
        scopes: environment.apiScopes,
        account: activeAccount,
        loginHint: activeAccount.username,
        domainHint: activeAccount.idTokenClaims.domain as string ?? undefined,
      });
      if (tokenResponse.account.homeAccountId !== activeAccount.homeAccountId) {
        console.info("Unexpected homeAccountId");
      } else {
        return tokenResponse.accessToken;
      }
    } catch (error) {
      console.info("Failed to get access token silently (ssoSilent)");
    }
    return null;
  }

  signup(): void {
    this.msalService.loginPopup({
      scopes: [],
      authority: environment.authoritySignUp,
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
  }
}
