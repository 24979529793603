import { Component, Inject, LOCALE_ID, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { OrganizationService } from '../organization.service';
import { Tile } from '../tiles/tiles.component';

@Component({
  selector: 'app-common-tiles',
  templateUrl: './common-tiles.component.html',
  styleUrls: ['./common-tiles.component.css']
})
export class CommonTilesComponent implements OnInit {

  @ViewChild('training', { static: true })
  private trainingTpl: TemplateRef<any>;

  @ViewChild('manuals', { static: true })
  private manualsTpl: TemplateRef<any>;

  @ViewChild('demo', { static: true })
  private demoTpl: TemplateRef<any>;

  @ViewChild('quotation', { static: true })
  private quotationTpl: TemplateRef<any>;

  tiles: Tile[] = [];

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private orgService: OrganizationService) { }

  ngOnInit(): void {
    const t = new Tile();
    t.tileTemplate = this.trainingTpl;
    t.colspan = 2;
    this.tiles.push(t);

    const m = new Tile();
    m.tileTemplate = this.manualsTpl;
    m.colspan = 2;
    this.tiles.push(m);

    if (this.orgService.activeOrganization?.purpose === 'Trial') {
      const d = new Tile();
      d.tileTemplate = this.demoTpl;
      d.colspan = 2;
      this.tiles.push(d);

      const q = new Tile();
      q.tileTemplate = this.quotationTpl;
      q.colspan = 2;
      this.tiles.push(q);
    }

  }

  openTraining() {
    if (this.locale === 'de') {
      window.open('https://business-one-consulting.com/schulungsangebot/', '_blank');
    } else {
      window.open('https://business-one-consulting.com/en/training-courses/', '_blank');
    }
  }

  openManuals() {
    if (this.locale === 'de') {
      window.open('https://business-one-consulting.com/schulungsunterlagen/', '_blank');
    }
    else {
      window.open('https://business-one-consulting.com/en/training-material/', '_blank');
    }
  }

  openOnlineDemo() {
    if (this.locale === 'de') {
      window.open('https://business-one-consulting.com/sap-business-one-online-demo/', '_blank');
    } else {
      window.open('https://business-one-consulting.com/en/sap-business-one-online-demo-en/', '_blank');
    }
  }

  openQuotation() {
    if (this.locale === 'de') {
      window.open('https://business-one-consulting.com/sap-business-one-cloud/preisanfrage/', '_blank');
    } else {
      window.open('https://business-one-consulting.com/en/sap-business-one-cloud-en/request-for-quotation/', '_blank');
    }
  }

}
