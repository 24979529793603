<div fxLayout="column" fxLayoutGap="32px" style="margin: 50px;">

    <app-tiles [tiles]="tiles">
        
        <ng-template let-org="organization" #organizationTemplate>
            <div fxLayout="column" fxFill (click)="selectOrganization(org)">
                <div class="tileheader">
                    {{  org.name }}
                </div>
                <div fxFlex="1 0" fxLayout="column" fxLayoutAlign="center center">
                    <mat-icon style="font-size: 30pt;">desktop_windows</mat-icon>
                </div>
                <div fxLayout="row" class="tilefooter">
                    <mat-icon fxFlexAlign="start">help_outline</mat-icon>
                    <div fxFlex="1 0"></div>
                    <div fxFlexAlign="end" fxLayout="row">
                        <span>Öffnen</span>
                        <mat-icon>open_in_new</mat-icon>
                    </div>
                </div>
            </div>
        </ng-template>

        <ng-template let-org="organization" #addOrganizationTemplate>
            <div fxLayout="column" fxFill (click)="addOrganization()">
                <div class="tileheader">
                    Organisation Anlegen
                </div>
                <div fxFlex="1 0" fxLayout="column" fxLayoutAlign="center center">
                    <mat-icon style="font-size: 30pt;">desktop_windows</mat-icon>
                </div>
                <div fxLayout="row" class="tilefooter">
                </div>
            </div>
        </ng-template>

    </app-tiles>
    
</div>