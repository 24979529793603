<h1 mat-dialog-title style="text-align: center">Apps</h1>
<mat-dialog-content class="mat-typography">
  <div style="display: flex; flex-direction: column; gap: 30px">
    <div class="appRow appHeaderRow">
      <div class="appIconColum"></div>
      <div class="appNameColum">
        <h2>App</h2>
      </div>
      <div class="appDescriptionColum">
        <h2>Description</h2>
      </div>
      <div class="appAdminColum" *ngIf="isOperator">
        <h2>Admininstration</h2>
      </div>
      <div class="appStatusColum">
        <h2>Status</h2>
      </div>
      <div class="appActionsColum"></div>
    </div>
    <app-tenant-app
      [app]="appDocumentRecognition"
      [tenant]="data.tenant"
      (install)="installApp($event)"
      (uninstall)="uninstallApp($event)"
      (enableTestVersion)="enableTestVersion($event)"
      (disableTestVersion)="disableTestVersion($event)"
      (enableDiagnostics)="enableDiagnostics($event)"
      (disableDiagnostics)="disableDiagnostics($event)"
    ></app-tenant-app>
    <app-tenant-app
      [app]="appMailer"
      [tenant]="data.tenant"
      (install)="installApp($event)"
      (uninstall)="uninstallApp($event)"
      (enableTestVersion)="enableTestVersion($event)"
      (disableTestVersion)="disableTestVersion($event)"
      (enableDiagnostics)="enableDiagnostics($event)"
      (disableDiagnostics)="disableDiagnostics($event)"
    ></app-tenant-app>
    <app-tenant-app
      [app]="appDocumentArchive"
      [tenant]="data.tenant"
      (install)="installApp($event)"
      (uninstall)="uninstallApp($event)"
      (enableTestVersion)="enableTestVersion($event)"
      (disableTestVersion)="disableTestVersion($event)"
      (enableDiagnostics)="enableDiagnostics($event)"
      (disableDiagnostics)="disableDiagnostics($event)"
    ></app-tenant-app>
    <app-tenant-app
      [app]="appFulfillment"
      [tenant]="data.tenant"
      (install)="installApp($event)"
      (uninstall)="uninstallApp($event)"
      (enableTestVersion)="enableTestVersion($event)"
      (disableTestVersion)="disableTestVersion($event)"
      (enableDiagnostics)="enableDiagnostics($event)"
      (disableDiagnostics)="disableDiagnostics($event)"
    ></app-tenant-app>
    <app-tenant-app
      [app]="appUPS"
      [tenant]="data.tenant"
      (install)="installApp($event)"
      (repair)="repairApp($event)"
      (uninstall)="uninstallApp($event)"
      (enableTestVersion)="enableTestVersion($event)"
      (disableTestVersion)="disableTestVersion($event)"
      (enableDiagnostics)="enableDiagnostics($event)"
      (disableDiagnostics)="disableDiagnostics($event)"
    ></app-tenant-app>
    <app-tenant-app
      [app]="appShopify"
      [tenant]="data.tenant"
      (install)="installApp($event)"
      (uninstall)="uninstallApp($event)"
      (enableTestVersion)="enableTestVersion($event)"
      (disableTestVersion)="disableTestVersion($event)"
      (enableDiagnostics)="enableDiagnostics($event)"
      (disableDiagnostics)="disableDiagnostics($event)"
    ></app-tenant-app>
    <app-tenant-app
      [app]="appShopware"
      [tenant]="data.tenant"
      (install)="installApp($event)"
      (uninstall)="uninstallApp($event)"
      (enableTestVersion)="enableTestVersion($event)"
      (disableTestVersion)="disableTestVersion($event)"
      (enableDiagnostics)="enableDiagnostics($event)"
      (disableDiagnostics)="disableDiagnostics($event)"
    ></app-tenant-app>
    <!--
    <app-tenant-app
      [app]="appAmazon"
      [tenant]="data.tenant"
      (install)="installApp($event)"
      (uninstall)="uninstallApp($event)"
      (enableTestVersion)="enableTestVersion($event)"
      (disableTestVersion)="disableTestVersion($event)"
      (enableDiagnostics)="enableDiagnostics($event)"
      (disableDiagnostics)="disableDiagnostics($event)"
    ></app-tenant-app>-->
    <app-tenant-app
      [app]="appBig"
      [tenant]="data.tenant"
      (install)="installApp($event)"
      (uninstall)="uninstallApp($event)"
      (enableTestVersion)="enableTestVersion($event)"
      (disableTestVersion)="disableTestVersion($event)"
      (enableDiagnostics)="enableDiagnostics($event)"
      (disableDiagnostics)="disableDiagnostics($event)"
    ></app-tenant-app>
    <app-tenant-app
      [app]="appWoo"
      [tenant]="data.tenant"
      (install)="installApp($event)"
      (uninstall)="uninstallApp($event)"
      (enableTestVersion)="enableTestVersion($event)"
      (disableTestVersion)="disableTestVersion($event)"
      (enableDiagnostics)="enableDiagnostics($event)"
      (disableDiagnostics)="disableDiagnostics($event)"
    ></app-tenant-app>
    <app-tenant-app
      [app]="appOxid"
      [tenant]="data.tenant"
      (install)="installApp($event)"
      (uninstall)="uninstallApp($event)"
      (enableTestVersion)="enableTestVersion($event)"
      (disableTestVersion)="disableTestVersion($event)"
      (enableDiagnostics)="enableDiagnostics($event)"
      (disableDiagnostics)="disableDiagnostics($event)"
    ></app-tenant-app>
    <app-tenant-app
      [app]="appCrm"
      [tenant]="data.tenant"
      (install)="installApp($event)"
      (uninstall)="uninstallApp($event)"
      (enableTestVersion)="enableTestVersion($event)"
      (disableTestVersion)="disableTestVersion($event)"
      (enableDiagnostics)="enableDiagnostics($event)"
      (disableDiagnostics)="disableDiagnostics($event)"
    ></app-tenant-app>
    <app-tenant-app
      [app]="appTimesheet"
      [tenant]="data.tenant"
      (install)="installApp($event)"
      (uninstall)="uninstallApp($event)"
      (enableTestVersion)="enableTestVersion($event)"
      (disableTestVersion)="disableTestVersion($event)"
      (enableDiagnostics)="enableDiagnostics($event)"
      (disableDiagnostics)="disableDiagnostics($event)"
    ></app-tenant-app>
    <app-tenant-app
      [app]="appFXImport"
      [tenant]="data.tenant"
      (install)="installApp($event)"
      (uninstall)="uninstallApp($event)"
      (enableTestVersion)="enableTestVersion($event)"
      (disableTestVersion)="disableTestVersion($event)"
      (enableDiagnostics)="enableDiagnostics($event)"
      (disableDiagnostics)="disableDiagnostics($event)"
    ></app-tenant-app>
    <app-tenant-app
      [app]="appDatevImport"
      [tenant]="data.tenant"
      (install)="installApp($event)"
      (uninstall)="uninstallApp($event)"
      (enableTestVersion)="enableTestVersion($event)"
      (disableTestVersion)="disableTestVersion($event)"
      (enableDiagnostics)="enableDiagnostics($event)"
      (disableDiagnostics)="disableDiagnostics($event)"
    ></app-tenant-app>
    <app-tenant-app
      [app]="appStatisticDoc"
      [tenant]="data.tenant"
      (install)="installApp($event)"
      (uninstall)="uninstallApp($event)"
      (enableTestVersion)="enableTestVersion($event)"
      (disableTestVersion)="disableTestVersion($event)"
      (enableDiagnostics)="enableDiagnostics($event)"
      (disableDiagnostics)="disableDiagnostics($event)"
    ></app-tenant-app>
    <app-tenant-app
      [app]="appHanaWorkbench"
      [tenant]="data.tenant"
      (install)="installApp($event)"
      (uninstall)="uninstallApp($event)"
      (enableTestVersion)="enableTestVersion($event)"
      (disableTestVersion)="disableTestVersion($event)"
      (enableDiagnostics)="enableDiagnostics($event)"
      (disableDiagnostics)="disableDiagnostics($event)"
    ></app-tenant-app>
    <app-tenant-app
      [app]="appIntegrationMonitor"
      [tenant]="data.tenant"
      (install)="installApp($event)"
      (uninstall)="uninstallApp($event)"
      (enableTestVersion)="enableTestVersion($event)"
      (disableTestVersion)="disableTestVersion($event)"
      (enableDiagnostics)="enableDiagnostics($event)"
      (disableDiagnostics)="disableDiagnostics($event)"
    ></app-tenant-app>
    <app-tenant-app
      [app]="appLabels"
      [tenant]="data.tenant"
      (install)="installApp($event)"
      (uninstall)="uninstallApp($event)"
      (enableTestVersion)="enableTestVersion($event)"
      (disableTestVersion)="disableTestVersion($event)"
      (enableDiagnostics)="enableDiagnostics($event)"
      (disableDiagnostics)="disableDiagnostics($event)"
    ></app-tenant-app>
    <app-tenant-app
      [app]="appManufacturing"
      [tenant]="data.tenant"
      (install)="installApp($event)"
      (uninstall)="uninstallApp($event)"
      (enableTestVersion)="enableTestVersion($event)"
      (disableTestVersion)="disableTestVersion($event)"
      (enableDiagnostics)="enableDiagnostics($event)"
      (disableDiagnostics)="disableDiagnostics($event)"
    ></app-tenant-app>
    <app-tenant-app
      [app]="appGoodsReceipt"
      [tenant]="data.tenant"
      (install)="installApp($event)"
      (uninstall)="uninstallApp($event)"
      (enableTestVersion)="enableTestVersion($event)"
      (disableTestVersion)="disableTestVersion($event)"
      (enableDiagnostics)="enableDiagnostics($event)"
      (disableDiagnostics)="disableDiagnostics($event)"
    ></app-tenant-app>
    <app-tenant-app
      [app]="appQA"
      [tenant]="data.tenant"
      (install)="installApp($event)"
      (uninstall)="uninstallApp($event)"
      (enableTestVersion)="enableTestVersion($event)"
      (disableTestVersion)="disableTestVersion($event)"
      (enableDiagnostics)="enableDiagnostics($event)"
      (disableDiagnostics)="disableDiagnostics($event)"
    ></app-tenant-app>
    <app-tenant-app
      [app]="appMetalSurcharge"
      [tenant]="data.tenant"
      (install)="installApp($event)"
      (uninstall)="uninstallApp($event)"
      (enableTestVersion)="enableTestVersion($event)"
      (disableTestVersion)="disableTestVersion($event)"
      (enableDiagnostics)="enableDiagnostics($event)"
      (disableDiagnostics)="disableDiagnostics($event)"
    ></app-tenant-app>
    <app-tenant-app
      [app]="appEDI"
      [tenant]="data.tenant"
      (install)="installApp($event)"
      (uninstall)="uninstallApp($event)"
      (enableTestVersion)="enableTestVersion($event)"
      (disableTestVersion)="disableTestVersion($event)"
      (enableDiagnostics)="enableDiagnostics($event)"
      (disableDiagnostics)="disableDiagnostics($event)"
    ></app-tenant-app>
    <app-tenant-app
      [app]="appDespatchOrder"
      [tenant]="data.tenant"
      (install)="installApp($event)"
      (uninstall)="uninstallApp($event)"
      (enableTestVersion)="enableTestVersion($event)"
      (disableTestVersion)="disableTestVersion($event)"
      (enableDiagnostics)="enableDiagnostics($event)"
      (disableDiagnostics)="disableDiagnostics($event)"
    ></app-tenant-app>
    <app-tenant-app
      [app]="appApproval"
      [tenant]="data.tenant"
      (install)="installApp($event)"
      (uninstall)="uninstallApp($event)"
      (enableTestVersion)="enableTestVersion($event)"
      (disableTestVersion)="disableTestVersion($event)"
      (enableDiagnostics)="enableDiagnostics($event)"
      (disableDiagnostics)="disableDiagnostics($event)"
    ></app-tenant-app>
    <app-tenant-app
      [app]="appService"
      [tenant]="data.tenant"
      (install)="installApp($event)"
      (uninstall)="uninstallApp($event)"
      (enableTestVersion)="enableTestVersion($event)"
      (disableTestVersion)="disableTestVersion($event)"
      (enableDiagnostics)="enableDiagnostics($event)"
      (disableDiagnostics)="disableDiagnostics($event)"
    ></app-tenant-app>
    <app-tenant-app
      [app]="appDeviceDiagnostics"
      [tenant]="data.tenant"
      (install)="installApp($event)"
      (uninstall)="uninstallApp($event)"
      (enableTestVersion)="enableTestVersion($event)"
      (disableTestVersion)="disableTestVersion($event)"
      (enableDiagnostics)="enableDiagnostics($event)"
      (disableDiagnostics)="disableDiagnostics($event)"
    ></app-tenant-app>
    <app-tenant-app
      [app]="appInbox"
      [tenant]="data.tenant"
      (install)="installApp($event)"
      (uninstall)="uninstallApp($event)"
      (enableTestVersion)="enableTestVersion($event)"
      (disableTestVersion)="disableTestVersion($event)"
      (enableDiagnostics)="enableDiagnostics($event)"
      (disableDiagnostics)="disableDiagnostics($event)"
    ></app-tenant-app>
    <app-tenant-app
      [app]="appTime"
      [tenant]="data.tenant"
      (install)="installApp($event)"
      (uninstall)="uninstallApp($event)"
      (enableTestVersion)="enableTestVersion($event)"
      (disableTestVersion)="disableTestVersion($event)"
      (enableDiagnostics)="enableDiagnostics($event)"
      (disableDiagnostics)="disableDiagnostics($event)"
    ></app-tenant-app>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button
    color="primary"
    mat-raised-button
    [mat-dialog-close]="true"
    [disabled]="busy"
    cdkFocusInitial
  >
    Close
  </button>
</mat-dialog-actions>
