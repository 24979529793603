<h1 mat-dialog-title style="text-align: center;">Einrichtung der Mobile Sales App</h1>
<mat-dialog-content class="mat-typography">
    <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="20px">

        <h2 style="text-align: center;">App laden und installieren</h2>
        <p>Sie finden die <strong>SAP Business One Sales</strong> App im Google Play Store oder im Apple App Store.</p>        
        <div fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="start center">
            <a
                href='https://play.google.com/store/apps/details?id=b1.sales.mobile.android&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target="_blank"><img
                    alt='Jetzt bei Google Play' style="width: 150px;margin-top: 3px;"
                    src='https://play.google.com/intl/en_us/badges/static/images/badges/de_badge_web_generic.png' /></a>
            <a href="https://apps.apple.com/de/app/sap-business-one-sales/id1087151812?mt=8" target="_blank"
                style="display:inline-block;overflow:hidden;background:url(https://linkmaker.itunes.apple.com/de-de/badge-lrg.svg?releaseDate=2016-03-28&kind=iossoftware&bubble=ios_apps) no-repeat;width:135px;height:40px;"></a>
        </div>
        <h2 style="text-align: center;">Einrichtung der Verbindung</h2>
        <h3>Öffnen Sie die App</h3>
        <img style="width: 200px;border: 1px solid black" src="assets/mobile_app_iphone_1.png" />
        <h3>Wechseln Sie zu <strong>Einstellungen</strong></h3>
        <img style="width: 200px;border: 1px solid black" src="assets/mobile_app_iphone_2.png" />
        <p>
            Unter <strong>Einstellungen</strong> müssen die Webserveradresse und der Port angegeben werden:
        </p>
        <p>Webserveradresse:</p>
        <p>{{ mobileAccess?.server }}<button mat-icon-button (click)="copyServer()"><mat-icon>content_copy</mat-icon></button></p>
        <p>Port: 443</p>
        <h3><strong>Speichern</strong> Sie die Einstellungen</h3>
        <h2 style="text-align: center;">Anmeldung</h2>
        <p>Verwenden Sie die Zugangs ID als Benutzername und den Zugangsschlüssel als Passwort</p>
        <app-access-key></app-access-key>
        <h3>Sie können sich jetzt <strong>Anmelden</strong></h3>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button color="primary" mat-raised-button [mat-dialog-close]="true" cdkFocusInitial>Schließen</button>
</mat-dialog-actions>