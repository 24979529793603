import { Component, OnInit, Inject, ViewChild, ElementRef, Input } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DatabaseService, Database, Extension } from '../database.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { FormControl } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

@Component({
    selector: 'app-extension-select',
    templateUrl: './extension-selection.component.html',
    styleUrls: ['./extension-selection.component.css']
})
export class ExtensionSelectionComponent implements OnInit {

    extensionsCtrl = new FormControl();
    separatorKeysCodes: number[] = [ENTER, COMMA];

    @Input()
    selectedExtensions: Extension[];

    @Input()
    availableExtensions: Extension[];

    filteredExtensions: Extension[];
    

    @ViewChild('extensionInput') extensionInput: ElementRef<HTMLInputElement>;

    ngOnInit(): void {
        this.refreshFilter();
    }

    constructor() {
        this.availableExtensions = [];
        this.selectedExtensions = [];

        this.extensionsCtrl.valueChanges.subscribe( ()  => this.refreshFilter());
    }

    remove(ext: Extension) {
        const index = this.selectedExtensions.indexOf(ext);

        if (index >= 0) {
            this.selectedExtensions.splice(index, 1);
            this.refreshFilter();
        }
    }

    addExtension(ext: Extension) {
        const index = this.selectedExtensions.findIndex( e => e.name === ext.name && e.namespace === ext.namespace);
        if (index !== -1) {
            return;
        }
        this.selectedExtensions.push(ext);
        this.refreshFilter();
    }

    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        const inputValue = (value || '').trim();

        if (inputValue) {

            const match = this.availableExtensions.filter(e => e.name === inputValue);
            if (match.length === 1) {

                this.addExtension(match[0]);

                if (input) {
                    input.value = '';
                }

                this.extensionsCtrl.setValue(null);
            }
        }
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        this.addExtension(event.option.value);
        this.extensionsCtrl.setValue(null);
        this.extensionInput.nativeElement.blur();
        this.extensionInput.nativeElement.value = '';
    }

    refreshFilter() {
        this.filteredExtensions = this._filter(this.extensionsCtrl.value);
    }

    private _filter(value: any): any[] {
        let filterValue = '';
        if (typeof value === 'string') {
            filterValue = value;
        } else {
            filterValue = value?.name || '';
        }
        filterValue = filterValue.toLocaleLowerCase();

        return this.availableExtensions.filter(e => e.name.toLowerCase().indexOf(filterValue) === 0
            && this.selectedExtensions.indexOf(e) === -1);
    }

}