import { Component, Input } from '@angular/core';
import { AccountService } from '../account.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {


  @Input()
  public disabled: boolean;

  constructor(private accountService: AccountService) {
    this.disabled = false;
  }

  signinMicrosoft(): void {
    if (!this.disabled) {
      this.accountService.login('commonaad');
    }
  }

  signinGoogle(): void {
    if (!this.disabled) {
      this.accountService.login('google.com');
    }
  }

  signinLinkedIn(): void {
    if (!this.disabled) {
      this.accountService.login('linkedin.com');
    }
  }

  signinB1(): void {
    if (!this.disabled) {
      this.accountService.login(null);
    }
  }

  createAccount(): void {
    if (!this.disabled) {
      this.accountService.signup();
    }
  }

}
