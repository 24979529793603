<div class="appRow" *ngIf="visible">
    <div class="appIconColum">
        <mat-icon [svgIcon]="svgIcon" class="appIcon"></mat-icon>
    </div>
    <div class="appNameColum">
        <h3><strong>{{ appName }}</strong></h3>
    </div>
    <div class="appDescriptionColum">
        <p>
            {{ appDescription }}
        </p>
    </div>
    <div class="appAdminColum" *ngIf="isOperator">
        <mat-slide-toggle [checked]="useTestVersion" (change)="useTestVersionChange($event.checked)">Test version</mat-slide-toggle>
        <mat-slide-toggle [checked]="diagnosticsEnabled" (change)="diagnosticsChange($event.checked)" >Diagnostics</mat-slide-toggle>
    </div>
    <div class="appStatusColum">
        <span>{{ appStatus }}</span>
    </div>
    <div class="appActionsColum">
        <button mat-stroked-button color="primary" *ngIf="canInstall" (click)="onInstall()">{{ installText }}</button>
        <!--<button mat-stroked-button color="primary" *ngIf="canRepair" (click)="onRepair()">{{ repairText }}</button>-->        
        <button mat-stroked-button color="primary" *ngIf="canUninstall" (click)="onUninstall()">{{ uninstallText }}</button>
    </div>
</div>