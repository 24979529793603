<h1 mat-dialog-title>Mandant bearbeiten</h1>
<div mat-dialog-content fxLayout="column">
    <section fxLayout="column">
        <div fxLayout="row">
            <mat-form-field class="spacer">
                <mat-label>Name</mat-label>
                <input matInput [ngModel]="tenant.name" disabled="true">
            </mat-form-field>
            <mat-form-field class="spacer">
                <mat-label>Beschreibung</mat-label>
                <input matInput [(ngModel)]="tenant.description">
            </mat-form-field>
        </div>
        <div fxLayout="row">
            <app-extension-select [selectedExtensions]="selectedExtensions" [availableExtensions]="allExtensions" style="width: 100%;" class="spacer" ></app-extension-select>
        </div>

    </section>
</div>
<div mat-dialog-actions>
    <button mat-button mat-dialog-close>Abbrechen</button>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Ok</button>
</div>