<div fxLayout="column" style="margin: 16px;">
    <span>Zugangs ID</span>
    <div fxLayout="row">
      <input matInput readonly="true" size="20" [value]="domain + '\\' + user" style="height: 20px;" fxFlexAlign="center"/>
      <button mat-icon-button (click)="copyAccessID()">
        <mat-icon>content_copy</mat-icon>
      </button>
    </div>
    <span style="margin-top: 5px;">Zugangsschlüssel</span>
    <div fxLayout="row">
      <input matInput readonly="true" size="12" [value]="accessKey" [type]="showAccessKey ? 'text' : 'password'"
        style="height: 20px;" fxFlexAlign="center" />
      <button mat-icon-button (click)="toggleAccessKey()">
        <mat-icon>{{showAccessKey ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
      <button mat-icon-button (click)="copyAccessKey()">
        <mat-icon>content_copy</mat-icon>
      </button>
      <button mat-icon-button (click)="resetAccessKey()">
        <mat-icon>wifi_protected_setup</mat-icon>
      </button>
    </div>
  </div>