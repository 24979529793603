import { Component, OnInit, OnDestroy, ViewEncapsulation, Inject, LOCALE_ID } from '@angular/core';
import { AccountService } from '../account.service';
import { OrganizationService, Organization } from '../organization.service';
import { Database, DatabaseService } from '../database.service';
import { FilterService } from '../filter.service';
import { MatDialog } from '@angular/material/dialog';
import { FirstStepsDialogComponent } from './firststeps-dialog';
import { Subscription } from 'rxjs';
import { ContextService } from '../context.service';

@Component({
  selector: 'app-startpage',
  templateUrl: './startpage.component.html',
  styleUrls: ['./startpage.component.css']
})
export class StartpageComponent implements OnInit, OnDestroy {

  constructor(public accountService: AccountService, private organizationService: OrganizationService,
    private filterService: FilterService, public dialog: MatDialog,
    private contectService: ContextService,
    @Inject(LOCALE_ID) public locale: string) {
    this.firstSteps = false;
  }


  isLoggedIn: boolean;


  public get allTenants(): Database[] {
    return this.contectService.allTenants;
  }

  public get tenants(): Database[] {
    return this.contectService.tenants;
  }
  
  firstSteps: boolean;

  private orgChangedSubscription: Subscription;

  public get organization(): Organization {
    return this.organizationService.activeOrganization;
  }

  public get showPrice(): boolean {
    return this.locale === 'de';
  }



  ngOnInit(): void {
    this.isLoggedIn = this.accountService.account != null;
    const _this = this;
    this.orgChangedSubscription =
      this.organizationService.organizationChanged.subscribe(org => {
        _this.onOrganizationChanged();
      }
      );
    if (this.organization != null) {
      this.onOrganizationChanged();
    }    
  }

  ngOnDestroy(): void {
    this.orgChangedSubscription.unsubscribe();
  }

  onOrganizationChanged() {
    if (this.organization != null) {

      if (!this.firstSteps && this.accountService.account?.member != null && !this.accountService.account.member.hideFirstSteps) {
        const dialogData = {
          hideFirstSteps: this.accountService.account.member.hideFirstSteps,
          showHideOption: true
        };
        const dialogRef = this.dialog.open(FirstStepsDialogComponent, {
          width: '80%',
          data: dialogData
        });
        this.firstSteps = true;
        dialogRef.afterClosed().subscribe(result => {
          this.firstSteps = false;
          if (result) {
            if (dialogData.hideFirstSteps) {
              if (this.organization != null) {
                this.organizationService.hideFirstSteps(this.organization.id);
              }
            }
          }
        });
      }

    }

  }


  login(): void {
    this.accountService.login(null);
  }

}
