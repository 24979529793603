import { Injectable, EventEmitter, Output } from '@angular/core';
import { Database } from './database.service';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  tenantShowProduction: boolean;
  tenantShowTest: boolean;
  tenantShowTrial: boolean;
  private ptenantShowOnline: boolean;

  tenantFilterChanged = new EventEmitter<void>();

  constructor() {
    this.tenantShowTrial = false;
    this.ptenantShowOnline = false;
    this.tenantShowProduction = false;
    this.tenantShowTest = false;
  }

  get tenantShowOnline(): boolean {
    return this.ptenantShowOnline;
  }
  set tenantShowOnline(value: boolean) {
    if (this.ptenantShowOnline !== value) {
      this.ptenantShowOnline = value;
      this.tenantFilterChanged.emit();
    }
  }

  showTenant(tenant: Database): boolean {
    if (this.ptenantShowOnline) {
      return tenant.databaseState === 'STARTED';
    }
    return true;
  }

}
