<ng-template #defaultTile>
    <div>
        Test
    </div>
</ng-template>

<div fxFill fxLayout="row wrap" fxLayoutGap="3px grid" fxLayoutGap.gt-sm="16px grid">
    <div *ngFor="let tile of tiles">
        <div  class="tile"  [style.width.px]="tile.colspan*175 + (tile.colspan-1)*gap" >

            <ng-container 
                *ngTemplateOutlet="tile.tileTemplate? tile.tileTemplate: defaultTile, context: tile">    
            </ng-container>
        </div>
    </div>
</div>