<div fxLayout="column" fxLayoutAlign="start center" class="contentarea">
    <section fxLayout="column" fxLayoutAlign="start center">
        <div>
            <h2 i18n="imprint|header">Impressum</h2>
        </div>
        <div style="height: 32px"></div>
        <div fxLayout="row" fxLayaoutAlign="center start" fxLayoutGap="40px">
            <div>
                <p style="text-align: left;"><strong>conesprit GmbH<br>
                    </strong>Hans-Gaugler-Weg 14<br>
                    71522 Backnang<br>
                    <span i18n="imprint|country">Deutschland</span><br>
                    <a href="info@business-one-consulting.com">info@business-one-consulting.com</a><br>
                    <a href="http://www.conesprit.de" rel="noopener noreferrer"
                        onclick="javascript:window.open('http://www.conesprit.de/'); return false;">www.conesprit.de</a>
                </p>
            </div>
            <div>
                <p style="text-align: left;"><strong i18n="imprint|contact">Ansprechpartner<br>
                    </strong>Katrin Douverne<br>
                    <a href="mailto:katrin.douverne@conesprit.de">katrin.douverne@conesprit.de</a><br>
                    Mob: +49 151 623 00 241<br>
                    Tel.:&nbsp; +49 7191 18 70 190<br>
                    Fax: +49 7191 18 70 191</p>
            </div>
            <div>
                <p style="text-align: left;"><strong i18n="imprint|ceo">Geschäftsführer<br>
                    </strong>Roman Douverne</p>
            </div>
            <div>
                <p style="text-align: left;"><strong i18n="imprint|register">Handelsregister<br>
                    </strong>Amtsgericht Stuttgart</p>
                <p style="text-align: left;"><strong i18n="imprint|register number">Handelsregister-Nr.:<br>
                    </strong>755791</p>
                <p style="text-align: left;"><strong i18n="imprint|tax id">Umsatzsteuer-ID:<br>
                    </strong>DE259724745</p>
            </div>
        </div>
    </section>
    <div>
        <h2 i18n="imprint|liability" header>Haftungshinweis</h2>
    </div>
    <section fxLayout="column" fxLayoutAlign="start start"
        style="padding-left: 64px;padding-right: 64px;">
        <div style="height: 32px"></div>
        <p i18n="imprint|liability content">
            <strong>Haftung für Inhalte</strong>
            <br>
            Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und
            Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen.
        </p>
        <p i18n="imprint|liability copyright">
            <strong>Urheberrecht</strong>
            <br>
            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
            Urheberrecht. Beiträge Dritter sind als solche gekennzeichnet. Die Vervielfältigung, Bearbeitung,
            Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen
            Zustimmung des jeweiligen Autors bzw. Erstellers. Die Betreiber der Seiten sind bemüht, stets die
            Urheberrechte anderer zu beachten bzw. auf selbst erstellte sowie lizenzfreie Werke zurückzugreifen.
        </p>

        <p i18n="imprint|liability third party naming rights">
            <strong>Namensrechte Dritter</strong>
            <br>
            Alle genannten Marken- und Produktnamen sind geschützte Warenzeichen der jeweiligen Unternehmen.
        </p>


        <p i18n="imprint|trademarks">
            <strong>Namensrechte conesprit</strong>
            <br>
            conesprit ist ein eingetragenes Markenzeichen gem. § 4 Ziffer 1 MarkenG. Die Marke conesprit ist für die
            Waren- und Dienstleistungsklassen 42 (Leitklasse), 9, 16, 35 und 38 geschützt. Jegliche nicht autorisierte
            Handlung durch Dritte im Namen von conesprit oder die Verwendung ähnlicher Namen in den gesicherten Marken-
            und Dienstleistungsklassen wird strafrechtlich verfolgt.
        </p>

        <p i18n="imprint|images">
            <strong>Bildmaterial</strong>
            <br>
            Das auf diesen Seiten verwendete Bildmaterial unterliegt zum Teil den Lizenzbedingungen Dritter. Eine über
            die private Nutzung hinausgehende Verwendung sowie eine Weiterveröffentlichung, Weiterübertragung,
            Reproduktion oder anderweitige Verwendung von lizensiertem und durch conesprit erstelltem Bildmaterial ist
            untersagt.
        </p>

        <p i18n="imprint|image credits">
            <strong>Bildnachweise</strong>
            <br>
            HOME People: Djomas – shutterstock.com
            Branchen Factory Engineer: Monkey Business Images – shutterstock.com
            Branchen Beauty salon: Karashaev – shutterstock.com
            Branchen Warehouse storage: Voy ager – shutterstock.com
            Branchen Bicycle store: pikselstock – shutterstock.com
            Branchen shoppig bags: kikovic– shutterstock.com
        </p>

        <p i18n="imprint|image thanks">
            Wir danken den Fotografen für die Bereitstellung der Bilder zum Kauf auf den o.g. Bilddatenbanken.
        </p>
    </section>
</div>