import { Component, Input, OnInit } from '@angular/core';
import { OrganizationService } from '../organization.service';
import { DatabaseService, TenantCredentials } from '../database.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { AccountService, Account } from '../account.service';


@Component({
    selector: 'app-credentials',
    templateUrl: './credentials.component.html',
    styleUrls: ['./credentials.component.css']
})
export class CredentialsComponent implements OnInit {

    showPassword: boolean;

    credentials: TenantCredentials;

    @Input()
    tenant: string;

    @Input()
    credentialsType: string;

    public get account(): Account {
        return this.accountService.account;
    }


    public get user(): string {
        return this.credentials?.username ?? '';
    }

    public get password(): string {
        return this.credentials?.password ?? '';
    }

    constructor(
        private accountService: AccountService,
        private tenantService: DatabaseService,
        private clipboard: Clipboard
    ) {
        this.showPassword = false;
        this.credentials = {
            password: '',
            type: '',
            username: ''
        };
    }

    ngOnInit(): void {
        this.tenantService.getCredentials(this.tenant, this.credentialsType).then(c => this.credentials = c).catch();
    }

    togglePassword() {
        this.showPassword = !this.showPassword;
    }

    resetCredentials() {
        this.tenantService.resetCredentials(this.tenant, this.credentialsType).then(c => this.credentials = c).catch();
    }

    copyPassword() {
        this.clipboard.copy(this.credentials.password);
    }

    copyUsername() {
        this.clipboard.copy(this.credentials.username);
    }

}
