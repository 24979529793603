<h1 mat-dialog-title>Mitglied einladen</h1>
<div mat-dialog-content fxLayout="column">
    <section fxLayout="column">
        <div fxLayout="row">
            <mat-form-field class="spacer" fxFill>
                <mat-label>E-Mail Adresse</mat-label>
                <input matInput [(ngModel)]="data.email">
            </mat-form-field>
        </div>
    </section>
</div>
<div mat-dialog-actions>
    <button mat-button mat-dialog-close>Abbrechen</button>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial [disabled] = "!isValid()" >Senden</button>
</div>