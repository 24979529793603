<mat-tab-group class="container" style="background-color: #F9FBFC !important;">

    <mat-tab label="Übersicht" *ngIf="canViewOverview" >
        <div fxFill fxLayout="column" fxLayoutAlign="start start">
            <div style="padding: 20px;">
                <div *ngIf="isOperator">
                    Lizenzdatei:
                    <input type="file" accept=".txt" (change)="licenseFileUpload($event.target.files)">

                </div>
                <div fxLayout='row' style="margin: 10px">
                    <button mat-mini-fab color="primary" (click)="toggleOverviewEdit()">
                        <mat-icon>{{ organizationOverviewEdit ? 'cancel' : 'edit' }}</mat-icon>
                    </button>
                    <div>&nbsp;</div>
                    <button mat-mini-fab color="primary" [disabled]="!organizationOverviewEdit"
                        (click)="saveOverview()">
                        <mat-icon>save</mat-icon>
                    </button>
                    <div>&nbsp;</div>
                    <a [routerLink]="'/organizations'" mat-mini-fab color="primary">
                        <mat-icon>swap_horizontal_circle</mat-icon>
                    </a>
                </div>
                <div class="spacer"></div>
                <form [formGroup]="organizationForm" fxLayout="column">
                    <mat-form-field fxLayout="row" class="spacer">
                        <mat-label>Unternehmen</mat-label>
                        <input matInput formControlName="name" [readonly]="!organizationOverviewEdit">
                    </mat-form-field>
                    <mat-form-field fxLayout="row" class="spacer">
                        <mat-label>Strasse</mat-label>
                        <input matInput formControlName="street" [readonly]="!organizationOverviewEdit">
                    </mat-form-field>
                    <div fxLayout="row wrap">
                        <mat-form-field class="spacer">
                            <mat-label>Postleitzahl</mat-label>
                            <input matInput formControlName="postalCode" [readonly]="!organizationOverviewEdit">
                        </mat-form-field>
                        <mat-form-field class="spacer">
                            <mat-label>Ort</mat-label>
                            <input matInput formControlName="city" [readonly]="!organizationOverviewEdit">
                        </mat-form-field>
                    </div>
                    <div fxLayout="row wrap">
                        <mat-form-field class="spacer">
                            <mat-label>Ländercode</mat-label>
                            <input matInput formControlName="countryLetterCode" [readonly]="!organizationOverviewEdit">
                        </mat-form-field>
                        <mat-form-field class="spacer">
                            <mat-label>Land</mat-label>
                            <input matInput formControlName="country" [readonly]="!organizationOverviewEdit">
                        </mat-form-field>
                    </div>
                    <mat-form-field fxLayout="row" class="spacer">
                        <mat-label>Bundesland</mat-label>
                        <input matInput formControlName="state" [readonly]="!organizationOverviewEdit">
                    </mat-form-field>
                </form>
            </div>
        </div>

    </mat-tab>
    <mat-tab label="Mitglieder" fxFill fxLayout="column" fxLayoutAlign="start center" *ngIf="canViewMembers">

        <table mat-table [dataSource]="organization.members" fxFill>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Name </th>
                <td mat-cell *matCellDef="let member"> {{member.name}} </td>
            </ng-container>

            <ng-container matColumnDef="role">
                <th mat-header-cell *matHeaderCellDef> Rolle </th>
                <td mat-cell *matCellDef="let member"> {{member.role | userRoleText }} </td>
            </ng-container>


            <ng-container matColumnDef="businessOneUserName">
                <th mat-header-cell *matHeaderCellDef> Business One Benutzercode </th>
                <td mat-cell *matCellDef="let member"> {{member.businessOneUserName}} </td>
            </ng-container>

            <ng-container matColumnDef="businessOneLicense">
                <th mat-header-cell *matHeaderCellDef> Lizenzen </th>
                <td mat-cell *matCellDef="let member">
                    <span *ngFor="let l of member.licenses; let i = index">{{ (i > 0 ? ',' : ' ') }} {{ l | licenseText }} </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="menu" stickyEnd>
                <th mat-header-cell *matHeaderCellDef>
                    <button mat-icon-button color="primary" (click)='onAddUser()' [disabled]='!canAddMember()'>
                        <mat-icon>person_add</mat-icon>
                    </button>
                </th>
                <td mat-cell *matCellDef="let member">
                    <button mat-icon-button color="primary" aria-label="Bearbeiten" (click)="editMember(member)"
                        [disabled]='!canEditMember(member)'>
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-icon-button color="primary" aria-label="Löschen" (click)="deleteMember(member)"
                        [disabled]='!canDeleteMember(member)'>
                        <mat-icon>delete</mat-icon>
                    </button>
                    <button mat-icon-button color="primary" aria-label="Einladung senden" (click)='onInviteUser(member)'
                        [disabled]='!canSendInvitation(member)' *ngIf="!hasAccount(member) && hasId(member)">
                        <mat-icon>mail</mat-icon>
                    </button>
                    <button mat-icon-button color="primary" aria-label="Disconnect" (click)='onDisconnect(member)'
                        [disabled]='!canDisconnect(member)' *ngIf="hasAccount(member) && hasId(member) && isOperator">
                        <mat-icon>person_off</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row
                *matHeaderRowDef="[ 'name', 'role', 'businessOneUserName' , 'businessOneLicense', 'menu']">
            </tr>
            <tr mat-row
                *matRowDef="let myRowData; columns: [ 'name', 'role', 'businessOneUserName', 'businessOneLicense','menu']">
            </tr>
        </table>
    </mat-tab>
    <mat-tab label="Apps" fxFill fxLayout="column" fxLayoutAlign="start center" *ngIf="canViewApps">
        <div fxFill fxLayout="column" fxLayoutAlign="start start">
            <div style="padding: 20px;">
                <div fxLayout='row' style="margin: 10px">
                    <button mat-mini-fab color="primary" (click)="toggleAppsEdit()">
                        <mat-icon>{{ organizationAppsEdit ? 'cancel' : 'edit' }}</mat-icon>
                    </button>
                    <div>&nbsp;</div>
                    <button mat-mini-fab color="primary" [disabled]="!organizationAppsEdit"
                        (click)="saveApps()">
                        <mat-icon>save</mat-icon>
                    </button>
                </div>
                <div class="spacer"></div>
                <app-app-select [allApps]="allApps" [selection]="orgApps" style="width: 100%;" fxLayout="row" [enabled]="organizationAppsEdit"></app-app-select>
            </div>
        </div>
    </mat-tab>
</mat-tab-group>