import { Injectable } from '@angular/core';
import { Organization } from './organization.service';
import { HttpClient } from '@angular/common/http';
import { Observable, of, concat, Subject } from 'rxjs';
import { tap, delay, switchMap, repeatWhen, takeUntil } from 'rxjs/operators';
import { AccountService } from './account.service';

export class DatabaseTemplate {
  name: string;
  description: string;
  templateId: string;
}

class Provision {
  organization: Organization;
  databases: DatabaseTemplate[];
  offerId: string;
  microsoftToken: string;
  campaign: string;
  locale: string;
}

export class MicrosoftSubscriptionInfo {
  subscriptionId: string;
  subscriptionName: string;
  offerId: string;
  planId: string;
  quantity: number;
  status: string;
  beneficaryTenantId: string;
  puchaserTenantId: string;
  organizationId: string;
  provisionState: ProvisionStatus;
}

export class AutocompleteResult {
  description: string;
  placeId: string;
  name: string;
}

export class CompanyDetails {
  name: string;
  street: string;
  streetNumber: string;
  city: string;
  postalCode: string;
  state: string;
  country: string;
  countryCode: string;

}

export const enum ProvisionState {
  Running = 'Running',
  Completed = 'Completed',
  Failed = 'Failed'
}

export class TenantProvisionStatus {
  tenantId: string;
  state: string;
}

export class ProvisionStatus {
  id: string;
  state: ProvisionState;
  organizationId: string;
  tenants: TenantProvisionStatus[];
}


@Injectable({
  providedIn: 'root'
})
export class ProvisioningService {

  constructor(private http: HttpClient, private accountService: AccountService) { }

  companyAutocomplete(text: string): Observable<AutocompleteResult[]> {
    return this.http.post<AutocompleteResult[]>('/api/v1/provisioning/company/autocomplete', {
      text
    });
  }

  getCompanyDetails(id: string): Observable<CompanyDetails> {
    return this.http.get<CompanyDetails>('/api/v1/provisioning/company/details/' + id);
  }

  resolveMicrosoftToken(token: string): Observable<MicrosoftSubscriptionInfo> {
    return this.http.post<MicrosoftSubscriptionInfo>('/api/v1/marketplace/resolve', {
      token
    });
  }

  signup(campaign: string): Observable<any> {
    return this.http.post<any>('/api/v1/provisioning/signup', {
      firstName: this.accountService.account?.firstName,
      lastName: this.accountService.account?.lastName,
      name: this.accountService.account?.name,
      campaign,
      account: this.accountService.account?.id
    });
  }

  provision(
    offerId: string,
    org: Organization,
    microsoftToken: string,
    databases: DatabaseTemplate[],
    campaign: string,
    locale: string
  ): Observable<ProvisionStatus> {
    const prov = new Provision();
    prov.databases = databases;
    prov.offerId = offerId;
    prov.organization = org;
    prov.microsoftToken = microsoftToken;
    prov.campaign = campaign;
    prov.locale = locale;

    const stop = new Subject();
    const start = this.http.post<ProvisionStatus>('/api/v1/provisioning', prov);
    const last: ProvisionStatus = new ProvisionStatus();
    const poll =
      concat(
        start.pipe(
          switchMap(first => this.http.get<ProvisionStatus>('/api/v1/provisioning/' + first.id).pipe(
            tap(s => {
              if (s.state !== ProvisionState.Running) {
                last.state = s.state;
                last.id = s.id;
                last.organizationId = s.organizationId;
                last.tenants = s.tenants;
                stop.next();
              }
            }),
            repeatWhen(completed => completed.pipe(delay(1000))),
            takeUntil(stop),
          ))
        ), of(last));
    return poll;
  }

}
