import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  Inject,
  LOCALE_ID,
  ViewEncapsulation,
} from '@angular/core';
import { AccountService, Account, AccountOption } from './account.service';
import { FilterService } from './filter.service';
import {
  OrganizationService,
  Organization,
  OrganizationInfo,
} from './organization.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatSelectionListChange } from '@angular/material/list';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatDialog } from '@angular/material/dialog';
import { FirstStepsDialogComponent } from './startpage/firststeps-dialog';
import { Router, NavigationEnd } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OperationsService, Operation } from './operations.service';
import { Subscription } from 'rxjs';
import { AppsService } from './apps.service';
import { MatSelectChange } from '@angular/material/select';
import { ContextService } from './context.service';
import { APIService } from './api.service';
import { FeaturesService } from './features.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'businessone';

  showAccessKey: boolean;
  accessKey = 'xxxxxxxxxxxx';

  organizations: OrganizationInfo[] = [];
  filteredOrganizations: OrganizationInfo[] = [];

  operationsSubscription: Subscription;

  @ViewChild('filterMenuTrigger', { read: MatMenuTrigger })
  protected filterMenuTrigger: MatMenuTrigger;

  public get account(): Account {
    return this.accountService.account;
  }

  public get isLoggedIn(): boolean {
    return this.account != null;
  }

  public get isOperator(): boolean {
    return this.account !== null && this.account.isOperator;
  }

  public get canMaintainOrganization(): boolean {
    return (
      this.isOperator ||
      (this.account?.isOwner &&
        this.organization.appAuthorizationsEnabled === true)
    );
  }

  public get isOwner(): boolean {
    return this.account !== null && this.account.member?.role === 'owner';
  }

  public get organization(): Organization {
    return this.orgService.activeOrganization;
  }

  public get availableAccounts(): AccountOption[] {
    return this.accountService.availableAccounts.filter((a) => !a.isActive);
  }

  public get activeAccount(): AccountOption | null {
    return (
      this.accountService.availableAccounts.find((a) => a.isActive) ?? null
    );
  }

  public get activeOperations(): Operation[] {
    return this.operations?.activeOperations;
  }

  public get allOperations(): Operation[] {
    return this.operations?.operations;
  }

  public get showPrice(): boolean {
    return this.locale === 'de';
  }

  public getAccountDisplayName(account: AccountOption | null) {
    if (account == null) {
      return '-';
    }
    return `${account.firstName ?? ''} ${account.lastName ?? ''}`;
  }

  public getAccountIdentity(account: AccountOption | null) {
    if (account == null) {
      return '-';
    }
    if (account.email != null && account.email != '') {
      return account.email;
    }
    return account.name ?? '-';
  }

  public get contact(): string {
    switch (this.locale) {
      case 'hu':
        return 'https://www.gtsteamlabs.com';
      default:
        return 'https://business-one-consulting.com/kontakt/';
    }
  }

  public get userName(): string {
    return (
      (this.account?.member?.adUser?.domain ?? 'BUSINESS-ONE') +
      '\\' +
      (this.account?.member?.adUser?.name ?? '')
    );
  }

  public get pendingSupportTickets(): number {
    return this.apiService.pendingSupportTickets;
  }

  constructor(
    private accountService: AccountService,
    private orgService: OrganizationService,
    private clipboard: Clipboard,
    public filter: FilterService,
    public dialog: MatDialog,
    private router: Router,
    private snackBar: MatSnackBar,
    private operations: OperationsService,
    private contextService: ContextService,
    private appsService: AppsService,
    public features: FeaturesService,
    private apiService: APIService,
    @Inject(LOCALE_ID) public locale: string
  ) { }
  ngOnDestroy(): void {
    this.operationsSubscription.unsubscribe();
  }

  showSupportTickets() {

    const ta = this.appsService.apps.find(a => a.id === "business-one.io-ticketing");
    if (ta === null) {
      return;
    }

    const url = ta.accessUrl
      .replace('${organizationId}', this.organization.id)
      .replace('${tenantId}', '')
      .replace('${registrationId}', '')
      .replace("${login_hint}", this.accountService.account.username)
      .replace("${domain_hint}", this.accountService.account.domain ?? "")
      .replace("${lang}", this.locale);
    window.open(url, '_blank', 'toolbar=0,location=0,menubar=0');
  }

  login() {
    this.accountService.login(null);
  }

  loginAccount(account: AccountOption) {
    this.accountService.loginAccount(account);
  }

  logout() {
    this.accountService.logout();
  }

  removeAccount(account: AccountOption): void {
    void this.accountService.removeAccount(account);
  }

  toggleAccessKey() {
    if (!this.showAccessKey) {
      this.orgService
        .loadAccessKey()
        .toPromise()
        .then((k) => {
          this.accessKey = k;
          this.showAccessKey = true;
        });
    } else {
      this.accessKey = 'xxxxxxxxxxxx';
      this.showAccessKey = false;
    }
  }

  resetAccessKey() {
    this.orgService
      .resetAccessKey()
      .toPromise()
      .then((k) => {
        this.accessKey = 'xxxxxxxxxxxx';
        this.showAccessKey = false;
      })
      .catch((err) => alert(err));
  }

  copyAccessKey() {
    this.orgService
      .loadAccessKey()
      .toPromise()
      .then((k) => {
        this.clipboard.copy(k);
      });
  }

  accountMenuClosed() {
    this.accessKey = 'xxxxxxxxxxxx';
    this.showAccessKey = false;
  }

  onFilterOpened() {
    this.orgService
      .getAvailableOrganizations()
      .toPromise()
      .then((orgs) => {
        this.organizations = orgs;
        this.filteredOrganizations = orgs;
      });
  }

  onNotificationsOpened() { }

  private applyOrgFilter(f: string) {
    this.filteredOrganizations = [];
    if (f === '') {
      this.filteredOrganizations = this.organizations;
    } else {
      const filter = f.toLowerCase();
      this.filteredOrganizations = this.organizations.filter(
        (o) => o.name.toLowerCase().indexOf(filter) >= 0
      );
    }
  }

  onOrgSearchChange(value: string) {
    this.applyOrgFilter(value);
  }

  onOrgSelected(s: MatSelectionListChange) {
    const orgInfo = s.option.value as OrganizationInfo;
    if (orgInfo != null) {
      this.orgService.switchActiveOrganization(orgInfo.id);
      this.filterMenuTrigger.closeMenu();
    }
  }

  ngOnInit() {
    this.router.events.forEach((item) => {
      if (item instanceof NavigationEnd) {
      }
    });
    this.operationsSubscription =
      this.operations.operationStatusUpdate.subscribe((op: Operation) => {
        if (op.status.isRunning) {
          this.snackBar.open(`${op.text}...`, null, {
            duration: 2000,
          });
        } else {
          this.snackBar.open(`${op.text} abgeschlossen`, null, {
            duration: 4000,
          });
        }
      });
  }

  showFirstSteps() {
    const dialogRef = this.dialog.open(FirstStepsDialogComponent, {
      width: '80%',
      data: {},
    });
  }

  public languageSelected(c: MatSelectChange): void {
    if (c.value !== this.locale) {
      switch (c.value) {
        case 'de':
          window.location.href = '/de' + this.router.url;
          break;
        case 'en':
          window.location.href = '/en' + this.router.url;
          break;
        case 'hu':
          window.location.href = '/hu' + this.router.url;
          break;
      }
    }
  }
}
