import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { DatabaseService, MobileAccess } from '../database.service';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
    selector: 'app-config-mobile-service-dialog',
    templateUrl: 'config-mobile-service.component.html',
    styleUrls: ['./config-mobile-service.component.css']
})
export class ConfigMobileServiceDialogComponent {

    mobileAccess: MobileAccess;

    constructor(
        public dialogRef: MatDialogRef<ConfigMobileServiceDialogComponent>,
        private clipboard: Clipboard,
        private tenantService: DatabaseService,
        @Inject(MAT_DIALOG_DATA) public data: any, iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
        const tenant = data.tenant as string;
        tenantService.getMobileAccess(tenant).toPromise().then(m => this.mobileAccess = m);
    }

    copyServer() {
        this.clipboard.copy( this.mobileAccess.server );
    }
}
