<h1 mat-dialog-title style="text-align: center;">Einrichtung der COBI.wms App</h1>
<mat-dialog-content class="mat-typography">
    <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="20px">

        <h2 style="text-align: center;">App laden und installieren</h2>
        <p>Sie finden die <strong>COBI.wms - Logistik App für SAP Business One</strong> App im Google Play Store.
        </p>
        <div fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="start center">

            <a href='https://play.google.com/store/apps/details?id=de.cobi.wms&hl=de&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
                target="_blank"><img alt='Jetzt bei Google Play' style="width: 150px;margin-top: 3px;"
                    src='https://play.google.com/intl/en_us/badges/static/images/badges/de_badge_web_generic.png' /></a>


        </div>
        <h2 style="text-align: center;">Einrichtung der Verbindung</h2>
        <h3>Öffnen Sie die App, Einstellungen sollte geöffnet werden</h3>
        <h3>Wechseln Sie zu <strong>Cloud</strong></h3>
        <img style="width: 300px;border: 1px solid black" src="assets/cobiwms_settings1.png" />
        <p>
            Für die <strong>Service Layer URL</strong> bitte die folgende Adresse eingegeben:
        </p>
        <p>{{ mobileAccess.server }} <button mat-icon-button (click)="copyServiceLayer()"><mat-icon>content_copy</mat-icon></button>
        </p>

        <p>Unter <strong>Firmendatenbanken</strong> muss <strong>{{ data.tenant }}</strong> <button mat-icon-button (click)="copyTenant()"><mat-icon>content_copy</mat-icon></button> hinterlegt werden.</p>

        <h3>In den <strong>Erweiterte Einstellungen</strong> können die Zugangsdaten gespeichert werden.</h3>

        <p>Verwenden Sie die Zugangs ID als <strong>Benutzername</strong> und den Zugangsschlüssel als
            <strong>Kennwort</strong></p>
        <app-access-key></app-access-key>


        <h3>Sie können sich jetzt <strong>Verbinden</strong> und anschließend <strong>ANMELDEN</strong></h3>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button color="primary" mat-raised-button [mat-dialog-close]="true" cdkFocusInitial>Schließen</button>
</mat-dialog-actions>