<div fxLayout='column' style="margin: 20px">

    <div>Datenbanken</div>
    <mat-divider></mat-divider>
    <div fxLayout='row' style="margin: 10px">
        <button mat-mini-fab color="primary" (click)="onStartDatabase()" [disabled]="!canStart">
            <mat-icon>play_arrow</mat-icon>
        </button>
        <div>&nbsp;</div>
        <button mat-mini-fab color="primary" (click)="onStopDatabase()" [disabled]="!canStop">
            <mat-icon>stop</mat-icon>
        </button>
        <div>&nbsp;</div>
        <button mat-mini-fab color="primary" (click)="onAddDatabase()">
            <mat-icon>add</mat-icon>
        </button>        
        <div>&nbsp;</div>
        <button mat-mini-fab color="primary" (click)="onEditTenant()">
            <mat-icon>edit</mat-icon>
        </button>
        <div>&nbsp;</div>
        <button mat-mini-fab color="primary" (click)="onDeleteDatabase()" [disabled]="!canDelete">
            <mat-icon>delete</mat-icon>
        </button>
        <div>&nbsp;</div>
        <button mat-mini-fab color="primary" (click)="onBackupDatabase()" [disabled]="!canBackup">
            <mat-icon>backup</mat-icon>
        </button>
        <div>&nbsp;</div>
        <button mat-mini-fab color="primary" (click)="onRestoreDatabase()" [disabled]="!canRestore">
            <mat-icon>restore</mat-icon>
        </button>
        <div>&nbsp;</div>
        <button mat-mini-fab color="primary" (click)="onCopyFrom()" [disabled]="!canCopyFrom"> 
            <mat-icon>content_copy</mat-icon>
        </button>
    </div>
    <mat-divider></mat-divider>
    <table mat-table [dataSource]="databases" class="mat-elevation-z6">

        <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
            </th>
            <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? databaseSelection.toggle(row) : null"
                    [checked]="databaseSelection.isSelected(row)">
                </mat-checkbox>
            </td>
        </ng-container>
        <mat-text-column name="name"></mat-text-column>
        <mat-text-column name="description"></mat-text-column>
        <mat-text-column name="databaseState" headerText="Status"></mat-text-column>
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>

    </table>

</div>