<div fxLayout="column" fxLayoutAlign="start center" style="margin-top: 20px;">
    <mat-card style="width: 900px;">
        <mat-card-title>Training materials</mat-card-title>
        <mat-card-content>
            <div fxLayout="column" fxLayoutAlign="start center">
                <div fxLayout="column" fxLayoutAlign="start start" class="table">
                    <div fxLayout="row" fxLayoutAlign="start start" class="tableHeader" fxLayoutGap="20px">
                        <div class="tableHeaderCol1">&nbsp;</div>
                        <div class="tableHeaderCol2">Web Client</div>
                        <div class="tableHeaderCol3">Browser Access<br>SAP GUI</div>
                    </div>
                    <div class="section" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px">
                        <div class="tableHeaderCol1">Basic</div>
                        <div class="tableHeaderCol2"></div>
                        <div class="tableHeaderCol3"></div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px" class="course">
                        <div class="courseId">SBO1000</div>
                        <div class="courseDescription">Introduction</div>
                        <div class="tableHeaderCol2" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px">
                            <div><a href="xyz"></a>PDF</div>
                            <div><a href="xyz"></a>Video</div>
                        </div>
                        <div class="tableHeaderCol3" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px">
                            <div><a href="xyz"></a>PDF</div>
                            <div><a href="xyz"></a>Video</div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>