import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MsalModule, MsalInterceptor } from '@azure/msal-angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { environment } from './../environments/environment';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatButtonModule } from '@angular/material/button';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatBadgeModule } from '@angular/material/badge';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTreeModule } from '@angular/material/tree';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

import { ClipboardModule } from '@angular/cdk/clipboard';

import { FlexLayoutModule } from '@angular/flex-layout';

import { TenantpoolsComponent } from './tenantpools/tenantpools.component';
import { StartpageComponent } from './startpage/startpage.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { LoginComponent } from './onboarding/login.component';
import { FirstStepsDialogComponent } from './startpage/firststeps-dialog';
import { ImprintComponent } from './startpage/imprint.component';
import { PrivacyComponent } from './startpage/privacy.component';
import {
  OrganizationComponent,
  AddUserDialogComponent,
  EditUserDialogComponent,
  InviteUserDialogComponent,
} from './organization/organization.component';
import {
  TenantsComponent,
  AddDatabaseDialogComponent,
  DatabaseBackupDialogComponent,
  DatabaseRestoreDialogComponent,
  EditTenantDialogComponent,
  CopyDatabaseDialogComponent,
} from './tenants/tenants.component';
import { ExtensionSelectionComponent } from './tenants/extension-selection.component';
import { InvitationConfirmationComponent } from './invitation-confirmation/invitation-confirmation.component';
import { TenantTilesComponent } from './tenant-tiles/tenant-tiles.component';
import { AccessKeyComponent } from './tenant-tiles/access-key.component';
import { CredentialsComponent } from './tenant-tiles/credentials.component';

import { ConfigMobileDialogComponent } from './tenant-tiles/config-mobile.component';
import { ConfigMobileServiceDialogComponent } from './tenant-tiles/config-mobile-service.component';
import { ConfigCobiWmsDialogComponent } from './tenant-tiles/config-cobiwms.component';
import { ConfigCrystalDialogComponent } from './tenant-tiles/config-crystal.component';

import { TilesComponent } from './tiles/tiles.component';
import {
  OrganizationsComponent,
  AddOrganizationDialogComponent,
} from './organizations/organizations.component';
import {
  UserRoleTextPipe,
  BusinessOneLicenseTextPipe,
  LicenseTextPipe,
} from './organization.service';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { AdministrationComponent } from './administration/administration.component';
import { CommonTilesComponent } from './common-tiles/common-tiles.component';

import { AppSelectionComponent } from './organization/app-selection.component';
import { ConfigExcelDialogComponent } from './tenant-tiles/config-excel.component';
import { ConfigPowerBIDialogComponent } from './tenant-tiles/config-powerbi.component';

import { TrainingComponent } from './training/training.component';
import { FileBrowserComponent } from './files/file-browser.component';

import { LicenseSelectionComponent } from './organization/license-selection.component';
import { AppRolesSelectionComponent } from './organization/approles-selection.component';

import {
  PublicClientApplication,
  InteractionType,
  BrowserCacheLocation,
} from '@azure/msal-browser';
import { ManageAppsComponent } from './apps/manage-apps.component';
import { TenantAppComponent } from './apps/tenant-app.component';

let protectedResources = new Map<string, string[] | null>();
protectedResources.set('/api/v1/tickets*', null);
protectedResources.set('/api/v1/invitation/resolve', null);
protectedResources.set('/api/v1/marketplace/resolve', null);
protectedResources.set('/api/v1/provisioning/company', null);
protectedResources.set('/api/v1/*', [environment.clientId]);

const isIE = false;
//  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
//  window.navigator.userAgent.indexOf('Trident/') > -1);

@NgModule({
  declarations: [
    AppComponent,
    TenantpoolsComponent,
    StartpageComponent,
    OnboardingComponent,
    OrganizationComponent,
    TenantsComponent,
    AddUserDialogComponent,
    EditUserDialogComponent,
    InviteUserDialogComponent,
    InvitationConfirmationComponent,
    AddDatabaseDialogComponent,
    EditTenantDialogComponent,
    ExtensionSelectionComponent,
    DatabaseBackupDialogComponent,
    DatabaseRestoreDialogComponent,
    CopyDatabaseDialogComponent,
    TenantTilesComponent,
    TilesComponent,
    OrganizationsComponent,
    AddOrganizationDialogComponent,
    FirstStepsDialogComponent,
    ImprintComponent,
    PrivacyComponent,
    LoginComponent,
    ConfigMobileDialogComponent,
    ConfigMobileServiceDialogComponent,
    ConfigCobiWmsDialogComponent,
    ConfigExcelDialogComponent,
    ConfigPowerBIDialogComponent,
    ConfigCrystalDialogComponent,
    ManageAppsComponent,
    TenantAppComponent,
    AccessKeyComponent,
    CredentialsComponent,
    LicenseSelectionComponent,
    AppRolesSelectionComponent,
    UserRoleTextPipe,
    BusinessOneLicenseTextPipe,
    LicenseTextPipe,
    AdministrationComponent,
    CommonTilesComponent,
    AppSelectionComponent,
    TrainingComponent,
    FileBrowserComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    MatToolbarModule,
    MatTableModule,
    MatCheckboxModule,
    MatInputModule,
    MatCardModule,
    MatDividerModule,
    MatGridListModule,
    MatButtonModule,
    MatStepperModule,
    MatSelectModule,
    MatProgressBarModule,
    MatListModule,
    MatSidenavModule,
    MatIconModule,
    MatMenuModule,
    MatBadgeModule,
    MatChipsModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatTabsModule,
    MatTreeModule,
    MatSlideToggleModule,
    ClipboardModule,
    FlexLayoutModule,
    MsalModule.forRoot(
      new PublicClientApplication({
        // MSAL Configuration
        auth: {
          clientId: environment.clientId,
          authority: environment.authority,
          knownAuthorities: ['businessoneservice.b2clogin.com','businessoneprod.b2clogin.com'],
          redirectUri: `${window?.location?.protocol}//${
            window?.location?.hostname
          }${window?.location?.port ? ':' : ''}${window?.location?.port}${
            environment.redirectUri
          }`,
        },
        cache: {
          cacheLocation: BrowserCacheLocation.LocalStorage,
          storeAuthStateInCookie: isIE,
        },
        system: {
          loggerOptions: {
            loggerCallback: (
              level: any,
              message: string,
              containsPii: boolean
            ) => {
              console.log(message);
            },
            logLevel: 1,
            piiLoggingEnabled: false,
          },
        },
      }),
      {
        interactionType: InteractionType.Popup,
        authRequest: {
          scopes: [],
        },
      },
      {
        interactionType: InteractionType.Popup, // MSAL Interceptor Configuration
        protectedResourceMap: protectedResources,
        //unprotectedResources: unprotectedResourceUris,
      }
    ),
    BrowserAnimationsModule,
    ReactiveFormsModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
