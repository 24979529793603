<div style="background-color: transparent;" class="contentarea">
    <div *ngIf="accountService.account == null || organization == null">
        <div fxFill fxLayout="column" fxLayoutAlign="start center" style="max-width: 1230px;background-color: #F9FBFC;">
            <div style="background-color: #2a6bb7;color: white; text-align: center;width: 100%;padding: 15px;">
                <a i18n="start page|Link to signup page" routerLink="/signup" style="margin: 0px;color: #F9FBFC; font-size: 12pt;text-decoration: none;">SAP Business One Cloud jetzt kostenlos und unverbindlich testen &gt;</a>
            </div>
            <div fxLayout="row" style="max-width: 1230px;background-color: #2c4a65;width: 100%;">
                <div fxLayout="column" fxLayoutAlign="start center" fxFlex style="height: 380px;">
                    <div style="color: #F9FBFC;margin-top: 60px;" fxLayout="column" fxLayoutAlign="start center">
                        <h2  i18n="start page|Welcome message header" style="text-align: center;">
                            Willkommen in der SAP BUSINESS ONE CLOUD
                        </h2>
                        <span i18n="start page|Welcome message part 1" style="text-align: center;">für kleine Unternehmen und Start-Ups,</span>

                        <span i18n="start page|Welcome message part 2" *ngIf="showPrice" style="text-align: center;">SAP Business One ab <a href="https://business-one-consulting.com/kosten/" style="color: cornflowerblue;" target="_blank">EUR 49 pro Monat</a> (Lizenz und
                            Hosting)</span>
                    </div>
                    <div fxLayout="row" style="margin-top: 60px;" fxLayoutGap="20px">
                        <a i18n="sign in" href="#" mat-flat-button color="#F9FBFC"(click)="login()">Anmelden</a>
                        <a *ngIf="showPrice" i18n="link|price" href="https://business-one-consulting.com/service/preise/" target="_blank" mat-flat-button
                            color="#F9FBFC">Preise</a>
                        <a i18n="link|Try now" routerLink="/signup" mat-flat-button color="#F9FBFC">Jetzt testen</a>
                    </div>
                </div>
                <div style="width: 615px;height: 380px; overflow: hidden;" fxFlex fxHide.lt-lg>
                    <img width="650"  src="./assets/service.jpg" />
                </div>
            </div>
            <div style="color: black;text-align: center;margin-top: 32px;">
                <h2 i18n="start page|access methods" >Die SAP Business One Cloud unterstützt folgende Zugangsarten:</h2>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="center">
                <div class="tile" fxLayout="column" fxLayoutAlign="start center">
                    <div style="text-align: center;">Web Client</div>
                    <img class="tile" src="./assets/Web Client.svg" />
                </div>
                <div class="tile" fxLayout="column" fxLayoutAlign="start center">
                    <div style="text-align: center;">Browser Access</div>
                    <img class="tile" src="./assets/Browser Access.svg" />
                </div>
                <div class="tile" fxLayout="column" fxLayoutAlign="start center">
                    <div style="text-align: center;">Mobile</div>
                    <img class="tile" src="./assets/Mobile Apps.svg" />
                </div>
                <div class="tile" fxLayout="column" fxLayoutAlign="start center">
                    <div style="text-align: center;">Desktop Client</div>
                    <img class="tile" src="./assets/Desktop.svg" />
                </div>
            </div>
            <img style="width: 100%;max-width: 1230px" i18n i18n-src src="./assets/de/start_01.jpg" />
            <div fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="20px">
                <a i18n="link|imprint" [routerLink]="'/imprint'" mat-flat-button color="#F9FBFC">Impressum</a>
                <!--<a href="#" mat-flat-button color="#F9FBFC">Nutzungsbedingungen</a>-->
                <a i18n="link|privacy" [routerLink]="'/privacy'" mat-flat-button color="#F9FBFC">Datenschutz</a>
            </div>
        </div>
    </div>
    <div *ngIf="accountService.account != null && organization != null" fxFlex fxLayout="column"
        style="margin-top: 20px;" ngClass.xs="xsmall" ngClass.gt-xs="small">
        <div>
            <h1 style="text-align: center;" class="text">{{ organization?.displayName }}</h1>
        </div>
        <div>
            <div fxLayout="column" fxLayoutAlign="start start">
                <div fxFill *ngFor="let t of tenants" fxLayout="column"> 
                    <app-tenant-tiles *ngIf="t.databaseState === 'STARTED'" fxFlex [tenant]="t"></app-tenant-tiles>
                    <div *ngIf="t.databaseState === 'STARTED'" style="margin-top: 50px;">&nbsp;</div>
                </div>
                <div fxFill>
                    <app-common-tiles></app-common-tiles>
                </div>
            </div>
        </div>

    </div>
</div>