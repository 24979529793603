import { Component, Inject, LOCALE_ID, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AccountService, Account } from '../account.service';
import { Organization, OrganizationMember, OrganizationService } from '../organization.service';
import { ProvisioningService, DatabaseTemplate, ProvisionState, MicrosoftSubscriptionInfo, AutocompleteResult } from '../provisioning.service';
import { MatStepper } from '@angular/material/stepper';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, switchMap, catchError } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatRadioChange } from '@angular/material/radio';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.css']
})
export class OnboardingComponent implements OnInit, OnDestroy {


  companyFormGroup: FormGroup;
  companyAutcompleteResults: AutocompleteResult[] = [];


  customizeTemplateFormGroup: FormGroup;
  reviewFormGroup: FormGroup;
  isOptional = false;

  createTenant = true;

  accountChecked = false;
  accountChecking = false;
  provisionStarted = false;
  provisionCompleted = false;

  microsoftStoreToken: string;
  microsoftStoreTokenInvalid: boolean;
  microsoftResolvingToken: boolean;
  microsoftSubscription: MicrosoftSubscriptionInfo;

  campaign: string;

  tenantId: string;

  companyName: string;

  progress: number;

  dataProtection: boolean;

  accountSubscription: Subscription;

  public get isMicrosoftMarketplace(): boolean {
    return this.microsoftStoreToken != null;
  }


  @ViewChild('stepperVertical', { static: true }) private stepperVertial: MatStepper;
  @ViewChild('stepperHorizontal', { static: true }) private stepperHorizontal: MatStepper;

  public get account(): Account {
    return this.accountService.account;
  }

  public get loggedIn(): boolean {
    return this.account != null;
  }

  public get activeOrganization(): Organization {
    return this.orgService.activeOrganization;
  }

  constructor(
    private formBuilder: FormBuilder, private accountService: AccountService, private provisionService: ProvisioningService,
    private route: ActivatedRoute, private router: Router, private orgService: OrganizationService, private org: OrganizationService,
    @Inject(LOCALE_ID) public locale: string) { }

  ngOnDestroy(): void {
    this.accountSubscription.unsubscribe();
  }



  private updateAccountData(a: Account): void {
    if (a == null) {
      //      this.companyFormGroup.get('firstNameCtrl').setValue('');
      //      this.companyFormGroup.get('lastNameCtrl').setValue('');
      //      this.companyFormGroup.get('b1userCtrl').setValue('');
    }
    else {
      //      this.companyFormGroup.get('firstNameCtrl').setValue(a.firstName);
      //      this.companyFormGroup.get('lastNameCtrl').setValue(a.lastName);
      /*      if (a.firstName !== null && a.lastName != null) {
              let code = this.replaceChars(a.firstName) + '.' + this.replaceChars(a.lastName);
              if (code.length > 25) {
                code = code.substr(0, 25);
              }
              this.companyFormGroup.get('b1userCtrl').setValue(code);
            }
      */
      if (this.stepperVertial?.selected != null && this.stepperVertial.selectedIndex === 0) {
        this.stepperVertial.selected.completed = true;
        this.stepperVertial.next();
      }
      if (this.stepperHorizontal?.selected != null && this.stepperHorizontal.selectedIndex === 0) {
        this.stepperHorizontal.selected.completed = true;
        this.stepperHorizontal.next();
      }

      if (this.stepperVertial?.selected != null && this.stepperVertial.selectedIndex === 2) {
        this.accountChecking = true;
        setTimeout(() => {
          this.accountChecking = false;
          this.accountChecked = true;
          if (this.orgService.activeOrganization == null) {
            this.provision();
          }
        }, 1000);
      }
      if (!this.accountChecking && this.stepperVertial?.selectedIndex !== 2) {
        this.accountChecked = true;
      }

      if (this.stepperHorizontal?.selected != null && this.stepperHorizontal.selectedIndex === 2) {
        this.accountChecking = true;
        setTimeout(() => {
          this.accountChecking = false;
          this.accountChecked = true;
          if (this.orgService.activeOrganization == null) {
            this.provision();
          }
        }, 1000);
      }
      if (!this.accountChecking && this.stepperHorizontal?.selectedIndex !== 2) {
        this.accountChecked = true;
      }
    }
  }

  public get isVertical(): boolean {
    const value = this.stepperVertial?.selected && this.stepperVertial.selectedIndex === 2;
    if (value === undefined) {
      return false;
    }
    return true;
  }

  public get isHorizontal(): boolean {
    const value = this.stepperHorizontal?.selected && this.stepperHorizontal.selectedIndex === 2;
    if (value === undefined) {
      return false;
    }
    return true;
  }


  private replaceChars(s: string): string {
    s = s.toLowerCase();
    s = s.replace(/ä/g, 'ae');
    s = s.replace(/ö/g, 'oe');
    s = s.replace(/ü/g, 'ue');
    s = s.replace(/ß/g, 'ss');
    s = s.replace(/ /g, '-');
    s = s.replace(/\./g, '');
    s = s.replace(/,/g, '');
    s = s.replace(/\(/g, '');
    s = s.replace(/\)/g, '');
    return s;
  }

  private done(): void {
    this.addProgress(100);
    this.provisionCompleted = true;
    this.launch();
  }

  launch(): void {
    this.router.navigateByUrl('/');
  }

  public provision(): void {

    if (this.provisionStarted) {
      return;
    }

    this.provisionStarted = true;

    const org: Organization = {
      displayName: this.companyName,
      city: this.companyFormGroup.get('cityCtrl').value,
      country: this.companyFormGroup.get('countryCtrl').value,
      countryLetterCode: this.companyFormGroup.get('countryCodeCtrl').value,
      postalCode: this.companyFormGroup.get('postCodeCtrl').value,
      state: this.companyFormGroup.get('stateCtrl').value,
      street: this.companyFormGroup.get('streetCtrl').value,

      members: [],

      availableApps: [],
      id: '',
      licensedProducts: [],
      purpose: ''
    };
    const owner = new OrganizationMember();
    owner.name = this.account.name;
    if (!owner.name || owner.name === '') {
      owner.name = 'Unbekannt';
    }
    owner.firstName = this.account.firstName ?? '';
    owner.lastName = this.account.lastName ?? '';
    if (owner.firstName !== null && owner.lastName != null) {
      let code = this.replaceChars(owner.firstName) + '.' + this.replaceChars(owner.lastName);
      if (code.length > 25) {
        code = code.substr(0, 25);
      }
      owner.businessOneUserName = code;
    }
    owner.role = 'owner';
    org.members.push(owner);

    const dbs: DatabaseTemplate[] = [];
    if (this.createTenant) {
      const db = new DatabaseTemplate();
      db.name = org.displayName;
      db.description = org.displayName;
      db.templateId = 'demo';
      dbs.push(db);
    }

    const c: OnboardingComponent = this;

    this.provisionService.provision(
      'trial',
      org,
      this.microsoftStoreToken,
      dbs,
      this.campaign,
      this.locale
    ).subscribe({
      next(x) {
        if (x.organizationId != null && x.organizationId !== c.orgService.activeOrganization?.id) {
          c.orgService.switchActiveOrganization(x.organizationId);
        }
        if (x.tenants !== undefined && x.tenants != null && x.tenants.length > 0) {
          c.tenantId = x.tenants[0].tenantId;
        }
        if (x.state !== ProvisionState.Running) {
          c.done();
        }
      },
      complete() {
        c.done();
      }
    });

    this.progress = 1;
    setTimeout(() => {
      this.addProgress(12);
      setTimeout(
        () => {
          this.addProgress(24);
          setTimeout(
            () => {
              this.addProgress(10);
              setTimeout(
                () => {
                  this.addProgress(7);
                  setTimeout(
                    () => {
                      this.addProgress(20);
                      setTimeout(
                        () => {
                          this.addProgress(10);
                          setTimeout(
                            () => {
                              this.addProgress(10);
                            }, 7000
                          );
                        }, 5000
                      );
                    }, 8000
                  );
                }, 3000
              );
            }, 5000
          );
        }, 10000
      );
    }, 5000);

  }



  addProgress(n: number): void {
    if (this.progress < 100) {
      this.progress += n;
    }
  }

  ngOnInit() {

    this.dataProtection = false;

    this.route.queryParamMap.subscribe(m => {

      this.campaign = m.get('campaign');
      const gclid = m.get('gclid');
      if (gclid !== null) {
        this.campaign = 'Google Ad';
      }

      this.microsoftStoreToken = m.get('token');
      this.microsoftStoreTokenInvalid = true;
      this.microsoftResolvingToken = true;
      if (this.microsoftStoreToken != null) {
        this.provisionService.resolveMicrosoftToken(this.microsoftStoreToken).toPromise().
          then(data => {
            this.microsoftSubscription = data;
            this.microsoftStoreTokenInvalid = false;
            this.microsoftResolvingToken = false;
          }).
          catch(error => {
            this.microsoftStoreTokenInvalid = true;
            this.microsoftResolvingToken = false;
          });
      }
    }
    );

    this.companyFormGroup = this.formBuilder.group({
      nameCtrl: ['', Validators.required],
      streetCtrl: [''],
      postCodeCtrl: [''],
      cityCtrl: [''],
      countryCodeCtrl: [''],
      countryCtrl: [''],
      stateCtrl: [''],

      /*      firstNameCtrl: ['', Validators.required],
            lastNameCtrl: ['', Validators.required],
            b1userCtrl: ['', Validators.required],*/
    });
    this.updateAccountData(this.account);

    this.companyFormGroup.get('nameCtrl').valueChanges
      .pipe(
        debounceTime(300),
        switchMap(value => {
          return this.provisionService.companyAutocomplete(value.name === undefined ? value : value.name);
        }
        ),
        catchError(error => {
          return [];
        })
      ).subscribe(results => {
        this.companyAutcompleteResults = results;
      });

    this.customizeTemplateFormGroup = this.formBuilder.group({
      nameCtrl: [''],
      descriptionCtrl: [''],
      templateCtrl: [''],
    });
    this.reviewFormGroup = this.formBuilder.group({
      firstCtrl: ''
    });

    this.accountSubscription =
      this.accountService.accountChanged.subscribe((a: Account) => {
        this.updateAccountData(a);
        if (a != null) {
          this.provisionService.signup(this.campaign).toPromise().then(() => { });
        }
      });

    if (this.loggedIn) {
      this.stepperVertial.selectedIndex = 1;
      this.stepperHorizontal.selectedIndex = 1;
    }
    //this.companyFormGroup.disable();
  }

  displayCompanyAutocomplete(result: AutocompleteResult): string {
    return result.name;
  }

  updateFromAutocomplete(e: MatAutocompleteSelectedEvent): void {
    const autoResult = e.option.value as AutocompleteResult;
    this.provisionService.getCompanyDetails(autoResult.placeId).toPromise().then(details => {
      // this.companyFormGroup.get('nameCtrl').setValue(details.name);
      this.companyFormGroup.get('cityCtrl').setValue(details.city);
      this.companyFormGroup.get('countryCtrl').setValue(details.country);
      this.companyFormGroup.get('countryCodeCtrl').setValue(details.countryCode);
      this.companyFormGroup.get('postCodeCtrl').setValue(details.postalCode);
      this.companyFormGroup.get('stateCtrl').setValue(details.state);
      this.companyFormGroup.get('streetCtrl').setValue(details.street + ' ' + details.streetNumber);
    });
  }


  onCreateTenantChanged(e: MatCheckboxChange) {
    this.createTenant = e.checked;
  }

  isString(value) {
    return typeof value === 'string' || value instanceof String;
  }

  onStepChange(e: StepperSelectionEvent) {

    if (e.selectedIndex === 1) {
      const v = this.companyFormGroup.get('nameCtrl').value;
      if (this.isString(v)) {
        this.companyName = v;
      } else {
        this.companyName = v?.name ?? '';
      }
      this.customizeTemplateFormGroup.get('nameCtrl').setValue(this.companyName);
      this.customizeTemplateFormGroup.get('descriptionCtrl').setValue(this.companyName + ' Test Mandant');
      this.customizeTemplateFormGroup.get('templateCtrl').setValue('demo');
    }
    if (e.selectedIndex === 2) {
      const v = this.companyFormGroup.get('nameCtrl').value;
      if (this.isString(v)) {
        this.companyName = v;
      } else {
        this.companyName = v?.name ?? '';
      }
    }
  }

  welcomeNext() {
    if (this.stepperVertial?.selected != null && this.stepperVertial.selectedIndex === 0) {
      this.stepperVertial.selected.completed = true;
      this.stepperVertial.next();
    }
    if (this.stepperHorizontal?.selected != null && this.stepperHorizontal.selectedIndex === 0) {
      this.stepperHorizontal.selected.completed = true;
      this.stepperHorizontal.next();
    }
  }

  companyNext() {
    if (!this.companyFormGroup.valid) {
      return;
    }
    if (this.stepperVertial?.selected != null && this.stepperVertial.selectedIndex === 1) {

      this.stepperVertial.selected.completed = true;
      this.stepperVertial.next();
    }
    if (this.stepperHorizontal?.selected != null && this.stepperHorizontal.selectedIndex === 1) {
      this.stepperHorizontal.selected.completed = true;
      this.stepperHorizontal.next();
    }
  }

  signinMicrosoft(): void {
    this.accountService.login('commonaad');
  }

  companyRadioChanged(r: MatRadioChange) {
    if (r.value === 'demo') {
      this.companyFormGroup.disable();
    } else {
      this.companyFormGroup.enable();
    }
  }

}
