import { Component, OnInit, } from '@angular/core';
import { AccountService, Account } from '../account.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizationService, Invitation } from '../organization.service';

@Component({
  selector: 'app-invitation-confirmation',
  templateUrl: './invitation-confirmation.component.html',
  styleUrls: ['./invitation-confirmation.component.css']
})
export class InvitationConfirmationComponent implements OnInit {

  public token: string;
  public invitation: Invitation;
  public invitationAccepted = false;

  public get account(): Account {
    return this.accountService.account;
  }

  public get loggedIn(): boolean {
    return this.account != null;
  }

  constructor(private accountService: AccountService, private orgService: OrganizationService, private route: ActivatedRoute,
    private router: Router) { }

  login(): void {
    this.accountService.login(null);    
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(m => {
      this.token = m.get('token');
      this.orgService.resolveInvitation(this.token).subscribe(i => {
        this.invitation = i;
      });
    }
    );
  }

  accept(): void {
    this.orgService.acceptInvitation(this.token).toPromise().then(x => {
      this.invitationAccepted = true;
      this.orgService.switchActiveOrganization(this.invitation.organizationId);
      this.router.navigateByUrl('/');
    });
  }
}
