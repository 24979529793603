import { Component, OnInit, ViewChild, TemplateRef, Directive, Inject } from '@angular/core';
import { Organization, OrganizationService, OrganizationInfo } from '../organization.service';
import { Tile } from '../tiles/tiles.component';
import { AccountService } from '../account.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

class OrganizationTile extends Tile {
  organization: OrganizationInfo;
}

@Component({
  selector: 'app-organizations',
  templateUrl: './organizations.component.html',
  styleUrls: ['./organizations.component.css']
})
export class OrganizationsComponent implements OnInit {

  @ViewChild('organizationTemplate', { static: true })
  private organizationTpl: TemplateRef<any>;

  @ViewChild('addOrganizationTemplate', { static: true })
  private addOrganizationTpl: TemplateRef<any>;

  tiles: Tile[] = [];

  get isOperator(): boolean {
    return this.accountService.account && this.accountService.account.isOperator;
  }

  constructor(private organizationService: OrganizationService, private accountService: AccountService, public dialog: MatDialog) { }

  ngOnInit(): void {

    if (this.isOperator) {
      const add = new Tile();
      add.tileTemplate = this.addOrganizationTpl;
      this.tiles.push(add);

    }

    this.organizationService.getAvailableOrganizations().toPromise().then(orgs => {
      for (const org of orgs) {
        const t = new OrganizationTile();
        t.organization = org;
        t.tileTemplate = this.organizationTpl;
        this.tiles.push(t);
      }
    });

  }

  selectOrganization(org: OrganizationInfo) {
    this.organizationService.switchActiveOrganization(org.id);
  }



  addOrganization(): void {
    const o: Organization = {
      displayName: '',
      members: [],
      purpose: 'Production',
      availableApps: [],
      city: '',
      country: '',
      countryLetterCode: '',
      id: '',
      licensedProducts: [],
      postalCode: '',
      state: '',
      street: ''
    };
    const orgData: any = {
      organization: o,
    };

    const dialogRef = this.dialog.open(AddOrganizationDialogComponent, {
      width: '600px',
      data: orgData,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.organizationService.createOrganization(o, o).finally(() => { });
      }
    });
  }

}


@Component({
  selector: 'app-dialog-add-organization',
  templateUrl: 'dialog-add-organization.html',
  styleUrls: ['./organizations.component.css']
})
export class AddOrganizationDialogComponent {

  organization: Organization;

  constructor(
    public dialogRef: MatDialogRef<AddOrganizationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.organization = data.organization;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  isValid() {
    return this.organization.displayName && this.organization.displayName.trim() !== '';
  }

}
