import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import {
  DatabaseService,
  Database,
  Extension,
  BackupSource,
  DatabaseBackup,
} from '../database.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { FormControl } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import {
  Organization,
  OrganizationInfo,
  OrganizationService,
} from '../organization.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-tenants',
  templateUrl: './tenants.component.html',
  styleUrls: ['./tenants.component.css'],
})
export class TenantsComponent implements OnInit {
  constructor(
    private databaseService: DatabaseService,
    private orgs: OrganizationService,
    public dialog: MatDialog
  ) {}

  databases: Database[] = [];

  databaseSelection: SelectionModel<Database> = new SelectionModel(true, []);
  columnsToDisplay = ['select', 'name', 'description', 'databaseState'];

  ngOnInit(): void {
    this.databaseService
      .getDatabases()
      .toPromise()
      .then((dbs) => {
        this.databases = dbs;
      });
  }

  async onAddDatabase(): Promise<void> {
    const allOrgs = await this.orgs.getAvailableOrganizations().toPromise();
    const databaseData: CreateTenantParameters = {
      name: '',
      description: '',
      online: false,
      purpose: 'Test',
      availableOrganization: allOrgs,
      source: {
        backupId: '',
        organization: this.orgs.activeOrganization.id,
        tenant: '',
      },
    };

    const dialogRef = this.dialog.open(AddDatabaseDialogComponent, {
      width: '600px',
      data: databaseData,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.databaseService
          .createDatabase(
            databaseData.name,
            databaseData.description,
            databaseData.online,
            databaseData.purpose,
            'backup',
            databaseData.source
          )
          .toPromise()
          .then((_) => console.log('ok'));
      }
    });
  }

  onEditTenant(): void {
    const tenant = this.databaseSelection.selected[0];
    const tenantData: any = {
      tenant,
    };

    const dialogRef = this.dialog.open(EditTenantDialogComponent, {
      width: '600px',
      data: tenantData,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        tenant.extensions = tenantData.newExtensions;
        this.databaseService.updateTenant(tenant, tenant).finally(() => {});
      }
    });
  }

  onStartDatabase(): void {
    this.databaseService
      .startDatabase(this.databaseSelection.selected[0].id)
      .toPromise()
      .then((_) => console.log('starting'));
  }

  onStopDatabase(): void {
    this.databaseService
      .stopDatabase(this.databaseSelection.selected[0].id)
      .toPromise()
      .then((_) => console.log('starting'));
  }

  onBackupDatabase(): void {
    const backupData: any = {
      description: '',
    };

    const dialogRef = this.dialog.open(DatabaseBackupDialogComponent, {
      width: '600px',
      data: backupData,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.databaseService
          .backupDatabase(
            this.databaseSelection.selected[0].id,
            backupData.description
          )
          .toPromise()
          .then((_) => console.log('ok'));
      }
    });
  }

  onRestoreDatabase(): void {
    this.databaseService
      .getDatabaseBackups(this.databaseSelection.selected[0].id)
      .toPromise()
      .then((backups) => {
        const backupData: any = {
          backups,
          selectedBackup: null,
        };

        const dialogRef = this.dialog.open(DatabaseRestoreDialogComponent, {
          width: '600px',
          data: backupData,
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.databaseService
              .restoreDatabase(
                this.databaseSelection.selected[0].id,
                backupData.selectedBackup
              )
              .toPromise()
              .then((_) => console.log('ok'));
          }
        });
      });
  }

  get canCopyFrom(): boolean {
    return (
      this.databaseSelection.selected.length === 1 &&
      this.databaseSelection.selected[0].databaseState === 'STOPPED'
    );
  }

  get canRestore(): boolean {
    return this.databaseSelection.selected.length === 1;
  }

  get canBackup(): boolean {
    return this.databaseSelection.selected.length === 1;
  }

  get canStart(): boolean {
    return this.databaseSelection.selected.length === 1;
  }

  get canStop(): boolean {
    return this.databaseSelection.selected.length === 1;
  }

  get canDelete(): boolean {
    return this.databaseSelection.selected.length === 1;
  }

  async onCopyFrom(): Promise<void> {
    const allOrgs = await this.orgs.getAvailableOrganizations().toPromise();
    const databaseData: CopyTenantParameters = {
      availableOrganization: allOrgs,
      source: {
        backupId: '',
        organization: this.orgs.activeOrganization.id,
        tenant: '',
      },
    };

    const dialogRef = this.dialog.open(CopyDatabaseDialogComponent, {
      width: '600px',
      data: databaseData,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.databaseService
          .copyDatabaseFrom(
            this.databaseSelection.selected[0].organizationId,
            this.databaseSelection.selected[0].id,
            databaseData.source
          )
          .then()
          .catch();
      }
    });
  }

  onDeleteDatabase(): void {
    this.databaseService
      .deleteDatabase(this.databaseSelection.selected[0].id)
      .toPromise()
      .then((_) => console.log('deleting'));
  }
}

export interface CreateTenantParameters {
  name: string;
  description: string;
  online: boolean;
  purpose: 'Production' | 'Test';
  availableOrganization: OrganizationInfo[];
  source: BackupSource;
}

@Component({
  selector: 'app-dialog-add-database',
  templateUrl: 'dialog-add-database.html',
  styleUrls: ['./tenants.component.css'],
})
export class AddDatabaseDialogComponent implements OnInit {
  orgControl = new FormControl('');
  tenantControl = new FormControl('');
  backupControl = new FormControl('');

  orgs: OrganizationInfo[] = [];
  filteredOrgs: Observable<OrganizationInfo[]>;

  tenants: Database[] = [];
  filteredTenants: Observable<Database[]>;

  backups: DatabaseBackup[] = [];
  filteredBackups: Observable<DatabaseBackup[]>;

  public get selectedOrganization(): OrganizationInfo | null {
    return (
      this.orgs.find((o) => o.id === this.data.source.organization) ?? null
    );
  }

  constructor(
    public dialogRef: MatDialogRef<AddDatabaseDialogComponent>,
    private tenantService: DatabaseService,
    @Inject(MAT_DIALOG_DATA) public data: CreateTenantParameters
  ) {
    this.orgs = data.availableOrganization;
  }

  ngOnInit(): void {
    this.filteredOrgs = this.orgControl.valueChanges.pipe(
      startWith(''),
      map((value) => this.filterOrgs(value || ''))
    );
    this.orgControl.setValue(this.selectedOrganization);

    this.filteredTenants = this.tenantControl.valueChanges.pipe(
      startWith(''),
      map((value) => this.filterTenants(value || ''))
    );
    this.tenantControl.setValue(null);

    this.filteredBackups = this.backupControl.valueChanges.pipe(
      startWith(''),
      map((value) => this.filterBackups(value || ''))
    );
    this.backupControl.setValue(null);

    void this.selectOrg(this.selectedOrganization);
  }

  displayOrg(org: OrganizationInfo | null): string {
    return org?.name ?? '-';
  }

  displayTenant(tenant: Database | null): string {
    return tenant == null ? '-' : `${tenant.id} - ${tenant.name}`;
  }

  displayBackup(backup: DatabaseBackup | null): string {
    if (backup == null) {
      return '-';
    }
    const datePipe = new DatePipe('en-US');
    return (
      datePipe.transform(backup.createdOn) + ' ' + backup?.description ?? '-'
    );
  }

  private filterOrgs(value: string | OrganizationInfo): OrganizationInfo[] {
    if (typeof value === 'string') {
      const filterValue = value.toLowerCase();

      return this.orgs.filter((option) =>
        option.name.toLowerCase().includes(filterValue)
      );
    } else {
      return [value];
    }
  }

  private filterTenants(value: string | Database): Database[] {
    if (typeof value === 'string') {
      const filterValue = value.toLowerCase();

      return this.tenants.filter((option) =>
        option.name.toLowerCase().includes(filterValue)
      );
    } else {
      return [value];
    }
  }

  private filterBackups(value: string | DatabaseBackup): DatabaseBackup[] {
    if (typeof value === 'string') {
      const filterValue = value.toLowerCase();

      return this.backups.filter((option) =>
        option.description.toLowerCase().includes(filterValue)
      );
    } else {
      return [value];
    }
  }

  async selectOrg(org: OrganizationInfo | null): Promise<void> {
    this.data.source.organization = org.id;
    this.data.source.tenant = null;
    this.data.source.backupId = null;
    this.tenants = await this.tenantService.getDatabasesOfOrg(
      this.data.source.organization
    );
    if (this.tenants.length > 0) {
      this.tenantControl.setValue(this.tenants[0]);
      this.selectTenant(this.tenants[0]);
    } else {
      this.tenantControl.setValue(null);
      this.selectTenant(null);
    }
    this.backupControl.setValue(null);
  }

  async selectTenant(tenant: Database | null): Promise<void> {
    this.data.source.tenant = tenant?.id ?? null;
    this.data.source.backupId = null;
    if (tenant != null) {
      this.backups = await this.tenantService.getDatabaseBackupsByOrgAndTenant(
        this.data.source.organization,
        this.data.source.tenant
      );
      const backup =
        this.backups.length == 0 ? null : this.backups[this.backups.length - 1];
      this.backupControl.setValue(backup);
      this.selectBackup(backup);
    } else {
      this.backups = [];
      this.backupControl.setValue(null);
      this.selectBackup(null);
    }
  }

  async selectBackup(backup: DatabaseBackup | null): Promise<void> {
    this.data.source.backupId = backup?.backupId ?? null;
  }
}

export interface CopyTenantParameters {
  availableOrganization: OrganizationInfo[];
  source: BackupSource;
}

@Component({
  selector: 'app-dialog-copy-database',
  templateUrl: 'dialog-copy-database.html',
  styleUrls: ['./tenants.component.css'],
})
export class CopyDatabaseDialogComponent implements OnInit {
  orgControl = new FormControl('');
  tenantControl = new FormControl('');
  backupControl = new FormControl('');

  orgs: OrganizationInfo[] = [];
  filteredOrgs: Observable<OrganizationInfo[]>;

  tenants: Database[] = [];
  filteredTenants: Observable<Database[]>;

  backups: DatabaseBackup[] = [];
  filteredBackups: Observable<DatabaseBackup[]>;

  public get selectedOrganization(): OrganizationInfo | null {
    return (
      this.orgs.find((o) => o.id === this.data.source.organization) ?? null
    );
  }

  constructor(
    public dialogRef: MatDialogRef<AddDatabaseDialogComponent>,
    private tenantService: DatabaseService,
    @Inject(MAT_DIALOG_DATA) public data: CopyTenantParameters
  ) {
    this.orgs = data.availableOrganization;
  }

  ngOnInit(): void {
    this.filteredOrgs = this.orgControl.valueChanges.pipe(
      startWith(''),
      map((value) => this.filterOrgs(value || ''))
    );
    this.orgControl.setValue(this.selectedOrganization);

    this.filteredTenants = this.tenantControl.valueChanges.pipe(
      startWith(''),
      map((value) => this.filterTenants(value || ''))
    );
    this.tenantControl.setValue(null);

    this.filteredBackups = this.backupControl.valueChanges.pipe(
      startWith(''),
      map((value) => this.filterBackups(value || ''))
    );
    this.backupControl.setValue(null);

    void this.selectOrg(this.selectedOrganization);
  }

  displayOrg(org: OrganizationInfo | null): string {
    return org?.name ?? '-';
  }

  displayTenant(tenant: Database | null): string {
    return tenant == null ? '-' : `${tenant.id} - ${tenant.name}`;
  }

  displayBackup(backup: DatabaseBackup | null): string {
    if (backup == null) {
      return '-';
    }
    const datePipe = new DatePipe('en-US');
    return (
      datePipe.transform(backup.createdOn) + ' ' + backup?.description ?? '-'
    );
  }

  private filterOrgs(value: string | OrganizationInfo): OrganizationInfo[] {
    if (typeof value === 'string') {
      const filterValue = value.toLowerCase();

      return this.orgs.filter((option) =>
        option.name.toLowerCase().includes(filterValue)
      );
    } else {
      return [value];
    }
  }

  private filterTenants(value: string | Database): Database[] {
    if (typeof value === 'string') {
      const filterValue = value.toLowerCase();

      return this.tenants.filter((option) =>
        option.name.toLowerCase().includes(filterValue)
      );
    } else {
      return [value];
    }
  }

  private filterBackups(value: string | DatabaseBackup): DatabaseBackup[] {
    if (typeof value === 'string') {
      const filterValue = value.toLowerCase();

      return this.backups.filter((option) =>
        option.description.toLowerCase().includes(filterValue)
      );
    } else {
      return [value];
    }
  }

  async selectOrg(org: OrganizationInfo | null): Promise<void> {
    this.data.source.organization = org.id;
    this.data.source.tenant = null;
    this.data.source.backupId = null;
    this.tenants = await this.tenantService.getDatabasesOfOrg(
      this.data.source.organization
    );
    if (this.tenants.length > 0) {
      this.tenantControl.setValue(this.tenants[0]);
      this.selectTenant(this.tenants[0]);
    } else {
      this.tenantControl.setValue(null);
      this.selectTenant(null);
    }
    this.backupControl.setValue(null);
  }

  async selectTenant(tenant: Database | null): Promise<void> {
    this.data.source.tenant = tenant?.id ?? null;
    this.data.source.backupId = null;
    if (tenant != null) {
      this.backups = await this.tenantService.getDatabaseBackupsByOrgAndTenant(
        this.data.source.organization,
        this.data.source.tenant
      );
      const backup =
        this.backups.length == 0 ? null : this.backups[this.backups.length - 1];
      this.backupControl.setValue(backup);
      this.selectBackup(backup);
    } else {
      this.backups = [];
      this.backupControl.setValue(null);
      this.selectBackup(null);
    }
  }

  async selectBackup(backup: DatabaseBackup | null): Promise<void> {
    this.data.source.backupId = backup?.backupId ?? null;
  }
}

@Component({
  selector: 'app-dialog-edit-tenant',
  templateUrl: 'dialog-edit-database.html',
  styleUrls: ['./tenants.component.css'],
})
export class EditTenantDialogComponent {
  allExtensions: Extension[];
  selectedExtensions: Extension[];

  tenant: Database;

  constructor(
    public dialogRef: MatDialogRef<EditTenantDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.tenant = data.tenant;

    this.allExtensions = [];
    this.selectedExtensions = this.tenant.extensions.slice();
    data.newExtensions = this.selectedExtensions;

    const elster = new Extension();
    elster.name = 'ELSTERLW';
    elster.namespace = 'SAP';
    this.allExtensions.push(elster);

    const payment = new Extension();
    payment.name = 'PaymentLW';
    payment.namespace = 'SAP';
    this.allExtensions.push(payment);

    const efm = new Extension();
    efm.name = 'EFMLW';
    efm.namespace = 'SAP';
    this.allExtensions.push(efm);

    const datev = new Extension();
    datev.name = 'Datev2LW';
    datev.namespace = 'SAP';
    this.allExtensions.push(datev);
  }
}

@Component({
  selector: 'app-dialog-backup-database',
  templateUrl: 'dialog-backup-database.html',
  styleUrls: ['./tenants.component.css'],
})
export class DatabaseBackupDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<DatabaseBackupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
}

@Component({
  selector: 'app-dialog-restore-database',
  templateUrl: 'dialog-restore-database.html',
  styleUrls: ['./tenants.component.css'],
})
export class DatabaseRestoreDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<DatabaseRestoreDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onOk(backupId: string): void {
    this.data.selectedBackup = backupId;
    this.dialogRef.close(true);
  }
}
