<mat-form-field style="width: 100%;">
    <mat-chip-list #chipList aria-label="Erweiterungen auswählen">
      <mat-chip
        *ngFor="let e of selectedExtensions"
        (removed)="remove(e)">
        {{e.name}}                    
        <mat-icon matChipRemove>cancel</mat-icon>                    
      </mat-chip>
      <input
        placeholder="Erweiterungen"
        #extensionInput
        [formControl]="extensionsCtrl"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (matChipInputTokenEnd)="add($event)">
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
     <mat-option *ngFor="let e of filteredExtensions" [value]="e">
        {{e.name}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>