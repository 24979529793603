import { CollectionViewer, DataSource, SelectionChange } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, Injectable, OnInit } from '@angular/core';
import { Direct } from 'protractor/built/driverProviders';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Directory, FilesService, File } from './files.service';
import saveAs from 'file-saver';
import { AccountService } from '../account.service';

export class DynamicFlatNode {
  constructor(public item: string, public level = 1, public expandable = false, public dir: Directory,
    public isLoading = false) { }
}

export class DynamicDatabase {

  constructor(private files: FilesService, private user: string) {

  }

  /** Initial data from database */
  initialData(): DynamicFlatNode[] {
    return [
      {
        expandable: true,
        isLoading: false,
        item: 'Meine Dateien',
        level: 0,
        dir: {
          name: this.user,
          path: ['home']
        }
      },
      {
        expandable: true,
        isLoading: false,
        item: 'Organisation',
        level: 0,
        dir: {
          name: 'organization',
          path: []
        }
      },
      {
        expandable: true,
        isLoading: false,
        item: 'Tenants',
        level: 0,
        dir: {
          name: 'tenants',
          path: []
        }
      }
    ];
  }

  async getChildren(node: DynamicFlatNode): Promise<DynamicFlatNode[] | undefined> {
    const path = (node.dir.path.length === 0 && node.dir.name === 'root') ? [] : [...node.dir.path, node.dir.name];
    const dirs = await this.files.getSubDirs(path);
    return [...dirs.map(d => {
      return {
        expandable: true,
        isLoading: false,
        item: d.name,
        level: node.level + 1,
        dir: d
      };
    })];
  }

  isExpandable(node: string): boolean {
    return true;
  }
}

export class DynamicDataSource implements DataSource<DynamicFlatNode> {

  dataChange = new BehaviorSubject<DynamicFlatNode[]>([]);

  get data(): DynamicFlatNode[] { return this.dataChange.value; }
  set data(value: DynamicFlatNode[]) {
    this._treeControl.dataNodes = value;
    this.dataChange.next(value);
  }

  constructor(private _treeControl: FlatTreeControl<DynamicFlatNode>,
    private _database: DynamicDatabase) { }

  connect(collectionViewer: CollectionViewer): Observable<DynamicFlatNode[]> {

    this._treeControl.expansionModel.changed.subscribe(change => {
      if ((change as SelectionChange<DynamicFlatNode>).added ||
        (change as SelectionChange<DynamicFlatNode>).removed) {
        this.handleTreeControl(change as SelectionChange<DynamicFlatNode>);
      }
    });

    return merge(collectionViewer.viewChange, this.dataChange).pipe(map(() => this.data));
  }

  disconnect(collectionViewer: CollectionViewer): void { }

  /** Handle expand/collapse behaviors */
  handleTreeControl(change: SelectionChange<DynamicFlatNode>) {
    if (change.added) {
      change.added.forEach(node => this.toggleNode(node, true));
    }
    if (change.removed) {
      change.removed.slice().reverse().forEach(node => this.toggleNode(node, false));
    }
  }

  /**
   * Toggle the node, remove from display list
   */
  async toggleNode(node: DynamicFlatNode, expand: boolean): Promise<void> {
    node.isLoading = true;
    const index = this.data.indexOf(node);
    const children = await this._database.getChildren(node);
    node.isLoading = false;
    if (expand) {
      this.data.splice(index + 1, 0, ...children);
    }
    else {
      let count = 0;
      for (let i = index + 1; i < this.data.length
        && this.data[i].level > node.level; i++, count++) { }
      this.data.splice(index + 1, count);
    }
    this.dataChange.next(this.data);
  }
}

@Component({
  selector: 'app-file-browser',
  templateUrl: './file-browser.component.html',
  styleUrls: ['./file-browser.component.css']
})
export class FileBrowserComponent implements OnInit {

  constructor(private files: FilesService, private accountService: AccountService) {
    this.treeControl = new FlatTreeControl<DynamicFlatNode>(this.getLevel, this.isExpandable);
    const database = new DynamicDatabase(files,accountService.account?.member?.adUser?.name)
    this.dataSource = new DynamicDataSource(this.treeControl, database);

    this.dataSource.data = database.initialData();
  }

  treeControl: FlatTreeControl<DynamicFlatNode>;
  selectedNode?: DynamicFlatNode;

  dataSource: DynamicDataSource;
  filesDatasource: File[] = [];
  columnsToDisplay = ['name', 'actions'];

  getLevel = (node: DynamicFlatNode) => node.level;

  isExpandable = (node: DynamicFlatNode) => node.expandable;

  hasChild = (_: number, _nodeData: DynamicFlatNode) => _nodeData.expandable;

  ngOnInit(): void {
  }

  async saveFile(file: File): Promise<void> {
    const blob = await this.files.loadFile(file.path, file.name);
    if (blob != null) {
      saveAs(blob, file.name);
    }
  }

  async selectNode(node: DynamicFlatNode): Promise<void> {
    this.selectedNode = node;
    if (this.selectedNode) {
      const path = (node.dir.path.length === 0 && node.dir.name === 'home') ? [] : [...node.dir.path, node.dir.name];
      this.filesDatasource = await this.files.getFiles(path);
    }
  }

}
