import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { TenantpoolsComponent } from './tenantpools/tenantpools.component';
import { StartpageComponent } from './startpage/startpage.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { OrganizationComponent } from './organization/organization.component';
import { TenantsComponent } from './tenants/tenants.component';
import { InvitationConfirmationComponent } from './invitation-confirmation/invitation-confirmation.component';
import { OrganizationsComponent } from './organizations/organizations.component';
import { ImprintComponent } from './startpage/imprint.component';
import { PrivacyComponent } from './startpage/privacy.component';
import { AdministrationComponent } from './administration/administration.component';
import { TrainingComponent } from './training/training.component';
import { FileBrowserComponent } from './files/file-browser.component';


const routes: Routes = [
  {
    path: 'tenantpools',
    component: TenantpoolsComponent,
    canActivate: [
      MsalGuard
    ]
  },
  {
    path: 'signup',
    canActivate: [],
    component: OnboardingComponent,
  },
  {
    path: 'organization',
    canActivate: [],
    component: OrganizationComponent,
  },
  {
    path: 'organizations',
    canActivate: [],
    component: OrganizationsComponent,
  },
  {
    path: 'tenants',
    canActivate: [],
    component: TenantsComponent,
  },
  {
    path: 'files',
    canActivate: [],
    component: FileBrowserComponent,
  },
  {
    path: 'join',
    canActivate: [],
    component: InvitationConfirmationComponent,
  },
  {
    path: 'imprint',
    canActivate: [],
    component: ImprintComponent,
  },
  {
    path: 'privacy',
    canActivate: [],
    component: PrivacyComponent,
  },
  {
    path: 'admin',
    canActivate: [],
    component: AdministrationComponent,
  },
  {
    path: 'training',
    canActivate: [],
    component: TrainingComponent,
  },
  {
    path: '**',
    canActivate: [],
    component: StartpageComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
