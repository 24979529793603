import { Component, OnInit } from '@angular/core';
import { TenantpoolService, TenantPool } from './../tenantpool.service';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-tenantpools',
  templateUrl: './tenantpools.component.html',
  styleUrls: ['./tenantpools.component.css']
})
export class TenantpoolsComponent implements OnInit {

  constructor(private tenantPoolService: TenantpoolService) { }

  public tenantPools: TenantPool[] = [];

  selection: SelectionModel<TenantPool>;

  selectedElement: TenantPool = new TenantPool();

  columnsToDisplay = ['select', 'name', 'serviceUnit', 'sourceTenant', 'sourceBackup'];

  isNew: boolean;
  isSelected: boolean;

  ngOnInit(): void {
    const initialSelection = [];
    const allowMultiSelect = false;
    this.isNew = false;
    this.selection = new SelectionModel<TenantPool>(allowMultiSelect, initialSelection);
    this.selection.changed.subscribe(
      {
        next: (v) => {
          if (this.selection.selected.length > 0) {
            this.selectedElement.name = this.selection.selected[0].name;
            this.selectedElement.serviceUnit = this.selection.selected[0].serviceUnit;
            this.selectedElement.targetCount = this.selection.selected[0].targetCount;
            this.selectedElement.poolCustomer = this.selection.selected[0].poolCustomer;
            this.selectedElement.sourceBackup = this.selection.selected[0].sourceBackup;
            this.selectedElement.sourceTenant = this.selection.selected[0].sourceTenant;
            this.isNew = false;
            this.isSelected = true;
          } else {
            this.clearPool(this.selectedElement);
            this.isSelected = false;
          }
        }
      });
    this.tenantPoolService.getTenantPools().toPromise().then(pools => this.tenantPools = pools);
    this.clearPool(this.selectedElement);
    this.selectedElement.name = '';
  }

  clearPool(pool: TenantPool): void {
    pool.name = '';
    pool.poolCustomer = '';
    pool.serviceUnit = '';
    pool.sourceBackup = '';
    pool.sourceTenant = '';
    pool.targetCount = 0;
  }

  buttonNewClicked() {
    this.selection.clear();
    this.clearPool(this.selectedElement);
    this.isNew = true;
  }

  buttonSaveClicked() {
    const pool = this.selectedElement;
    this.selectedElement = new TenantPool();
    this.clearPool(this.selectedElement);
    this.isSelected = false;
    this.isNew = false;

    this.tenantPoolService.createOrUpdate(pool).subscribe(
      {
        next: (v) => {
          this.tenantPoolService.getTenantPools().toPromise().then(pools => this.tenantPools = pools);
        }
      });
  }

  buttonDeleteClicked() {
    const pool = this.selectedElement;
    this.selectedElement = new TenantPool();
    this.clearPool(this.selectedElement);
    this.isSelected = false;
    this.isNew = false;
    this.tenantPoolService.deleteTenantPool(pool.name).subscribe(
      {
        next: (v) => {
          this.tenantPoolService.getTenantPools().toPromise().then(pools => this.tenantPools = pools);
        }
      });
  }

  convertStringToNumber(input: string): number {
    if (input.trim().length === 0) {
        return 0;
    }
    return Number(input);
  }

}
