<div fxLayout="row" fxLayoutAlign="center start" style="height: 100%;padding: 20px;" class="mat-elevation-z8">
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="treeControl">
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
            <button mat-icon-button disabled></button>
            {{node.item}}
        </mat-tree-node>
        <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding matTreeNodePaddingIndent="10" [class.nodeSelected]='selectedNode === node' (click)="selectNode(node)">
            <button mat-icon-button [attr.aria-label]="'Toggle ' + node.item" matTreeNodeToggle>
                <mat-icon class="mat-icon-rtl-mirror">
                    {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
            </button>
            <span style="white-space: nowrap;" >{{node.item}}</span>
            <mat-progress-bar *ngIf="node.isLoading" mode="indeterminate" class="example-tree-progress-bar">
            </mat-progress-bar>
        </mat-tree-node>
    </mat-tree>
    <mat-divider [vertical]="true"></mat-divider>
    <div class="filesComponent">
        <table mat-table [dataSource]="filesDatasource" class="filesTable">
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Name </th>
                <td mat-cell *matCellDef="let file"> {{file.name}} </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let file"> 
                    <button mat-icon-button>
                        <mat-icon class="mat-icon" (click)="saveFile(file)" >save_alt</mat-icon>
                    </button>
                </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
            <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
        </table>
    </div>
</div>