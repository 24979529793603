import { Injectable } from "@angular/core";
import { AccountService } from "./account.service";
import { AppsService } from "./apps.service";
import { OrganizationService } from "./organization.service";
import { environment } from "../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class FeaturesService {

    constructor(
        private org: OrganizationService,
        private accountService: AccountService,
        private appsService: AppsService
    ) {

    }

    public get isStaging(): boolean {
        return environment.staging
    }

    public get ticketingEnabled(): boolean {
        // GA
        return true;
    }

    public get isEInvoiceEnabled(): boolean {
        // GA
        return true;
        /*const orgs = [
            "c30b27fa-5d2f-4a8b-ae2d-31cc4db8d86b"];
        if (orgs.indexOf(this.org.activeOrganization.id) > -1) {
            return true;
        }
        return false;*/
    }

    public get plusTileEnabled(): boolean {
        if (this.isStaging) {
            return true;
        }
        if (this.accountService.account == null) {
            return false;
        }
        if (this.org.activeOrganization == null) {
            return false;
        }
        if (this.accountService.account?.isOperator === true) {
            return true;
        }
        const orgs = [
            'c30b27fa-5d2f-4a8b-ae2d-31cc4db8d86b',
            'de096b7e-b657-4814-b1a5-db1074e8ccf4',
            'a3963dc9-fcfc-462b-8e49-4f1ee95eca4e'
        ];
        if (orgs.indexOf(this.org.activeOrganization.id) > -1) {
            return true;
        }
        return false;
    }

    public isAppAvailableInPlusTile(appId: string) {
        if (this.org.activeOrganization != null && this.accountService.account?.isOperator) {
            return true;
        }
        return this.org.activeOrganization.availableApps.find(a => a === appId) != null;
    }

}