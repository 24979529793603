<h1 mat-dialog-title>Datenbank Wiederherstellen</h1>
<div mat-dialog-content fxLayout="column">
    <section fxLayout="column">
        <div>Verfügbare Sicherungen</div>
        <div fxLayout="row">
            <mat-selection-list #backupList multiple="false" class="mat-elevation-z4" fxFill>
                <mat-list-option *ngFor="let backup of data.backups" fxLayout="row" [value]="backup.backupId">
                  {{ backup.createdOn | date:'medium' }} {{ backup.description }}
                </mat-list-option>
              </mat-selection-list>
        </div>
        <div>&nbsp;</div>
    </section>
</div>
<div mat-dialog-actions>
    <button mat-button mat-dialog-close>Abbrechen</button>
    <button mat-button cdkFocusInitial (click)="onOk(backupList.selectedOptions.selected[0].value)">Datenbank Wiederherstellen</button>
</div>