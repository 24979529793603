<mat-form-field style="width: 100%;">
    <mat-chip-list #chipList aria-label="Apps auswählen">
      <mat-chip
        *ngFor="let e of selectedApps"
        (removed)="remove(e)">
        {{e?.name}}                    
        <mat-icon *ngIf="enabled" matChipRemove>cancel</mat-icon>                    
      </mat-chip>
      <input
        placeholder="Apps"
        #appInput
        [formControl]="appsCtrl"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (matChipInputTokenEnd)="add($event)"
        [disabled]="!enabled">
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
     <mat-option *ngFor="let a of filteredApps" [value]="a">
        {{a?.name}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>