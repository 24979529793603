import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccountService } from '../account.service';
import { OrganizationService } from '../organization.service';

export interface Directory {
    name: string;
    path: string[];
}

export interface File {
    name: string;
    path: string[];
}


@Injectable({
    providedIn: 'root'
})
export class FilesService {

    constructor(private httpClient: HttpClient, private orgService: OrganizationService, private accountService: AccountService) {

    }

    public async getSubDirs(path: string[]): Promise<Directory[]> {
        let combined = path.reduce((p, c, i, a) => p + '/directories/' + c, '') + '/directories';
        const sds = await this.httpClient.get<string[]>(`/api/v1/files/${this.orgService.activeOrganization.id}${combined}`).toPromise().catch(_ => [] as string[]);
        return sds.map(s => { return { name: s, path }; });
    }

    public async getFiles(path: string[]): Promise<File[]> {
        let combined = path.reduce((p, c, i, a) => p + '/directories/' + c, '') + '/files';
        const sds = await this.httpClient.get<string[]>(`/api/v1/files/${this.orgService.activeOrganization.id}${combined}`).toPromise().catch(_ => [] as string[]);
        return sds.map(s => { return { name: s, path }; });
    }

    public async loadFile(path: string[], file: string): Promise<Blob | null> {
        let combined = path.reduce((p, c, i, a) => p + '/directories/' + c, '') + `/files/${file}/download?ts=${Date.now()}`;
        return await this.httpClient.get(`/api/v1/files/${this.orgService.activeOrganization.id}${combined}`, {
            responseType: 'blob'
        }).toPromise().catch(_ => null);

    }
}