<h2 mat-dialog-title style="text-align: center;" i18n="Header|first steps">Erste Schritte</h2>
<mat-dialog-content class="mat-typography">
  <section i18n="Overview|first steps">
    Ihr SAP Business One System steht nun für Sie bereit. Mit folgenden Clients können Sie sich auf Ihr System verbinden:
  </section>
  <br>
  <section>
    <div fxLayout="column" fxLayoutGap="20px">
      <div fxLayout="row wrap" fxLayout.gt-sm="row" fxFill fxLayoutAlign="center start" fxLayoutAlign.gt-sm="start start" fxLayoutGap="10px">
        <div class="tile" fxFlex="0 0 175px">
          <div fxLayout="column" fxFill class="normalTile">
            <div class="tileheader">
              Web Client
            </div>
            <div fxFlex="1 0" fxLayout="column" fxLayoutAlign="center center">
              <mat-icon svgIcon="web-client" class="icon"></mat-icon>
            </div>
            <div fxLayout="row" class="tilefooter">
              <mat-icon fxFlexAlign="start">help_outline</mat-icon>
              <div fxFlex="1 0"></div>
              <div fxFlexAlign="end" fxLayout="row">
                <span i18n="@@TileOpen">Öffnen</span>
                <mat-icon>open_in_new</mat-icon>
              </div>
            </div>
          </div>
        </div>
        <div style="margin-left: 16px;">
          <h3 ngClass.xs="xsmallheader" i18n="Web Client header|first steps">Web Client (empfohlen für Interessenten)</h3>
          <p i18n="Web Client description|first steps">
            Der Web Client läuft in Ihrem Browser. Er ist der ideale Einstiegspunkt für Endanwender ohne Vorkenntnisse.
            Mit ihm lassen sich Artikel und Geschäftspartner anlegen und pflegen, aber auch Belege wie Rechnungen
            buchen. Der Web Client unterstützt nicht alle Prozesse.
          </p>
          <p i18n="Web Client audience|first steps">Zielgruppen: Vertriebsmitarbeiter und Manager</p>
        </div>
      </div>

      <div fxLayout="row wrap" fxLayout.gt-sm="row" fxFill fxLayoutAlign="center start" fxLayoutAlign.gt-sm="start start" fxLayoutGap="10px">
        <div class="tile" fxFlex="0 0 175px">
          <div fxLayout="column" fxFill class="normalTile">
            <div class="tileheader">
              Browser Access
            </div>
            <div fxFlex="1 0" fxLayout="column" fxLayoutAlign="center center">
              <mat-icon svgIcon="browser-access" class="icon"></mat-icon>
            </div>
            <div fxLayout="row" class="tilefooter">
              <mat-icon fxFlexAlign="start">help_outline</mat-icon>
              <div fxFlex="1 0"></div>
              <div fxFlexAlign="end" fxLayout="row">
                <span i18n="@@TileOpen">Öffnen</span>
                <mat-icon>open_in_new</mat-icon>
              </div>
            </div>
          </div>
        </div>
        <div style="margin-left: 16px;">
          <h3 ngClass.xs="xsmallheader">Browser Access</h3>
          <p i18n="Browser Access description|first steps">
            Der Browser Access läuft ebenfalls in Ihrem Browser. Er unterstützt alle Standard-Prozesse. Mit ihm lassen
            sich auch Basiseinstellungen und die Konfiguration des Systems vornehmen.
          </p>
          <p i18n="Browser Access audience|first steps">Zielgruppen: Vertriebsmitarbeiter, Einkäufer, Manager, Buchhalter, Produktions- und Lagermitarbeiter</p>
        </div>
      </div>

      <div fxLayout="row wrap" fxLayout.gt-sm="row" fxFill fxLayoutAlign="center start" fxLayoutAlign.gt-sm="start start" fxLayoutGap="10px">
        <div class="tile" fxFlex="0 0 175px">
          <div fxLayout="column" fxFill class="normalTile">
            <div class="tileheader">
              Sales App
            </div>
            <div fxFlex="1 0" fxLayout="column" fxLayoutAlign="center center">
              <mat-icon svgIcon="mobile" class="icon"></mat-icon>
            </div>
            <div fxLayout="row" class="tilefooter">
              <mat-icon fxFlexAlign="start">help_outline</mat-icon>
              <div fxFlex="1 0"></div>
              <div fxFlexAlign="end" fxLayout="row">
                <span i18n="@@TileConfigure">Konf.</span>
                <mat-icon>settings</mat-icon>
              </div>
            </div>
          </div>
        </div>
        <div style="margin-left: 16px;">
          <h3 ngClass.xs="xsmallheader">Sales App</h3>
          <p i18n="Sales App description|first steps">
            Die SAP Business One Sales App läuft auf Android- und Apple-Geräten. Hier wird Ihnen gezeigt, wie Sie sich
            die App herunterladen und sich danach mit Ihrem SAP Business One System verbinden.
          </p>
          <p i18n="Sales App audience|first steps">Zielgruppe: Vertriebsmitarbeiter (insbesondere Außendienst)</p>
        </div>
      </div>

      <div fxLayout="row wrap" fxLayout.gt-sm="row" fxFill fxLayoutAlign="center start" fxLayoutAlign.gt-sm="start start" fxLayoutGap="10px">
        <div class="tile" fxFlex="0 0 175px">
          <div fxLayout="column" fxFill class="normalTile">
            <div class="tileheader">
              Service App
            </div>
            <div fxFlex="1 0" fxLayout="column" fxLayoutAlign="center center">
              <mat-icon svgIcon="mobile-service" class="icon"></mat-icon>
            </div>
            <div fxLayout="row" class="tilefooter">
              <mat-icon fxFlexAlign="start">help_outline</mat-icon>
              <div fxFlex="1 0"></div>
              <div fxFlexAlign="end" fxLayout="row">
                <span i18n="@@TileConfigure">Konf.</span>
                <mat-icon>settings</mat-icon>
              </div>
            </div>
          </div>
        </div>
        <div style="margin-left: 16px;">
          <h3 ngClass.xs="xsmallheader">Service App</h3>
          <p i18n="Service App description|first steps">
            Die SAP Business One Service App läuft auf Android- und Apple-Geräten. Hier wird Ihnen gezeigt, wie Sie sich
            die App herunterladen und sich danach mit Ihrem SAP Business One System verbinden.
          </p>
          <p i18n="Service App audience|first steps">Zielgruppe: Mitarbeiter im Service (insbesondere Außendienst)</p>
        </div>
      </div>

      <div fxLayout="row wrap" fxLayout.gt-sm="row" fxFill fxLayoutAlign="center start" fxLayoutAlign.gt-sm="start start" fxLayoutGap="10px">
        <div class="tile" fxFlex="0 0 175px">
          <div fxLayout="column" fxFill class="normalTile">
            <div class="tileheader">
              Desktop Client
            </div>
            <div fxFlex="1 0" fxLayout="column" fxLayoutAlign="center center">
              <mat-icon svgIcon="desktop" class="icon"></mat-icon>
            </div>
            <div fxLayout="row" class="tilefooter">
              <mat-icon fxFlexAlign="start">help_outline</mat-icon>
              <div fxFlex="1 0"></div>
              <div fxFlexAlign="end" fxLayout="row">
                <span i18n="@@TileOpen">Öffnen</span>
                <mat-icon>open_in_new</mat-icon>
              </div>
            </div>
          </div>
        </div>
        <div style="margin-left: 16px;">
          <h3 ngClass.xs="xsmallheader">Desktop Client</h3>
          <p i18n="Desktop Client description|first steps">
            Der Desktop Client erlaubt einen Zugriff mit der SAP GUI über eine Remote-Desktop- Verbindung. Dieser Zugriff kann erforderlich sein, wenn Add-Ons genutzt werden, welche die anderen Zugangsarten nicht unterstützen.
          </p>
          <p i18n="Desktop Client audience|first steps">Zielgruppen: Vertriebsmitarbeiter, Einkäufer, Manager, Buchhalter, Produktions- und Lagermitarbeiter</p>
        </div>
      </div>

      <div>
        &nbsp;
      </div>

    </div>

  </section>

</mat-dialog-content>

<mat-dialog-actions align="end">
  <div fxLayout="row wrap" fxLayout.md="row" fxLayoutAlign="end center" fxLayoutGap="20px">
    <mat-checkbox *ngIf="data.showHideOption"  [(ngModel)]="data.hideFirstSteps" i18n="Hide at startup|first steps">Zukünftig nicht mehr anzeigen</mat-checkbox>
    <button color="primary" mat-raised-button [mat-dialog-close]="true" cdkFocusInitial i18n="Close|first steps">Schließen</button>
  </div>
</mat-dialog-actions>