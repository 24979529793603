// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  staging: false,
  test: true,

  clientId: '8b4959a5-7e36-4c22-87ad-1271e31fe35c',
  authority:
    'https://businessoneservice.b2clogin.com/businessoneservice.onmicrosoft.com/B2C_1A_signup_signin',
  authoritySignUp:
    'https://businessoneservice.b2clogin.com/businessoneservice.onmicrosoft.com/B2C_1A_signup',
  authorityPasswordReset:
    'https://businessoneservice.b2clogin.com/businessoneservice.onmicrosoft.com/B2C_1A_PasswordReset',
  redirectUri: '/de/b2credirect.html',
  apiUri: `${window?.location?.protocol}//${window?.location?.hostname}:${window?.location?.port}/api/v1/`,
  startWebClientUri: `${window?.location?.protocol}//${window?.location?.hostname}/start/web/`,
  startBrowserAccessUri: `${window?.location?.protocol}//${window?.location?.hostname}/start/browser/`,
  idpLogoutUri: 'https://www.business-one.cloud/sld/saml2/idp/logout',
  apiBase: 'business-one.io',
  apiScopes: ['https://businessoneservice.onmicrosoft.com/api/All'],
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
