import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { DatabaseService, MobileAccess } from '../database.service';
import { Clipboard } from '@angular/cdk/clipboard';


@Component({
    selector: 'app-config-mobile-dialog',
    templateUrl: 'config-mobile.component.html',
    styleUrls: ['./config-mobile.component.css']
})
export class ConfigMobileDialogComponent {

    mobileAccess: MobileAccess;

    constructor(
        public dialogRef: MatDialogRef<ConfigMobileDialogComponent>,
        private tenantService: DatabaseService,
        private clipboard: Clipboard,
        @Inject(MAT_DIALOG_DATA) public data: any, iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
        const tenant = data.tenant as string;
        tenantService.getMobileAccess(tenant).toPromise().then(m => this.mobileAccess = m);
    }

    copyServer() {
        this.clipboard.copy( this.mobileAccess.server );
    }
}
