import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { Database, DatabaseService } from "./database.service";
import { FilterService } from "./filter.service";
import { Organization, OrganizationService } from "./organization.service";

@Injectable({
    providedIn: 'root'
})
export class ContextService {

    public tenantsReloaded: Subject<void> = new Subject<void>();

    allTenants: Database[];
    tenants: Database[];


    constructor(
        private filterService: FilterService,
        private databaseService: DatabaseService,
        private organizationService: OrganizationService) {
        this.organizationService.organizationChanged.subscribe(org => this.onOrganizationChanged());
        this.filterService.tenantFilterChanged.subscribe(_ => {
            this.tenants = this.allTenants.filter(
                t => this.filterService.showTenant(t));
        });
    }

    public get organization(): Organization {
        return this.organizationService.activeOrganization;
    }

    public async reloadTenants(): Promise<void> {
        await this.onOrganizationChanged();
        this.tenantsReloaded.next();
    }

    private async onOrganizationChanged(): Promise<void> {
        if (this.organization == null) {
            this.allTenants = [];
            this.tenants = [];
        } else {
            try {
                this.allTenants =
                    await this.databaseService.getDatabases().toPromise();
                this.tenants = this.allTenants.filter(t => this.filterService.showTenant(t));
            }
            catch (error) {
                console.error(error);
                this.allTenants = [];
                this.tenants = [];
            }
        }

    }
}