<div fxLayout="row" fxLayoutAlign="center start">
    <div class="control" fxLayout="column" fxLayoutAlign="start center">
        <div style="margin-top: 100px;" fxHide.lt-sm></div>
        <div *ngIf="account == null">
            <h1 style="text-align: center;">Hallo {{ invitation?.memberFirstName }} {{ invitation?.memberLastName }}, willkommen beim BUSINESS ONE
                SERVICE
            </h1>
            <p style="text-align: center;">Sie wurden von {{ invitation?.senderFirstName }} {{ invitation?.senderLastName }} in die Organisation
                {{ invitation?.organizationName }} eingeladen</p>
            <br>
            <app-login></app-login>
        </div>
        <div *ngIf="account != null" fxLayout="column" fxLayoutAlign="start center">
            <h1 style="text-align: center;">Hallo {{ account.displayName }},</h1>
            <p style="text-align: center;">Sie wurden von {{ invitation?.senderFirstName }} {{ invitation?.senderLastName }} in die Organisation
                {{ invitation?.organizationName }} eingeladen</p>
            <p>Wollen Sie die Einladung annehmen ?</p>
            <div style="margin-top: 50px;" fxHide.lt-sm></div>
            <button color="primary" mat-raised-button (click)="accept()">Einladung annehmen</button>
        </div>
    </div>
</div>