<h1 mat-dialog-title style="text-align: center;">Daten in Microsoft Power BI Importieren</h1>
<mat-dialog-content class="mat-typography">
    <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="20px">

        <h2 style="text-align: center;">OData Datenquelle hinzufügen</h2>
        <p> Unter <strong>Daten abrufen</strong>-&gt;<strong>OData-Feed</strong> auswählen.
        </p>
        <div class="powerBIImg1"></div>
        <div fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="start center">
        </div>

        <h2 style="text-align: center;">OData-Feed Adresse</h2>
        <div class="powerBIImg2"></div>
        <p>Unter <strong>URL</strong> die folgende Adresse übernehmen (kopieren):</p>
        <p><strong>{{ mobileAccess.server }}</strong> <button mat-icon-button (click)="copyServer()"><mat-icon>content_copy</mat-icon></button> </p>


        <h2 style="text-align: center;">Zugangsdaten angeben</h2>
        <p>Beim erstmaligen Verbinden werden die Zugangsdaten benötigt.</p>
        <p>Power BI Desktop speichert diese dann auf dem lokalen Computer.</p>
        <div class="powerBIImg3"></div>
        <p>Wählen Sie <strong>Standard</strong> aus.</p>
        <p>Verwenden Sie dann die Zugangs ID als <strong>Benutzername</strong> und den Zugangsschlüssel als <strong>Kennwort</strong>.</p>
        <app-access-key></app-access-key>
        <p>Mit <strong>Verbinden</strong> weiter zur Auswahl der Datenquelle</p>

        <h2 style="text-align: center;">Datenquelle auswählen</h2>
        <div class="powerBIImg4"></div>
        <ul>
            <li><strong>Laden</strong> startet den Datenimport</li>
            <li><strong>Daten transformieren</strong> Abfrage in Power Query bearbeiten z.B. Spalten und Filter auswählen</li>
        </ul>

        <h2 style="text-align: center;">Fertig</h2>
        <div class="powerBIImg5"></div>        
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button color="primary" mat-raised-button [mat-dialog-close]="true" cdkFocusInitial>Schließen</button>
</mat-dialog-actions>