import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { OrganizationService, Organization, OrganizationMember, License, AppAuthorizations } from '../organization.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { MatSelectionListChange } from '@angular/material/list';
import { SelectionModel } from '@angular/cdk/collections';
import { Subscription } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AccountService } from '../account.service';
import { App, AppRole, AppsService } from '../apps.service';

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.css']
})
export class OrganizationComponent implements OnInit, OnDestroy {

  public organizationForm: FormGroup;

  activeView = 'overview';

  columnsToDisplay = ['name', 'role'];

  companySelection: SelectionModel<Organization> = new SelectionModel(true, []);

  organizationOverviewEdit = false;
  organizationAppsEdit = false;

  allApps: App[] = [];
  orgApps: App[] = [];

  private subscription: Subscription;

  public get organization(): Organization {
    return this.organizationService.activeOrganization;
  }

  public get isOperator(): boolean {
    return this.accountService.account?.isOperator === true;
  }

  public get isOwner(): boolean {
    return this.accountService.account.isOwner;
  }

  public get isAdministrator(): boolean {
    return this.accountService.account.isAdministrator;
  }

  public get canViewOverview(): boolean {
    return this.accountService.account.isOperator;
  }

  public get canViewApps(): boolean {
    return this.accountService.account.isOperator;
  }

  public get canViewMembers(): boolean {
    return this.accountService.canMaintainOrganization;
  }


  constructor(
    public organizationService: OrganizationService, private accountService: AccountService, private _fb: FormBuilder,
    public dialog: MatDialog, private appService: AppsService) { }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.organizationForm = this._fb.group({
      name: [this.organization?.displayName, Validators.required],
      street: [this.organization?.street, Validators.required],
      city: [this.organization?.city, Validators.required],
      country: [this.organization?.country, Validators.required],
      countryLetterCode: [this.organization?.countryLetterCode, Validators.required],
      postalCode: [this.organization?.postalCode, Validators.required],
      state: [this.organization?.state, Validators.required],
    });

    this.subscription =
      this.organizationService.organizationChanged.subscribe(_ => {
        this.initOverviewForm();
        if (this.organization) {
          this.orgApps = this.getAppsFromIds(this.organization.availableApps);
        }
      });

    this.appService.getAllApps().then(apps => {
      this.allApps = apps;
      this.orgApps = this.getAppsFromIds(this.organization.availableApps);
    }
    );
    if (this.organization) {
      this.orgApps = this.getAppsFromIds(this.organization.availableApps);
    }
  }

  getAppsFromIds(ids: string[]): App[] {
    return ids.map(id => this.allApps.find(a => a.id === id)).filter(a => a !== undefined);
  }


  licenseFileUpload(files: FileList): void {
    if (files.length === 0) {
      return;
    }
    files[0].text().then(s => this.organizationService.assignLicenseFile(s).toPromise().then(() => { }));
  }

  onNavSelection(e: MatSelectionListChange): void {
    this.activeView = e.option.value;
    switch (e.option.value) {
      case 'overview': break;
      case 'members': break;
    }
  }

  toggleOverviewEdit() {
    this.organizationOverviewEdit = !this.organizationOverviewEdit;
    if (!this.organizationOverviewEdit) {
      this.initOverviewForm();
    }
  }

  toggleAppsEdit() {
    this.organizationAppsEdit = !this.organizationAppsEdit;
  }

  initOverviewForm(): void {
    this.organizationForm.get('name').setValue(this.organization?.displayName);
    this.organizationForm.get('street').setValue(this.organization?.street);
    this.organizationForm.get('city').setValue(this.organization?.city);
    this.organizationForm.get('country').setValue(this.organization?.country);
    this.organizationForm.get('countryLetterCode').setValue(this.organization?.countryLetterCode);
    this.organizationForm.get('postalCode').setValue(this.organization?.postalCode);
    this.organizationForm.get('state').setValue(this.organization?.state);
  }

  saveOverview() {
    const org: Organization = {
      city: this.organizationForm.get('city').value,
      country: this.organizationForm.get('country').value,
      countryLetterCode: this.organizationForm.get('countryLetterCode').value,
      displayName: this.organizationForm.get('name').value,
      id: this.organization?.id,
      postalCode: this.organizationForm.get('postalCode').value,
      state: this.organizationForm.get('state').value,
      street: this.organizationForm.get('street').value,
      availableApps: null,
      licensedProducts: null,
      members: null,
      purpose: null
    };
    this.organizationService.updateOrganizationOverview(org);
    this.organizationOverviewEdit = false;
  }

  onAddUser(): void {
    const userData: any = {
      firstName: '',
      lastName: '',
      role: 'member',
      userCode: '',
      email: '',
      sendInvitation: false,
      allLicenses: this.organizationService.activeOrganization.licensedProducts.filter(l => l.availableQuantity > 0),
      licenses: [],
      canEditRole: this.isOwner || this.isOperator
    }

    const dialogRef = this.dialog.open(AddUserDialogComponent, {
      width: '600px',
      data: userData,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const member = new OrganizationMember();
        member.firstName = userData.firstName;
        member.lastName = userData.lastName;
        member.name = userData.firstName + ' ' + userData.lastName;
        member.role = userData.role;
        member.businessOneUserName = userData.userCode;
        member.licenses = [];

        member.licenses = userData.licenses;

        this.organizationService.addMember(this.organization.id, member, userData.sendInvitation, userData.email, member).
          toPromise().then(() => { });
      }
    });
  }

  canAddMember(): boolean {
    if (this.isOperator || this.isOwner || this.isAdministrator) {
      return true;
    }
    return false;
  }

  canEditMember(member: OrganizationMember): boolean {
    if (this.isOperator || this.isOwner) {
      return true;
    }
    if (this.isAdministrator) {
      return member.role === 'member' || member.id === this.accountService?.account?.member?.id;
    }
    return false;
  }

  canEditRole(member: OrganizationMember): boolean {
    if (member.role === 'owner') {
      return false;
    }
    if (this.isOperator || this.isOwner) {
      return true;
    }
    if (this.isAdministrator) {
      return member.role === 'member';
    }
    return false;
  }

  canDeleteMember(member: OrganizationMember): boolean {
    if (member.role === 'owner') {
      return false;
    }
    if (this.isOperator || this.isOwner) {
      return true;
    }
    if (this.isAdministrator) {
      return member.role === 'member';
    }
    return false;
  }

  canSendInvitation(member: OrganizationMember): boolean {
    if (member.accountId) {
      return false;
    }
    if (this.isOperator || this.isOwner) {
      return true;
    }
    if (this.isAdministrator) {
      return member.role === 'member';
    }
    return false;
  }

  canDisconnect(member: OrganizationMember): boolean {
    if (member.accountId == null) {
      return false;
    }
    if (this.isOperator) {
      return true;
    }
    return false;
  }

  hasAccount(member: OrganizationMember): boolean {
    if (member.accountId) {
      return true;
    }
    return false;
  }

  hasId(member: OrganizationMember): boolean {
    if (member.id) {
      return true;
    }
    return false;
  }



  editMember(member: OrganizationMember): void {

    member = Object.assign(new OrganizationMember(), member);

    const userData: any = {
      firstName: member.firstName,
      lastName: member.lastName,
      role: member.role,
      userCode: member.businessOneUserName,
      licenses: member.licenses ?? [],
      allLicenses: this.organizationService.activeOrganization.licensedProducts.filter(l => l.availableQuantity > 0),
      appsWithRoles: this.orgApps.filter(a => a.applicationRoles?.length ?? 0 > 0),
      appRoles: member.appAuthorizations ?? [],
      canEditRole: this.canEditRole(member),
      rolesEnabled: this.organization.appAuthorizationsEnabled ?? false
    }

    const dialogRef = this.dialog.open(EditUserDialogComponent, {
      width: '600px',
      data: userData,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        member.firstName = userData.firstName;
        member.lastName = userData.lastName;
        member.name = userData.firstName + ' ' + userData.lastName;
        member.role = userData.role;

        member.licenses = userData.licenses;
        member.appAuthorizations = userData.appRoles;

        this.organizationService.updateMember(this.organization.id, member, member).toPromise().then();

      }
    });
  }

  deleteMember(member: OrganizationMember): void {

    this.organizationService.deleteMember(this.organization.id, member.id, member).toPromise().then(() => { });

  }

  onInviteUser(member: OrganizationMember): void {
    const userData: any = {
    }

    const dialogRef = this.dialog.open(InviteUserDialogComponent, {
      width: '600px',
      data: userData,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.organizationService.inviteMember(this.organization.id, member, userData.email);
      }
    });
  }

  onDisconnect(member: OrganizationMember): void {
    if (window.confirm("Verknüpfung aufheben ?")) {
        this.organizationService.disconnectMember(this.organization.id, member);
        member.accountId = null;
    }
  }

  getLicenseText(licenses: License[]): string {
    return licenses.reduce((p, c, i) => `${p}${i > 0 ? ',' : ''}{p}`, '');
  }

  getBusinessOneLicense(licenses: License[]): License {
    if (licenses === null) {
      return null;
    }
    for (const l of licenses) {
      if (l.licensedProductName === 'SAP Business One') {
        return l;
      }
    }
    return null;
  }

  saveApps(): void {
    this.organization.availableApps = this.orgApps.map(a => a.id);
    this.organizationService.updateOrganizationApps(this.organization);
    this.organizationAppsEdit = false;
  }

}




@Component({
  selector: 'app-dialog-add-user',
  templateUrl: 'dialog-add-user.html',
  styleUrls: ['./organization.component.css']
})
export class AddUserDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<AddUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  isValid() {
    return this.data.firstName !== '' && this.data.firstName !== '' && this.data.userCode !== '' &&
      (!this.data.sendInvitation || this.data.email !== '');
  }

}

@Component({
  selector: 'app-dialog-edit-user',
  templateUrl: 'dialog-edit-user.html',
  styleUrls: ['./organization.component.css']
})
export class EditUserDialogComponent implements OnInit {

  public applicationRoles: Map<string, AppAuthorizations> = new Map<string, AppAuthorizations>();

  constructor(
    public dialogRef: MatDialogRef<EditUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {

    for (const app of this.data.appsWithRoles as App[]) {
      const roles = (this.data.appRoles as AppAuthorizations[]).find(a => a.applicationId === app.id);
      if (roles) {
        this.applicationRoles.set(app.id, roles);
      } else {
        const appRoles = {
          applicationId: app.id,
          applicationRoles: []
        };
        this.applicationRoles.set(app.id, appRoles);
        this.data.appRoles.push(appRoles);
      }
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  isValid() {
    return this.data.firstName !== '' && this.data.firstName !== '' && this.data.userCode !== '';
  }

  getRoles(app: App) {
    return this.applicationRoles.get(app.id) ?? {
      applicationId: app.id,
      applicationRoles: []
    };
  }

}

@Component({
  selector: 'app-dialog-invite',
  templateUrl: 'dialog-invite.html',
  styleUrls: ['./organization.component.css']
})
export class InviteUserDialogComponent {


  constructor(
    public dialogRef: MatDialogRef<InviteUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  isValid() {
    return this.data.email !== '';
  }

}