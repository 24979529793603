import { Component, OnInit, Inject, ViewChild, ElementRef, Input } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DatabaseService, Database, Extension } from '../database.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { FormControl } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { App, AppRole } from '../apps.service';
import { AppAuthorizations, License } from '../organization.service';


@Component({
    selector: 'app-approles-select',
    templateUrl: './approles-selection.component.html',
    styleUrls: ['./approles-selection.component.css']
})
export class AppRolesSelectionComponent implements OnInit {

    rolesCtrl = new FormControl();
    separatorKeysCodes: number[] = [ENTER, COMMA];

    selectedRoles: AppAuthorizations;
    availableRoles: AppRole[];

    @Input()
    enabled: true;

    get allRoles() {
        return this.availableRoles;
    }

    @Input()
    set allRoles(roles: AppRole[]) {
        this.availableRoles = roles;
        this.refreshFilter();
    }

    get selection() {
        return this.selectedRoles;
    }

    @Input()
    set selection(roles: AppAuthorizations) {
        this.selectedRoles = roles;
        this.refreshFilter();
    }


    filteredRoles: AppRole[] = [];

    @ViewChild('rolesInput') appInput: ElementRef<HTMLInputElement>;

    ngOnInit(): void {
        this.refreshFilter();
    }

    constructor() {
        this.availableRoles = [];
        this.selectedRoles = {
            applicationId: '',
            applicationRoles: []
        };

        this.rolesCtrl.valueChanges.subscribe(() => this.refreshFilter());
    }

    remove(role: string) {
        const index = this.selectedRoles.applicationRoles.findIndex(ar => ar === role);

        if (index >= 0) {
            this.selectedRoles.applicationRoles.splice(index, 1);
            this.refreshFilter();
        }
    }

    addRole(role: AppRole) {
        const index = this.selectedRoles.applicationRoles.findIndex(e => e === role.id);
        if (index !== -1) {
            return;
        }
        this.selectedRoles.applicationRoles.push(role.id);
        this.refreshFilter();
    }

    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        const inputValue = (value || '').trim();

        if (inputValue) {

            const match = this.availableRoles.filter(e => e.id === inputValue);
            if (match.length === 1) {

                this.addRole(match[0]);

                if (input) {
                    input.value = '';
                }

                this.rolesCtrl.setValue(null);
            }
        }
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        this.addRole(event.option.value);
        this.rolesCtrl.setValue(null);
        this.appInput.nativeElement.blur();
        this.appInput.nativeElement.value = '';
    }

    refreshFilter() {
        this.filteredRoles = this._filter(this.rolesCtrl.value);
    }

    getRole(roleId: string) {
        return this.availableRoles.find( r => r.id === roleId);
    }

    private _filter(value: any): any[] {
        let filterValue = '';
        if (typeof value === 'string') {
            filterValue = value;
        } else {
            filterValue = value?.displayName ?? '';
        }
        filterValue = filterValue.toLocaleLowerCase();

        return this.availableRoles.filter(e => e.displayName.toLowerCase().indexOf(filterValue) === 0
            && this.selectedRoles.applicationRoles.findIndex(ar => ar === e.id) === -1);
    }

}