import { Component, OnInit, Inject, ViewChild, ElementRef, Input } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DatabaseService, Database, Extension } from '../database.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { FormControl } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { App } from '../apps.service';
import { License } from '../organization.service';

@Component({
    selector: 'app-license-select',
    templateUrl: './license-selection.component.html',
    styleUrls: ['./license-selection.component.css']
})
export class LicenseSelectionComponent implements OnInit {

    licensesCtrl = new FormControl();
    separatorKeysCodes: number[] = [ENTER, COMMA];

    selectedLicenses: License[];
    availableLicenses: License[];

    @Input()
    enabled: true;

    get allLicenses() {
        return this.availableLicenses;
    }

    @Input()
    set allLicenses(licenses: License[]) {
        this.availableLicenses = licenses;
        this.refreshFilter();
    }

    get selection() {
        return this.selectedLicenses;
    }

    @Input()
    set selection(licenses: License[]) {
        this.selectedLicenses = licenses;
        this.refreshFilter();
    }


    filteredLicenses: License[] = [];

    @ViewChild('licenseInput') appInput: ElementRef<HTMLInputElement>;

    ngOnInit(): void {
        this.refreshFilter();
    }

    constructor() {
        this.availableLicenses = [];
        this.selectedLicenses = [];

        this.licensesCtrl.valueChanges.subscribe(() => this.refreshFilter());
    }

    remove(license: License) {
        const index = this.selectedLicenses.indexOf(license);

        if (index >= 0) {
            this.selectedLicenses.splice(index, 1);
            this.refreshFilter();
        }
    }

    addLicense(license: License) {
        const index = this.selectedLicenses.findIndex(e => e.licensedProductName === license.licensedProductName
            && e.licensedProductSKU === license.licensedProductSKU);
        if (index !== -1) {
            return;
        }
        this.selectedLicenses.push(license);
        this.refreshFilter();
    }

    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        const inputValue = (value || '').trim();

        if (inputValue) {

            const match = this.availableLicenses.filter(e => e.licensedProductSKU === inputValue);
            if (match.length === 1) {

                this.addLicense(match[0]);

                if (input) {
                    input.value = '';
                }

                this.licensesCtrl.setValue(null);
            }
        }
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        this.addLicense(event.option.value);
        this.licensesCtrl.setValue(null);
        this.appInput.nativeElement.blur();
        this.appInput.nativeElement.value = '';
    }

    refreshFilter() {
        this.filteredLicenses = this._filter(this.licensesCtrl.value);
    }

    private _filter(value: any): any[] {
        let filterValue = '';
        if (typeof value === 'string') {
            filterValue = value;
        } else {
            filterValue = value?.name || '';
        }
        filterValue = filterValue.toLocaleLowerCase();

        return this.availableLicenses.filter(e => e.licensedProductSKU.toLowerCase().indexOf(filterValue) === 0
            && this.selectedLicenses.indexOf(e) === -1);
    }

}