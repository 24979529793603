<table mat-table [dataSource]="tenantPools" class="mat-elevation-z8">
    
    <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
        </th>
        <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)">
            </mat-checkbox>
        </td>
    </ng-container>
    <mat-text-column name="name"></mat-text-column>
    <mat-text-column name="serviceUnit"></mat-text-column>
    <mat-text-column name="poolCustomer"></mat-text-column>
    <mat-text-column name="sourceTenant"></mat-text-column>
    <mat-text-column name="sourceBackup"></mat-text-column>
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>

</table>

<button mat-raised-button color="primary" (click)="buttonNewClicked()" style="margin-top: 10px;margin-bottom: 10px;">New</button>

<mat-divider style="padding: 30px;"></mat-divider>


<mat-card>
    <mat-card-title>Details</mat-card-title>
    <mat-card-content>
        <form class="details-form">
            <mat-grid-list cols="2" rowHeight="100px">
                <mat-grid-tile>
                    <mat-form-field>
                        <mat-label>Name</mat-label>
                        <input matInput placeholder="Pool..." [value]="selectedElement.name" (input)="selectedElement.name=$event.target.value" [readonly]="!isNew">
                    </mat-form-field>
                </mat-grid-tile>            
                <mat-grid-tile>
                    <mat-form-field>
                        <mat-label>Service Unit</mat-label>
                        <input matInput placeholder="SU00.." [value]="selectedElement.serviceUnit" (input)="selectedElement.serviceUnit=$event.target.value" [readonly]="!isNew">
                    </mat-form-field>
                </mat-grid-tile>            
                <mat-grid-tile>
                    <mat-form-field>
                        <mat-label>Customer</mat-label>
                        <input matInput placeholder="Customer..." [value]="selectedElement.poolCustomer" (input)="selectedElement.poolCustomer=$event.target.value" [readonly]="!isNew">
                    </mat-form-field>
                </mat-grid-tile>            
                <mat-grid-tile>
                    <mat-form-field>
                        <mat-label>Target count</mat-label>
                        <input matInput placeholder="1" type="number" [value]="selectedElement.targetCount" (input)="selectedElement.targetCount=convertStringToNumber($event.target.value)" [readonly]="!isNew && !isSelected">
                    </mat-form-field>
                </mat-grid-tile>            
                <mat-grid-tile>
                    <mat-form-field>
                        <mat-label>Source Tenant</mat-label>
                        <input matInput placeholder="SBO0000..." [value]="selectedElement.sourceTenant" (input)="selectedElement.sourceTenant=$event.target.value" [readonly]="!isNew && !isSelected">
                    </mat-form-field>
                </mat-grid-tile>            
                <mat-grid-tile>
                    <mat-form-field>
                        <mat-label>Source Backup</mat-label>
                        <input matInput placeholder="202001011..." [value]="selectedElement.sourceBackup" (input)="selectedElement.sourceBackup=$event.target.value" [readonly]="!isNew && !isSelected">
                    </mat-form-field>
                </mat-grid-tile>            

                <mat-grid-tile columSpan="2">
                    <button mat-raised-button color="primary" (click)="buttonSaveClicked()" [disabled]="!isNew && !isSelected">Save</button>
                    <button mat-raised-button color="primary" (click)="buttonDeleteClicked()" [disabled]="isNew ||!isSelected">Delete</button>
                </mat-grid-tile>
        </mat-grid-list>
    </form>
    </mat-card-content>
</mat-card>
