import {
  Component,
  OnInit,
  Input,
  ViewChild,
  TemplateRef,
  OnDestroy,
  Inject, LOCALE_ID
} from '@angular/core';
import {
  Database,
  DatabaseService,
  AppRegistration,
} from '../database.service';
import { environment } from '../../environments/environment';
import {
  OrganizationMember,
  OrganizationService,
} from '../organization.service';
import { Data } from '@angular/router';
import { Tile } from '../tiles/tiles.component';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { ConfigMobileDialogComponent } from './config-mobile.component';
import { ConfigMobileServiceDialogComponent } from './config-mobile-service.component';
import { ConfigCobiWmsDialogComponent } from './config-cobiwms.component';
import { AccountService } from '../account.service';
import { AppsService, App } from '../apps.service';
import { ConfigExcelDialogComponent } from './config-excel.component';
import { ConfigPowerBIDialogComponent } from './config-powerbi.component';
import { ConfigCrystalDialogComponent } from './config-crystal.component';
import { ManageAppsComponent } from '../apps/manage-apps.component';
import { Subject, Subscription } from 'rxjs';
import { FeaturesService } from '../features.service';
import { A } from '@angular/cdk/keycodes';

class TenantTile extends Tile {
  tenant: Database;
  action: (t: Database) => void;
  tenantService: DatabaseService;
}

class InstallTile extends TenantTile {
  app: App;
  tooltip: string;
  svgIcon: string;
  iconClass: string;
  header: string;
}

class AppTile extends TenantTile {
  app: App;
  appReg: AppRegistration;
  tooltip: string;
  svgIcon: string;
  iconClass: string;
  header: string;
}

@Component({
  selector: 'app-tenant-tiles',
  templateUrl: './tenant-tiles.component.html',
  styleUrls: ['./tenant-tiles.component.css'],
})
export class TenantTilesComponent implements OnInit, OnDestroy {
  @ViewChild('startBrowserAccess', { static: true })
  private startBrowserAccessTpl: TemplateRef<any>;

  @ViewChild('startWebClient', { static: true })
  private startWebClientTpl: TemplateRef<any>;

  @ViewChild('mobileClient', { static: true })
  private mobileClientTpl: TemplateRef<any>;

  @ViewChild('mobilServiceClient', { static: true })
  private mobilServiceClientTpl: TemplateRef<any>;

  @ViewChild('wms', { static: true })
  private wmsTpl: TemplateRef<any>;

  @ViewChild('startRDP', { static: true })
  private startRDPTpl: TemplateRef<any>;

  @ViewChild('installApp', { static: true })
  private installAppTpl: TemplateRef<any>;

  @ViewChild('manageApps', { static: true })
  private manageAppsTpl: TemplateRef<any>;

  @ViewChild('appRegistration', { static: true })
  private appRegistrationTpl: TemplateRef<any>;

  @ViewChild('rdpApplication', { static: true })
  private rdpApplicationTpl: TemplateRef<any>;

  @ViewChild('appExternal', { static: true })
  private externalApplicationTpl: TemplateRef<any>;

  get applicationRolesEnabled(): boolean {
    return this.org.activeOrganization?.appAuthorizationsEnabled ?? false;
  }

  @Input()
  tenant: Database;

  tiles: TenantTile[] = [];

  private subscriptions: Subscription = new Subscription();

  constructor(
    private tenantService: DatabaseService,
    private org: OrganizationService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    public dialog: MatDialog,
    private accountService: AccountService,
    private appsService: AppsService,
    private features: FeaturesService,
    @Inject(LOCALE_ID) public locale: string,
  ) {
    iconRegistry.addSvgIcon(
      'browser-access',
      sanitizer.bypassSecurityTrustResourceUrl('assets/Browser Access.svg')
    );
    iconRegistry.addSvgIcon(
      'web-client',
      sanitizer.bypassSecurityTrustResourceUrl('assets/Web Client.svg')
    );
    iconRegistry.addSvgIcon(
      'desktop',
      sanitizer.bypassSecurityTrustResourceUrl('assets/Desktop.svg')
    );
    iconRegistry.addSvgIcon(
      'mobile',
      sanitizer.bypassSecurityTrustResourceUrl('assets/Mobile Apps.svg')
    );
    iconRegistry.addSvgIcon(
      'mobileService',
      sanitizer.bypassSecurityTrustResourceUrl('assets/ServiceApp.svg')
    );
    iconRegistry.addSvgIcon(
      'wms',
      sanitizer.bypassSecurityTrustResourceUrl('assets/COBIwms.svg')
    );
    iconRegistry.addSvgIcon(
      'shop',
      sanitizer.bypassSecurityTrustResourceUrl('assets/webshop.svg')
    );
    iconRegistry.addSvgIcon(
      'shop-plus',
      sanitizer.bypassSecurityTrustResourceUrl('assets/webshop_plus.svg')
    );
    iconRegistry.addSvgIcon(
      'excel',
      sanitizer.bypassSecurityTrustResourceUrl('assets/apps/excel.svg')
    );
    iconRegistry.addSvgIcon(
      'powerbi',
      sanitizer.bypassSecurityTrustResourceUrl('assets/apps/powerbi.svg')
    );
    iconRegistry.addSvgIcon(
      'crystal',
      sanitizer.bypassSecurityTrustResourceUrl('assets/Crystal Reports.svg')
    );
    iconRegistry.addSvgIcon(
      'whm-browser',
      sanitizer.bypassSecurityTrustResourceUrl('assets/whm/whm_browser.svg')
    );
    iconRegistry.addSvgIcon(
      'time-recording',
      sanitizer.bypassSecurityTrustResourceUrl('assets/Time recording.svg')
    );
    iconRegistry.addSvgIcon(
      'init-web-client',
      sanitizer.bypassSecurityTrustResourceUrl('assets/apps/b1web.svg')
    );
    iconRegistry.addSvgIcon(
      'docrec',
      sanitizer.bypassSecurityTrustResourceUrl('assets/apps/docrec.svg')
    );
    iconRegistry.addSvgIcon(
      'mailer',
      sanitizer.bypassSecurityTrustResourceUrl('assets/apps/mailer.svg')
    );
    iconRegistry.addSvgIcon(
      'hanaworkbench',
      sanitizer.bypassSecurityTrustResourceUrl('assets/apps/hanaworkbench.svg')
    );
    iconRegistry.addSvgIcon(
      'ticketing',
      sanitizer.bypassSecurityTrustResourceUrl('assets/apps/ticketing.svg')
    );
    iconRegistry.addSvgIcon(
      'manageApps',
      sanitizer.bypassSecurityTrustResourceUrl('assets/apps/manageapps.svg')
    );
    iconRegistry.addSvgIcon(
      'shopify',
      sanitizer.bypassSecurityTrustResourceUrl('assets/apps/shopify.svg')
    );
    iconRegistry.addSvgIcon(
      'shopware',
      sanitizer.bypassSecurityTrustResourceUrl('assets/apps/shopware.svg')
    );
    iconRegistry.addSvgIcon(
      'bigcommerce',
      sanitizer.bypassSecurityTrustResourceUrl('assets/apps/bigcommerce.svg')
    );
    iconRegistry.addSvgIcon(
      'woocommerce',
      sanitizer.bypassSecurityTrustResourceUrl('assets/apps/woocommerce.svg')
    );
    iconRegistry.addSvgIcon(
      'datevimport',
      sanitizer.bypassSecurityTrustResourceUrl('assets/apps/datev-import.svg')
    );
    iconRegistry.addSvgIcon(
      'oxid',
      sanitizer.bypassSecurityTrustResourceUrl('assets/apps/oxid.svg')
    );
    iconRegistry.addSvgIcon(
      'amazon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/apps/amazon.svg'),
      {
        viewBox: "0 0 603 182"
      }
    );
    iconRegistry.addSvgIcon(
      'documentarchive',
      sanitizer.bypassSecurityTrustResourceUrl('assets/apps/documentarchive.svg')
    );
    iconRegistry.addSvgIcon(
      'fximport',
      sanitizer.bypassSecurityTrustResourceUrl('assets/apps/fximport.svg')
    );
    iconRegistry.addSvgIcon(
      'integrationmonitor',
      sanitizer.bypassSecurityTrustResourceUrl('assets/apps/integrationmonitor.svg')
    );
    iconRegistry.addSvgIcon(
      'hubspot',
      sanitizer.bypassSecurityTrustResourceUrl('assets/apps/hubspot.svg')
    );
    iconRegistry.addSvgIcon(
      'crm',
      sanitizer.bypassSecurityTrustResourceUrl('assets/apps/C4B1.svg')
    );
    iconRegistry.addSvgIcon(
      'einvoice',
      sanitizer.bypassSecurityTrustResourceUrl('assets/apps/eInvoice.svg')
    );
    iconRegistry.addSvgIcon(
      'statisticdoc',
      sanitizer.bypassSecurityTrustResourceUrl('assets/apps/statisticdoc.svg')
    );
    iconRegistry.addSvgIcon(
      'ups',
      sanitizer.bypassSecurityTrustResourceUrl('assets/apps/ups.svg')
    );
    iconRegistry.addSvgIcon(
      'qa',
      sanitizer.bypassSecurityTrustResourceUrl('assets/apps/qa.svg')
    );
    iconRegistry.addSvgIcon(
      'despatchorder',
      sanitizer.bypassSecurityTrustResourceUrl('assets/apps/despatchorder.svg')
    );
    iconRegistry.addSvgIcon(
      'documentbrowser',
      sanitizer.bypassSecurityTrustResourceUrl('assets/apps/documentbrowser.svg')
    );
    iconRegistry.addSvgIcon(
      'documentimport',
      sanitizer.bypassSecurityTrustResourceUrl('assets/apps/documentimport.svg')
    );
    iconRegistry.addSvgIcon(
      'edi',
      sanitizer.bypassSecurityTrustResourceUrl('assets/apps/edi.svg')
    );
    iconRegistry.addSvgIcon(
      'fulfillmentterminal',
      sanitizer.bypassSecurityTrustResourceUrl('assets/apps/fulfillmentterminal.svg')
    );
    iconRegistry.addSvgIcon(
      'labels',
      sanitizer.bypassSecurityTrustResourceUrl('assets/apps/labels.svg')
    );
    iconRegistry.addSvgIcon(
      'manufacturing',
      sanitizer.bypassSecurityTrustResourceUrl('assets/apps/manufacturing.svg')
    );
    iconRegistry.addSvgIcon(
      'metalsurcharge',
      sanitizer.bypassSecurityTrustResourceUrl('assets/apps/metalsurcharge.svg')
    );
    iconRegistry.addSvgIcon(
      'whm',
      sanitizer.bypassSecurityTrustResourceUrl('assets/apps/whm.svg')
    );
    iconRegistry.addSvgIcon(
      'appApproval',
      sanitizer.bypassSecurityTrustResourceUrl('assets/apps/approval.svg')
    );
    iconRegistry.addSvgIcon(
      'appService',
      sanitizer.bypassSecurityTrustResourceUrl('assets/apps/service.svg')
    );
    iconRegistry.addSvgIcon(
      'appDeviceDiagnostics',
      sanitizer.bypassSecurityTrustResourceUrl('assets/apps/deviceDiagnostics.svg')
    );
    iconRegistry.addSvgIcon(
      'inboxApp',
      sanitizer.bypassSecurityTrustResourceUrl('assets/apps/Inbox.svg')
    );
    iconRegistry.addSvgIcon(
      'timeApp',
      sanitizer.bypassSecurityTrustResourceUrl('assets/apps/time.svg')
    );

    this.subscriptions.add(
      appsService.appsLoaded.subscribe((apps) => this.initTiles())
    );

  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    if (this.appsService.apps.length > 0) {
      this.initTiles();
    }
  }

  initTiles(): void {

    console.log(`initializing tiles`);

    this.tiles = [];

    if (this.hasApplicationRoleAssigned('business-one.cloud-webclient')) {
      const t2 = new TenantTile();
      t2.tenant = this.tenant;
      t2.action = this.startWebClientUrl;
      t2.tileTemplate = this.startWebClientTpl;
      this.tiles.push(t2);
    }

    if (this.hasApplicationRoleAssigned('business-one.cloud-browser-access')) {
      const t1 = new TenantTile();
      t1.tenant = this.tenant;
      t1.action = this.startBrowserAccessUrl;
      t1.tileTemplate = this.startBrowserAccessTpl;
      this.tiles.push(t1);
    }

    if (this.hasApplicationRoleAssigned('business-one.cloud-sales-app')) {
      const mobile = new TenantTile();
      mobile.tenant = this.tenant;
      mobile.tileTemplate = this.mobileClientTpl;
      this.tiles.push(mobile);
    }

    if (this.hasApplicationRoleAssigned('business-one.cloud-service-app')) {
      const service = new TenantTile();
      service.tenant = this.tenant;
      service.tileTemplate = this.mobilServiceClientTpl;
      this.tiles.push(service);
    }

    if (this.features.isEInvoiceEnabled) {
      const app = this.appsService.getAppFromId('business-one.io-einvoice');
      if (app != null) {
        const appTile = new AppTile();
        appTile.tenant = this.tenant;
        appTile.tileTemplate = this.appRegistrationTpl;
        appTile.app = app;
        appTile.header = app.name;
        appTile.svgIcon = "einvoice";
        appTile.iconClass = '';
        appTile.tooltip = app.description;
        appTile.appReg = {
          applicationId: app.id,
          useTestVersion: false,
          name: app.name,
          tenantId: this.tenant.id,
          organizationId: this.org.activeOrganization.id                    
        } as AppRegistration;
        this.tiles.push(appTile);
      }
    }

    const t3 = new TenantTile();
    t3.tenant = this.tenant;
    t3.tileTemplate = this.startRDPTpl;
    const s = this.tenantService;
    if (this.showRdp()) {
      this.tiles.push(t3);
    }

    for (const config of this.appsService.getConfigureOnlyApps()) {
      if (!this.hasApplicationRoleAssigned(config.id)) {
        continue;
      }

      const appTile = new AppTile();
      appTile.tenant = this.tenant;
      appTile.tileTemplate = this.externalApplicationTpl;
      appTile.app = config;
      appTile.header = config.name;
      switch (config.id) {
        case 'business-one.io-excel':
          appTile.svgIcon = 'excel';
          break;
        case 'business-one.io-powerbi':
          appTile.svgIcon = 'powerbi';
          break;
      }
      appTile.tooltip = config.description;
      this.tiles.push(appTile);
    }

    for (const app of this.appsService.apps.filter(
      (a) => a.applicationType === 'RdpApp'
    )) {
      if (
        this.accountService.account?.isOperator ||
        this.isLicensed(this.accountService.account.member, app)
      ) {
        const appTile = new AppTile();
        appTile.tenant = this.tenant;
        appTile.tileTemplate = this.rdpApplicationTpl;
        appTile.app = app;
        appTile.header = app.name;
        if (app.id === 'business-one.cloud-crystal-reports') {
          appTile.svgIcon = 'crystal';
        } else {
          appTile.svgIcon = 'desktop';
        }
        appTile.tooltip = app.description;
        this.tiles.push(appTile);
      }
    }

    const wms = new TenantTile();
    wms.tenant = this.tenant;
    wms.tileTemplate = this.wmsTpl;
    if (this.showCobiWmsApp()) {
      this.tiles.push(wms);
    }

    if (this.tenant.appRegistrations) {
      for (const reg of this.tenant.appRegistrations) {
        const app = this.appsService.getAppFromId(reg.applicationId);
        if (!app || !this.hasApplicationRoleAssigned(app.id)) {
          continue;
        }
        let icon = 'browser-access';
        let iconClass = '';
        if (app.id === 'business-one.io-shippingterminal') {
          icon = 'whm-browser';
        }
        if (app.id === 'business-one.io-fulfillment') {
          icon = 'fulfillmentterminal';
        }
        if (app.id === 'business-one.io-timesheet-manager') {
          icon = 'time-recording';
        }
        if (app.id === 'business-one.io-document-recognition') {
          icon = 'docrec';
        }
        if (app.id === 'business-one.io-document-archive') {
          icon = 'documentarchive';
        }
        if (app.id === 'business-one.io-datev-import') {
          icon = 'datevimport';
        }
        if (app.id === 'business-one.io-mailer') {
          icon = 'mailer';
        }
        if (app.id === 'business-one.io-fx-import') {
          icon = 'fximport';
        }
        if (app.id === 'business-one.io-hana-workbench') {
          icon = 'hanaworkbench';
        }
        if (app.id === 'business-one.io-integration-monitor') {
          icon = 'integrationmonitor';
        }
        if (app.id === 'business-one.io-ticketing') {
          icon = 'ticketing';
        }
        if (app.id === 'business-one.io-nero') {
          icon = undefined;
          iconClass = 'appNeroTile';
        }
        if (app.id === 'business-one.io-statistical-documents') {
          icon = 'statisticdoc';
        }
        if (app.id === 'business-one.io-edi') {
          icon = 'edi';
        }
        if (app.id === 'business-one.io-qa-app') {
          icon = 'qa';
        }
        if (app.id === 'business-one.io-manufacturing') {
          icon = 'manufacturing';
        }
        if (app.id === 'business-one.io-goods-receipt') {
          icon = 'whm';
        }
        if (app.id === 'business-one.io-labels') {
          icon = 'labels';
        }
        if (app.id === 'business-one.io-metal-surcharge') {
          icon = 'metalsurcharge';
        }
        if (app.id === 'business-one.io-despatchorder') {
          icon = 'despatchorder';
        }
        if (app.id === 'business-one.io-approval') {
          icon = 'appApproval';
        }
        if (app.id === 'business-one.io-service') {
          icon = 'appService';
        }
        if (app.id === 'business-one.io-device-diagnostics') {
          icon = 'appDeviceDiagnostics';
        }
        if (app.id === 'business-one.io-einvoice') {
          icon = 'einvoice';
        }
        if (app.id === 'business-one.io-inbox') {
          icon = 'inboxApp';
        }
        if (app.id === 'business-one.io-time') {
          icon = 'timeApp';
        }

        if (app.id === 'business-one.io-marketplace') {
          icon = undefined;
          iconClass = 'shop';
          switch (reg.variant) {
            case "0": icon = "woocommerce"; break;
            case "1": icon = "shopify"; break;
            case "2": icon = "shopware"; break;
            case "3": icon = "bigcommerce"; break;
            case "4": icon = "oxid"; break;
            case "5": icon = "amazon"; break;
          }
        }
        if (app.id === 'business-one.io-crm') {
          switch (reg.variant) {
            case "hubspot": icon = "hubspot"; break;
            case "crm": icon = "crm"; break;
          }
        }
        if (app.id === 'business-one.io-shipping') {
          switch (reg.variant) {
            case "UPS": icon = "ups"; break;
          }
        }
        if (app.id === 'business-one.cloud-init-webclient') {
          icon = 'init-web-client';
        }
        const appTile = new AppTile();
        appTile.tenant = this.tenant;
        appTile.tileTemplate = this.appRegistrationTpl;
        appTile.app = app;
        appTile.header = (reg.name != null && reg.name != "") ? reg.name : app.name;
        appTile.svgIcon = icon;
        appTile.iconClass = iconClass;
        appTile.tooltip = app.description;
        appTile.appReg = reg;
        this.tiles.push(appTile);
      }
    }

    for (const install of this.appsService.getInstallableApps()) {
      if (!this.hasApplicationRoleAssigned(install.id)) {
        continue;
      }

      let icon = 'shop';
      let iconClass = '';
      if (install.id === 'business-one.io-shippingterminal') {
        icon = 'whm-browser';
      }
      if (install.id === 'business-one.io-nero') {
        icon = undefined;
        iconClass = 'appNeroTile';
      }
      const installTile = new InstallTile();
      installTile.tenant = this.tenant;
      installTile.tileTemplate = this.installAppTpl;
      installTile.app = install;
      installTile.header = install.name;
      installTile.svgIcon = icon;
      installTile.iconClass = iconClass;
      installTile.tooltip = install.description;
      //this.tiles.push(installTile);
    }

    if (this.hasApplicationRoleAssigned("business-one.io-ticketing")) {
      const appTile = new AppTile();
      appTile.tenant = this.tenant;
      appTile.tileTemplate = this.appRegistrationTpl;
      appTile.app = this.appsService.getAppFromId("business-one.io-ticketing");
      appTile.header = "Support";
      appTile.svgIcon = "ticketing";
      appTile.iconClass = '';
      appTile.tooltip = appTile.app?.description;
      appTile.appReg = {
        applicationId: appTile.app?.id,
        useTestVersion: false,

      } as AppRegistration;
      this.tiles.push(appTile);
    }

    if (this.features.plusTileEnabled) {
      const manageApps = new InstallTile();
      manageApps.tenant = this.tenant;
      manageApps.tileTemplate = this.manageAppsTpl;
      manageApps.header = 'Apps';
      manageApps.svgIcon = 'manageApps';
      manageApps.tooltip = 'Add or remove Apps';
      this.tiles.push(manageApps);
    }
  }

  hasApplicationRoleAssigned(appId: string): boolean {
    if (appId === 'business-one.io-ticketing' && !this.features.ticketingEnabled) {
      return false;
    }
    if (
      !this.applicationRolesEnabled ||
      this.accountService.account?.isOperator
    ) {
      return true;
    }
    const app = this.appsService.apps.find((a) => a.id === appId);
    if (!app) {
      return false;
    }
    if ((app.applicationRoles ?? []).length === 0) {
      return true;
    }
    const assignedRoles = (
      this.accountService.account?.member?.appAuthorizations ?? []
    ).find((r) => r.applicationId === appId);
    if ((assignedRoles?.applicationRoles ?? []).length === 0) {
      return false;
    }
    return true;
  }

  isLicensed(member: OrganizationMember, app: App): boolean {
    if (!app.licenses || !member.licenses) {
      return false;
    }
    for (const l of app.licenses) {
      if (
        member.licenses.find(
          (x) =>
            x.licensedProductName === l.licensedProductName &&
            x.licensedProductSKU === l.licensedProductSKU
        )
      ) {
        return true;
      }
    }
    return false;
  }

  startBrowserAccessUrl(t: Database) {
    window.open(
      environment.startBrowserAccessUri +
      t.id +
      `?ts=${Date.now()}&account=${this.accountService.account?.id}`,
      '_blank',
      'toolbar=0,location=0,menubar=0'
    );
  }

  startWebClientUrl(t: Database) {
    window.open(
      environment.startWebClientUri +
      t.id +
      `?ts=${Date.now()}&account=${this.accountService.account?.id}`,
      '_blank',
      'toolbar=1,location=0,menubar=0'
    );
  }

  startRdpApp(t: Database, app: App) {
    this.tenantService
      .createTenantRDP(t.id, this.org.activeOrganization.id, app.id)
      .toPromise()
      .then((rdp) => {
        const blob = new Blob([rdp], { type: 'application/rdp' });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        document.body.appendChild(a);
        a.href = url;
        a.download = `${app.name}.rdp`;
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      });
  }

  displayHelp(t: Database, app: App): void {
    if (app.id === 'business-one.cloud-crystal-reports') {
      const dialogRef = this.dialog.open(ConfigCrystalDialogComponent, {
        width: '80%',
        data: {
          serviceUnit: t.sercviceUnit,
          tenant: t.id,
        },
      });
    }
  }

  configMobileSalesApp(t: Database) {
    const dialogRef = this.dialog.open(ConfigMobileDialogComponent, {
      width: '80%',
      data: {
        tenant: t.id,
      },
    });
  }

  configMobileServiceApp(t: Database) {
    const dialogRef = this.dialog.open(ConfigMobileServiceDialogComponent, {
      width: '80%',
      data: {
        tenant: t.id,
      },
    });
  }

  configCobiWmsApp(t: Database) {
    const dialogRef = this.dialog.open(ConfigCobiWmsDialogComponent, {
      width: '80%',
      data: {
        tenant: t.id,
      },
    });
  }

  configureApp(tenant: Database, app: App): void {
    if (app.id === 'business-one.io-excel') {
      const dialogRef = this.dialog.open(ConfigExcelDialogComponent, {
        width: '80%',
        data: {
          tenant: tenant.id,
        },
      });
    }
    if (app.id === 'business-one.io-powerbi') {
      const dialogRef = this.dialog.open(ConfigPowerBIDialogComponent, {
        width: '80%',
        data: {
          tenant: tenant.id,
        },
      });
    }
  }

  showRdp(): boolean {
    if (this.accountService.account?.isOperator) {
      return false;
    }
    return (
      this.accountService.account?.isOperator ||
      this.org.activeOrganization?.purpose === null ||
      this.org.activeOrganization?.purpose === 'Trial'
    );
  }

  showCobiWmsApp(): boolean {
    return (
      (this.accountService.account?.isOperator ||
        this.org.activeOrganization?.purpose === null ||
        this.org.activeOrganization?.purpose === 'Trial' ||
        this.org.activeOrganization.availableApps.findIndex(
          (a) => a === 'COBI.wms'
        ) !== -1) &&
      this.hasApplicationRoleAssigned('COBI.wms')
    );
  }

  install(tenant: Database, app: App) {
    const url = app.tenantInstallationUrl
      .replace('${organizationId}', this.org.activeOrganization.id)
      .replace('${tenantId}', tenant.id);
    window.open(url, '_blank', 'toolbar=0,location=0,menubar=0');
  }

  openApp(tenant: Database, app: App, reg: AppRegistration) {

    const url = (((reg.useTestVersion ?? false) ? app.accessUrlTest : app.accessUrl) ?? app.accessUrl)
      .replace('${organizationId}', this.org.activeOrganization.id)
      .replace('${tenantId}', tenant.id)
      .replace('${registrationId}', reg.id)
      .replace("${login_hint}", this.accountService.account.username)
      .replace("${domain_hint}", this.accountService.account.domain ?? "")
      .replace("${lang}", this.locale);
    window.open(url, '_blank', 'toolbar=0,location=0,menubar=0');
  }

  showManageApps(tenant: Database) {
    const dialogRef = this.dialog.open(ManageAppsComponent, {
      width: '80%',
      disableClose: true,
      data: {
        organitaion: this.org.activeOrganization?.id ?? '',
        tenant: tenant,
      },
    });
  }
}
