import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AccountService } from '../account.service';
import { App, AppsService } from '../apps.service';
import { AppRegistration, Database, DatabaseService } from '../database.service';
import { OrganizationService } from '../organization.service';
import { IApp } from './manage-apps.component';
import { FeaturesService } from '../features.service';

const markeplace = "business-one.io-marketplace";
const crm = "business-one.io-crm";
const shipping = "business-one.io-shipping";

@Component({
  selector: 'app-tenant-app',
  templateUrl: './tenant-app.component.html',
  styleUrls: ['./tenant-app.component.css']
})
export class TenantAppComponent implements OnInit {

  @Input()
  app: IApp = {
    id: "",
    svgIcon: ""
  };

  @Input()
  tenant: Database | null = null;

  get svgIcon(): string {
    return this.app.svgIcon;
  }

  @Input()
  description: string | null = null;

  @Output()
  install: EventEmitter<IApp> = new EventEmitter<IApp>();

  @Output()
  repair: EventEmitter<AppRegistration> = new EventEmitter<AppRegistration>();

  @Output()
  uninstall: EventEmitter<AppRegistration> = new EventEmitter<AppRegistration>();

  @Output()
  enableTestVersion: EventEmitter<AppRegistration> = new EventEmitter<AppRegistration>();

  @Output()
  disableTestVersion: EventEmitter<AppRegistration> = new EventEmitter<AppRegistration>();

  @Output()
  enableDiagnostics: EventEmitter<AppRegistration> = new EventEmitter<AppRegistration>();

  @Output()
  disableDiagnostics: EventEmitter<AppRegistration> = new EventEmitter<AppRegistration>();

  public get canInstall(): boolean {
    return this.canInstallApp(this.app);
  }

  public get canRepair(): boolean {
    return this.canRepairApp(this.app);
  }

  public get canUninstall(): boolean {
    return this.canUninstallApp(this.app);
  }

  public get appStatus(): string {
    return this.getAppStatus(this.app);
  }

  public get appName(): string {
    const a = this.appsService.apps.find(a => a.id === this.app.id);
    if (a == null) {
      return "-";
    }
    if (this.app.variant != null) {
      return a.variants?.[this.app.variant]?.name ?? a.name ?? "-";
    } else {
      return a.name ?? "-";
    }
  }

  public get appDescription(): string {
    if (this.description != null) {
      return this.description
    }
    const a = this.appsService.apps.find(a => a.id === this.app.id);
    if (a == null) {
      return "-";
    }
    if (this.app.variant != null) {
      return a.variants?.[this.app.variant]?.description ?? a.description ?? "-";
    } else {
      return a.description ?? "-";
    }
  }


  private get appReg(): AppRegistration | undefined {
    return this.tenant.appRegistrations.find((a) => a.applicationId === this.app.id && (this.app.variant === undefined || this.app.variant === a.variant));
  }

  public get useTestVersion(): boolean {
    return this.appReg?.useTestVersion ?? false;
  }

  public get diagnosticsEnabled(): boolean {
    return this.appReg?.diagnostics?.enabled ?? false;
  }

  public get isOperator(): boolean {
    return this.accountService.account?.isOperator ?? false;
  }

  public installText = $localize`Install`;
  public repairText = $localize`Repair`;
  public uninstallText = $localize`Remove`;

  constructor(
    private accountService: AccountService,
    private organizationService: OrganizationService,
    private appsService: AppsService,
    private tenantService: DatabaseService,
    private features: FeaturesService
  ) { }

  ngOnInit(): void {
  }

  public get visible(): boolean {
    return this.features.isAppAvailableInPlusTile(this.app.id);
  }

  public isAppAvailable(app: IApp): boolean {
    const a = this.organizationService.activeOrganization?.availableApps.find((a) => a === app.id);
    return a != null;
  }

  public isAppInstalled(app: IApp): boolean {
    const a = this.tenant.appRegistrations.find((a) => a.applicationId === app.id && (app.variant === undefined || app.variant === a.variant));
    return a != null;
  }

  public canInstallApp(app: IApp): boolean {
    if (this.isAppInstalled(app)) {
      return false;
    }
    if (!this.isAppAvailable(app)) {
      return false;
    }
    if (app.id === markeplace) {
      return false;
    }
    return true;
  }

  public canRepairApp(app: IApp): boolean {
    if (!this.isAppInstalled(app)) {
      return false;
    }
    if (!this.isAppAvailable(app)) {
      return false;
    }
    return (app.id === shipping);
  }


  public canUninstallApp(app: IApp): boolean {
    if (!this.isAppInstalled(app)) {
      return false;
    }
    if (!this.isAppAvailable(app)) {
      return false;
    }
    if (app.id === markeplace) {
      return false;
    }
    return true;
  }

  public getAppStatus(app: IApp): string {
    if (!this.isAppAvailable(app)) {
      return "On demand";
    }
    if (this.isAppInstalled(app)) {
      return "Installed";
    }
    return "Not installed";
  }

  public onInstall(): void {
    this.install.emit(this.app);
  }

  public onRepair(): void {
    this.repair.emit(this.appReg);
  }

  public onUninstall(): void {
    if (this.appReg == null) {
      return;
    }
    this.uninstall.emit(this.appReg);
  }

  public useTestVersionChange(v: boolean) {
    if (this.appReg == null) {
      return;
    }
    if (v) {
      this.enableTestVersion.emit(this.appReg);
    } else {
      this.disableTestVersion.emit(this.appReg);
    }
  }

  public diagnosticsChange(v: boolean) {
    if (this.appReg == null) {
      return;
    }
    if (v) {
      this.enableDiagnostics.emit(this.appReg);
    } else {
      this.disableDiagnostics.emit(this.appReg);
    }
  }

}
