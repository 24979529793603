import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export class TenantPool {
  public name: string;
  public serviceUnit: string;
  public targetCount: number;
  public poolCustomer: string;
  public sourceTenant: string;
  public sourceBackup: string;

}

@Injectable({
  providedIn: 'root'
})
export class TenantpoolService {

  constructor(private httpClient: HttpClient) { }

  public getTenantPools(): Observable<TenantPool[]> {
    return this.httpClient.get<TenantPool[]>('/api/v1/tenantpools');
  }

  public createOrUpdate(pool: TenantPool): Observable<TenantPool> {
    return this.httpClient.post<TenantPool>('/api/v1/tenantpools', pool);
  }

  public deleteTenantPool(name: string): Observable<{}> {
    return this.httpClient.delete('/api/v1/tenantpools/' + name);
  }

}
