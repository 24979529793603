<h1 mat-dialog-title style="text-align: center;">Arbeiten mit Crystal Reports</h1>
<mat-dialog-content class="mat-typography">
    <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="20px">

        <h2 style="text-align: center;">Starten von Crystal Reports</h2>
        <p>Duch einen Klick auf die Crystal Reports Kachel wird eine RDP Datei erzeugt und heruntergeladen
            (Download-Leiste)</p>
        <div class="crystalImg1"></div>
        <p>Bitte öffnen Sie die Datei, um die Verbindung zu Crystal Reports herzustellen (Anklicken).</p>
        <p>Die Datei ist nur für eine kurze Zeit gültig und muss deshalb durch Betätigung der Kachel für jede Verbindung
            neu erzeugt werden.</p>
        <mat-divider style="width: 100%;"></mat-divider>
        <h3 style="text-align: center;">Anmeldung am System</h3>
        <div class="crystalLogin"></div>
        <p>Verwenden Sie den <strong>Zugangsschlüssel</strong> als Kennwort.</p>
        <app-access-key></app-access-key>
        <mat-divider style="width: 100%;"></mat-divider>
        <h3>Die folgende Meldung kann ignoriert werden, weiter mit <strong>Connect</strong></h3>
        <div class="crystalImg2"></div>
        <mat-divider style="width: 100%;"></mat-divider>
        <div class="crystalStart"></div>
        <h2>Verbindung zur Datenbank</h2>
        <p>Verbindung unter <strong>ODBC (RDO)</strong> auswählen oder neu anlegen (Doppelklick)</p>
        <div class="crystalDB1"></div>
        <p>Neue Verbindung: <strong>Enter Connection String</strong> wählen und den folgenden Wert übernehmen:</p>
        <div fxLayout="row">
            <input matInput readonly="true" size="20" [value]="connectionString" style="height: 20px;"
                fxFlexAlign="center" />
            <button mat-icon-button (click)="copyConnectionString()">
                <mat-icon>content_copy</mat-icon>
            </button>
        </div>
        <div class="crystalDB2"></div>
        <p>Weiter mit <strong>Next</strong>.</p>
        <p>Benutzername in <strong>User ID</strong> eintragen, Passwort in <strong>Password</strong>.</p>
        <app-credentials [tenant]="data.tenant" [credentialsType]="'CrystalReports'"></app-credentials>
        <div class="crystalDB3"></div>
        <p>Mit <strong>Finish</strong> die Verbindung hinzufügen.</p>
        <div class="crystalDB4"></div>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button color="primary" mat-raised-button [mat-dialog-close]="true" cdkFocusInitial>Schließen</button>
</mat-dialog-actions>