import { Injectable, EventEmitter, Inject, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import {
  OperationsService,
  Operation,
  OperationStatus,
} from './operations.service';
import { isPlatformBrowser } from '@angular/common';
import { off } from 'process';

export interface Organization {
  id: string;
  displayName: string | null;
  city: string | null;
  country: string | null;
  countryLetterCode: string | null;
  postalCode: string | null;
  state: string | null;
  street: string | null;
  members: OrganizationMember[] | null;
  licensedProducts: LicensedProduct[] | null;
  purpose: string | null;
  availableApps: string[] | null;
  appAuthorizationsEnabled?: boolean;
}

export class OrganizationInfo {
  public id: string;
  public name: string;
  public role: string;
}

export enum MemberState {
  Active = 'active',
  NoAccount = 'noaccount',
  Invitation = 'invitation',
  Inactive = 'inactive',
}

export class ADUser {
  public name: string;
  public domain: string;
}

export interface AppAuthorizations {
  applicationId: string;
  applicationRoles: string[];
}

export class OrganizationMember {
  public accountId: string;
  public name: string;
  public firstName: string;
  public lastName: string;
  public role: string;
  public id: string;
  public businessOneUserName: string;
  public adUser: ADUser;
  public hideFirstSteps: boolean;
  public licenses: License[];
  public appAuthorizations?: AppAuthorizations[];

  public getBusinessOneLicenses(): License[] {
    if (this.licenses === null) {
      this.licenses = [];
    }
    return this.licenses.filter(
      (l) => l.licensedProductName === 'SAP Business One'
    );
  }

  public setBusinessOneLicenses(skus: string[]): void {
    let l = this.getBusinessOneLicenses();
    const skuEmpty = skus.length === 0;
    if (skuEmpty) {
      this.licenses = this.licenses.filter(
        (x) => x.licensedProductName !== 'SAP Business One'
      );
    } else {
      const b1Licenses = skus.map((s) => {
        const license = new License();
        license.licensedProductName = 'SAP Business One';
        license.licensedProductSKU = s;
        return license;
      });
      this.licenses = this.licenses.filter(
        (x) => x.licensedProductName !== 'SAP Business One'
      );
      this.licenses.push(...b1Licenses);
    }
  }
}

export class Invitation {
  memberId: string;
  memberFirstName: string;
  memberLastName: string;
  memberName: string;
  organizationId: string;
  organizationName: string;
  senderId: string;
  senderFirstName: string;
  senderLastName: string;
  senderName: string;
}

export class License {
  public licensedProductName: string;
  public licensedProductSKU: string;
}

export class LicensedProduct {
  public licensedProductName: string;
  public licensedProductSKU: string;
  public licensedQuantity: number;
  public availableQuantity: number;
}

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  public activeOrganization: Organization;

  public organizationChangedInit = new Subject<Organization>();
  public organizationChanged = new EventEmitter<Organization>();

  private loadingDefaultOrganization: boolean;

  constructor(
    private httpClient: HttpClient,
    private operations: OperationsService,
    @Inject(PLATFORM_ID) private platformId
  ) {
    this.activeOrganization = null;
    if (isPlatformBrowser(this.platformId)) {
      //this.selectDefaultCompany();
    }
  }

  reload(): void {
    if (this.activeOrganization) {
      this.httpClient
        .get<Organization>(
          `/api/v1/organizations/${this.activeOrganization.id}`
        )
        .toPromise()
        .then((org) => {
          if (org.licensedProducts === null) {
            org.licensedProducts = [];
          }
          if (org.members === null) {
            org.members = [];
          }
          this.activeOrganization = org;
          this.organizationChangedInit.next(org);
          this.organizationChanged.emit(org);
        });
    }
  }

  switchActiveOrganization(id: string): void {
    const service = this;
    this.httpClient
      .get<Organization>('/api/v1/organizations/' + id)
      .toPromise()
      .then((org) => {
        service.activeOrganization = org;
        if (org.licensedProducts === null) {
          org.licensedProducts = [];
        }
        if (org.members === null) {
          org.members = [];
        }
        this.organizationChangedInit.next(org);
        service.organizationChanged.emit(org);
      });
  }

  getAvailableOrganizations(): Observable<OrganizationInfo[]> {
    return this.httpClient.get<OrganizationInfo[]>(
      '/api/v1/organizations/available'
    );
  }

  async selectDefaultCompany(): Promise<void> {
    const service = this;
    this.loadingDefaultOrganization = true;
    try {
      const org = await this.httpClient
        .get<Organization>('/api/v1/organizations/default')
        .toPromise();
      service.activeOrganization = org;
      if (org.licensedProducts === null) {
        org.licensedProducts = [];
      }
      if (org.members === null) {
        org.members = [];
      }
      this.organizationChangedInit.next(org);
      service.organizationChanged.emit(org);
    } catch (error) {
      service.activeOrganization = null;
      this.organizationChangedInit.next(null);
      service.organizationChanged.emit(null);
    } finally {
      this.loadingDefaultOrganization = false;
    }
  }

  updateOrganizationOverview(organization: Organization): void {
    const service = this;
    this.httpClient
      .patch<Organization>(
        '/api/v1/organizations/' + organization.id,
        organization
      )
      .toPromise()
      .then((org) => {
        service.activeOrganization = org;
        this.organizationChangedInit.next(org);
        service.organizationChanged.emit(org);
      });
  }

  updateOrganizationApps(organization: Organization): void {
    const service = this;
    this.httpClient
      .patch<Organization>('/api/v1/organizations/' + organization.id, {
        id: organization.id,
        availableApps: organization.availableApps,
      })
      .toPromise()
      .then((org) => {
        service.activeOrganization = org;
        this.organizationChangedInit.next(org);
        service.organizationChanged.emit(org);
      });
  }

  async createOrganization(
    organization: Organization,
    operationState: any
  ): Promise<Operation> {
    const status = await this.httpClient
      .post<OperationStatus>(`/api/v1/organizations`, organization)
      .toPromise();

    const operation = this.operations.register(
      status,
      operationState,
      `Organisation ${organization.displayName} anlegen`
    );
    return operation;
  }

  addMember(
    orgId: string,
    member: OrganizationMember,
    invite: boolean,
    inviteEMail: string,
    operationState: any
  ): Observable<Operation> {
    this.activeOrganization.members.push(member);
    return this.httpClient
      .post<OperationStatus>(
        '/api/v1/organizations/' + orgId + '/members',
        member
      )
      .pipe(
        map((s) =>
          this.operations.register(
            s,
            operationState,
            `Mitglied ${member.name} anlegen`
          )
        ),
        tap((op) => {
          op.completed.subscribe((o) => {
            this.reload();
          });
        })
      );
  }

  inviteMember(
    orgId: string,
    member: OrganizationMember,
    inviteEMail: string
  ): void {
    this.httpClient
      .post(`/api/v1/organizations/${orgId}/members/${member.id}/invite`, {
        email: inviteEMail,
      })
      .toPromise()
      .then((x) => { });
  }

  disconnectMember(orgId: string, member: OrganizationMember): void {
    this.httpClient
      .post(
        `/api/v1/organizations/${orgId}/members/${member.id}/disconnect`,
        null
      )
      .toPromise()
      .then((x) => { });
  }

  updateMember(
    orgId: string,
    member: OrganizationMember,
    operationState: any
  ): Observable<Operation> {
    // tslint:disable-next-line:max-line-length
    return this.httpClient
      .put<OperationStatus>(
        '/api/v1/organizations/' + orgId + '/members/' + member.id,
        member
      )
      .pipe(
        map((s) =>
          this.operations.register(
            s,
            operationState,
            `Mitglied ${member.name} ändern`
          )
        ),
        tap((op) => {
          op.completed.subscribe((o) => {
            this.reload();
          });
        })
      );
  }

  deleteMember(
    orgId: string,
    memberId: string,
    operationState: any
  ): Observable<Operation> {
    return this.httpClient
      .delete<OperationStatus>(
        '/api/v1/organizations/' + orgId + '/members/' + memberId
      )
      .pipe(
        map((s) =>
          this.operations.register(
            s,
            operationState,
            `Mitglied ${operationState.name} löschen`
          )
        ),
        tap((op) => {
          op.completed.subscribe((o) => {
            this.reload();
          });
        })
      );
  }

  hideFirstSteps(orgId: string): void {
    this.httpClient
      .post(
        '/api/v1/organizations/' + orgId + '/members/me/hideFirstSteps',
        null
      )
      .toPromise()
      .then((x) => { });
  }

  acceptInvitation(t: string): Observable<any> {
    return this.httpClient.post('/api/v1/invitation/confirm', {
      token: t,
    });
  }

  resolveInvitation(t: string): Observable<Invitation> {
    return this.httpClient.post<Invitation>('/api/v1/invitation/resolve', {
      token: t,
    });
  }

  loadAccessKey(): Observable<string> {
    if (this.activeOrganization == null) {
      return of("");
    }
    return this.httpClient
      .post<any>(
        '/api/v1/organizations/' +
        this.activeOrganization.id +
        '/members/me/accessKey',
        null
      )
      .pipe(map((x) => x.accessKey));
  }

  resetAccessKey(): Observable<any> {
    if (this.activeOrganization == null) {
      return of("");
    }
    return this.httpClient.post(
      '/api/v1/organizations/' +
      this.activeOrganization.id +
      '/members/me/resetAccessKey',
      null
    );
  }

  assignLicenseFile(licenseFileContent: string): Observable<any> {
    if (this.activeOrganization == null) {
      return of("");
    }
    return this.httpClient.post(
      '/api/v1/organizations/' + this.activeOrganization.id + '/licenseFile',
      {
        licenseFileContent,
      }
    );
  }
}

@Pipe({ name: 'userRoleText' })
export class UserRoleTextPipe implements PipeTransform {
  transform(value: string, args?: any): string {
    switch (value) {
      case 'owner':
        return 'Besitzer';
      case 'member':
        return 'Mitglied';
      case 'administrator':
        return 'Administrator';
      default:
        return value;
    }
  }
}

@Pipe({ name: 'businessOneLicenseText' })
export class BusinessOneLicenseTextPipe implements PipeTransform {
  transform(sku: string, args?: any): string {
    switch (sku) {
      case 'PROFESSIONAL':
        return 'SAP Business One Professional User';
      case 'MOBILE-SERVICE':
        return 'Mobile Service User';
      case 'FINANCIALS-LTD':
        return 'SAP Business One Limited Financials User';
      case 'MOBILE-SALES':
        return 'Mobile Sales User';
      case 'CRM-LTD':
        return 'SAP Business One Limited CRM User';
      case 'LOGISTICS-LTD':
        return 'SAP Business One Limited Logistics User';
      case 'B1STARTER':
        return 'B1 Starter Pack';
      case 'INDIRECT':
        return 'SAP Business One Indirect Access User';
      default:
        return sku;
    }
  }
}

@Pipe({ name: 'licenseText' })
export class LicenseTextPipe implements PipeTransform {
  transform(license: License, args?: any): string {
    if (!license) {
      return '';
    }
    if (license.licensedProductName === 'SAP Business One') {
      switch (license.licensedProductSKU) {
        case 'PROFESSIONAL':
          return 'SAP Business One Professional User';
        case 'MOBILE-SERVICE':
          return 'Mobile Service User';
        case 'FINANCIALS-LTD':
          return 'SAP Business One Limited Financials User';
        case 'MOBILE-SALES':
          return 'Mobile Sales User';
        case 'CRM-LTD':
          return 'SAP Business One Limited CRM User';
        case 'LOGISTICS-LTD':
          return 'SAP Business One Limited Logistics User';
        case 'B1STARTER':
          return 'B1 Starter Pack';
        case 'INDIRECT':
          return 'SAP Business One Indirect Access User';
        default:
          return license.licensedProductSKU;
      }
    }
    if (license.licensedProductName === 'Crystal Reports') {
      switch (license.licensedProductSKU) {
        case 'CRYSTAL_RDP':
          return 'Crystal Reports RDP';
      }
    }
    return license.licensedProductSKU;
  }
}
