import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { OrganizationService, Organization } from './organization.service';
import { DatabaseBackupDialogComponent } from './tenants/tenants.component';
import {
  OperationsService,
  OperationStatus,
  Operation,
} from './operations.service';

export enum DatabaseState {
  Creating = 'CREATING',
  Stopped = 'STOPPED',
  Stopping = 'STOPPING',
  Started = 'STARTED',
  Starting = 'STARTING',
}

export interface Database {
  id: string;
  name: string;
  description: string;
  databaseState: DatabaseState;
  purpose: string;
  extensions: Extension[];
  organizationId: string;
  appRegistrations: AppRegistration[];
  sercviceUnit: string;
}

export interface AppDiagnostics {
  enabled: true;
}

export interface AppRegistration {
  organizationId: string;
  tenantId: string;
  id: string;
  applicationId: string;
  name: string;
  variant: string;
  useTestVersion: boolean | null;
  diagnostics: AppDiagnostics | null;
}

export class Extension {
  name: string;
  namespace: string;
}

export class DatabaseBackup {
  backupId: string;
  createdOn: Date;
  description: string;
}

export class MobileAccess {
  server: string;
  port: number;
}

export interface TenantCredentials {
  type: string;
  username: string;
  password: string;
}

export interface BackupSource {
  organization: string;
  tenant: string;
  backupId: string;
}

@Injectable({
  providedIn: 'root',
})
export class DatabaseService {

  public tenantChanged: Subject<string> = new Subject<string>();

  constructor(
    private httpClient: HttpClient,
    private orgService: OrganizationService,
    private operations: OperationsService
  ) { }

  getDatabases(): Observable<Database[]> {
    if (this.orgService.activeOrganization == null) {
      return of([]);
    }
    return this.httpClient.get<Database[]>(
      '/api/v1/organizations/' +
      this.orgService.activeOrganization.id +
      '/databases'
    );
  }

  public getDatabasesOfOrg(org: string): Promise<Database[]> {
    return this.httpClient
      .get<Database[]>('/api/v1/organizations/' + org + '/databases')
      .toPromise();
  }

  async getDatabase(id: string, organization: string): Promise<Database> {
    return await this.httpClient
      .get<Database>(`/api/v1/organizations/${organization}/databases/${id}`)
      .toPromise();
  }

  async updateTenant(
    tenant: Database,
    operationState: any
  ): Promise<Operation> {
    const status = await this.httpClient
      .put<OperationStatus>(
        `/api/v1/organizations/${tenant.organizationId}/databases/${tenant.id}`,
        tenant
      )
      .toPromise();

    const operation = this.operations.register(
      status,
      operationState,
      `Mandant ${tenant.name} ändern`
    );
    return operation;
  }

  getDatabaseBackups(id: string): Observable<DatabaseBackup[]> {
    if (this.orgService.activeOrganization == null) {
      return of([]);
    }
    return this.httpClient.get<DatabaseBackup[]>(
      '/api/v1/organizations/' +
      this.orgService.activeOrganization.id +
      '/databases/' +
      id +
      '/backups'
    );
  }

  getDatabaseBackupsByOrgAndTenant(
    org: string,
    tenant: string
  ): Promise<DatabaseBackup[]> {
    return this.httpClient
      .get<DatabaseBackup[]>(
        '/api/v1/organizations/' + org + '/databases/' + tenant + '/backups'
      )
      .toPromise();
  }

  startDatabase(id: string): Observable<any> {
    if (this.orgService.activeOrganization == null) {
      return of({});
    }
    return this.httpClient.post(
      '/api/v1/organizations/' +
      this.orgService.activeOrganization.id +
      '/databases/' +
      id +
      '/start',
      null
    );
  }

  stopDatabase(id: string): Observable<any> {
    if (this.orgService.activeOrganization == null) {
      return of({});
    }
    return this.httpClient.post(
      '/api/v1/organizations/' +
      this.orgService.activeOrganization.id +
      '/databases/' +
      id +
      '/stop',
      null
    );
  }

  backupDatabase(id: string, reason: string): Observable<any> {
    if (this.orgService.activeOrganization == null) {
      return of({});
    }
    return this.httpClient.post(
      '/api/v1/organizations/' +
      this.orgService.activeOrganization.id +
      '/databases/' +
      id +
      '/backup',
      { reason }
    );
  }

  restoreDatabase(id: string, backupId: string): Observable<any> {
    if (this.orgService.activeOrganization == null) {
      return of({});
    }
    return this.httpClient.post(
      '/api/v1/organizations/' +
      this.orgService.activeOrganization.id +
      '/databases/' +
      id +
      '/restore',
      { backupId }
    );
  }

  deleteDatabase(id: string): Observable<any> {
    if (this.orgService.activeOrganization == null) {
      return of({});
    }
    return this.httpClient.post(
      '/api/v1/organizations/' +
      this.orgService.activeOrganization.id +
      '/databases/' +
      id +
      '/delete',
      null
    );
  }

  createDatabase(
    name: string,
    description: string,
    online: boolean,
    purpose: 'Test' | 'Production',
    sourceType: 'template' | 'backup',
    source: BackupSource | null
  ): Observable<any> {
    if (this.orgService.activeOrganization == null) {
      return of({});
    }
    return this.httpClient.post(
      '/api/v1/organizations/' +
      this.orgService.activeOrganization.id +
      '/databases',
      {
        name,
        description,
        online,
        sourceType,
        source,
        purpose,
      }
    );
  }

  async copyDatabaseFrom(
    organization: string,
    tenant: string,
    source: BackupSource | null
  ): Promise<any> {
    if (this.orgService.activeOrganization == null) {
      return of({});
    }
    return this.httpClient.post(
      '/api/v1/organizations/' +
      this.orgService.activeOrganization.id +
      '/databases/' +
      tenant +
      '/copyFrom',
      {
        source,
      }
    ).toPromise();
  }

  createTenantRDP(
    id: string,
    organization: string,
    appId: string
  ): Observable<string> {
    return this.httpClient.post<string>(
      '/api/v1/RDP/',
      {
        Tenant: id,
        Organization: organization,
        ApplicationId: appId,
      },
      { responseType: 'text' as 'json' }
    );
  }

  getMobileAccess(id: string): Observable<MobileAccess> {
    return this.httpClient.get<MobileAccess>(
      '/api/v1/organizations/' +
      this.orgService.activeOrganization.id +
      '/databases/' +
      id +
      '/mobile'
    );
  }

  public async getCredentials(
    tenantId: string,
    type: string
  ): Promise<TenantCredentials> {
    if (this.orgService.activeOrganization == null) {
      return {
        password: "",
        type: "",
        username: ""
      };
    }

    return this.httpClient
      .post<TenantCredentials>(
        `/api/v1/organizations/${this.orgService.activeOrganization.id}/databases/${tenantId}/credentials/${type}`,
        null
      )
      .toPromise();
  }

  public async resetCredentials(
    tenantId: string,
    type: string
  ): Promise<TenantCredentials> {
    if (this.orgService.activeOrganization == null) {
      return {
        password: "",
        type: "",
        username: ""
      };
    }
    return this.httpClient
      .post<TenantCredentials>(
        `/api/v1/organizations/${this.orgService.activeOrganization.id}/databases/${tenantId}/credentials/${type}/reset`,
        null
      )
      .toPromise();
  }

  public async installApp(appReg: AppRegistration): Promise<void> {
    if (this.orgService.activeOrganization == null) {
      return;
    }
    await this.httpClient
      .post<TenantCredentials>(
        `/api/v1/organizations/${this.orgService.activeOrganization.id}/databases/${appReg.tenantId}/apps`,
        appReg
      )
      .toPromise();
  }

  public async uninstallApp(appReg: AppRegistration): Promise<void> {
    if (this.orgService.activeOrganization == null) {
      return;
    }
    await this.httpClient
      .delete<TenantCredentials>(
        `/api/v1/organizations/${this.orgService.activeOrganization.id}/databases/${appReg.tenantId}/apps/${appReg.id}`,
      )
      .toPromise();
  }


  public async enableTestVersion(appReg: AppRegistration): Promise<void> {
    if (this.orgService.activeOrganization == null) {
      return;
    }
    await this.httpClient
      .post<TenantCredentials>(
        `/api/v1/organizations/${this.orgService.activeOrganization.id}/databases/${appReg.tenantId}/apps/${appReg.id}/enableTestVersion`,
        null
      )
      .toPromise();
  }

  public async disableTestVersion(appReg: AppRegistration): Promise<void> {
    if (this.orgService.activeOrganization == null) {
      return;
    }
    await this.httpClient
      .post<TenantCredentials>(
        `/api/v1/organizations/${this.orgService.activeOrganization.id}/databases/${appReg.tenantId}/apps/${appReg.id}/disableTestVersion`,
        null
      )
      .toPromise();
  }

  public async enableDiagnostics(appReg: AppRegistration): Promise<void> {
    if (this.orgService.activeOrganization == null) {
      return;
    }
    await this.httpClient
      .post<TenantCredentials>(
        `/api/v1/organizations/${this.orgService.activeOrganization.id}/databases/${appReg.tenantId}/apps/${appReg.id}/enableDiagnostics`,
        null
      )
      .toPromise();
  }

  public async disableDiagnostics(appReg: AppRegistration): Promise<void> {
    if (this.orgService.activeOrganization == null) {
      return;
    }
    await this.httpClient
      .post<TenantCredentials>(
        `/api/v1/organizations/${this.orgService.activeOrganization.id}/databases/${appReg.tenantId}/apps/${appReg.id}/disableDiagnostics`,
        null
      )
      .toPromise();
  }
}
