import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { DatabaseService, MobileAccess } from '../database.service';
import { Clipboard } from '@angular/cdk/clipboard';


@Component({
    selector: 'app-config-excel-dialog',
    templateUrl: 'config-excel.component.html',
    styleUrls: ['./config-excel.component.css']
})
export class ConfigExcelDialogComponent {

    mobileAccess: MobileAccess;

    constructor(
        public dialogRef: MatDialogRef<ConfigExcelDialogComponent>,
        private tenantService: DatabaseService,
        private clipboard: Clipboard,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        const tenant = data.tenant as string;
        this.mobileAccess = new MobileAccess();
        this.mobileAccess.server = `https://${tenant}.business-one.io/b1s/v1/sml.svc`;
    }

    copyServer() {
        this.clipboard.copy( this.mobileAccess.server );
    }
}
