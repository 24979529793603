<div fxLayout="column" fxLayoutGap="20px">
    <p class="text">{{ tenant.name }}</p>
    <app-tiles [tiles]="tiles">

        <ng-template #startBrowserAccess let-tenant="tenant">
            <div fxLayout="column" fxFill (click)="startBrowserAccessUrl(tenant)" class="normalTile">
                <div class="tileheader">
                    Browser Access
                </div>
                <div fxFlex="1 0" fxLayout="column" fxLayoutAlign="center center">
                    <mat-icon svgIcon="browser-access" class="icon"></mat-icon>
                </div>
                <div fxLayout="row" class="tilefooter">
                    <mat-icon fxFlexAlign="start" i18n i18n-matTooltip="Browser Access tooltip|Tile"
                        matTooltip="Der Browser Access läuft in Ihrem Browser. Er unterstützt alle Standard-Prozesse.Mit ihm lassen sich auch Basiseinstellungen und die Konfiguration des Systems vornehmen.
                    Zielgruppen: Vertriebsmitarbeiter, Einkäufer, Manager, Buchhalter, Produktions- und Lagermitarbeiter">help_outline</mat-icon>
                    <div fxFlex="1 0"></div>
                    <div fxFlexAlign="end" fxLayout="row">
                        <span i18n="@@TileOpen">Öffnen</span>
                        <mat-icon>open_in_new</mat-icon>
                    </div>
                </div>
            </div>
        </ng-template>

        <ng-template #startWebClient>
            <div fxLayout="column" fxFill (click)="startWebClientUrl(tenant)" class="normalTile">
                <div class="tileheader">
                    Web Client
                </div>
                <div fxFlex="1 0" fxLayout="column" fxLayoutAlign="center center">
                    <mat-icon svgIcon="web-client" class="icon"></mat-icon>
                </div>
                <div fxLayout="row" class="tilefooter">
                    <mat-icon fxFlexAlign="start" i18n i18n-matTooltip="Web Client tooltip|Tile" matTooltip="Der Web Client läuft in Ihrem Browser. Er ist der ideale Einstiegspunkt für Endanwender ohne Vorkenntnisse. Mit ihm lassen sich Artikel und Geschäftspartner anlegen und pflegen, aber auch Belege wie Rechnungen buchen. Der Web Client unterstützt nicht alle Prozesse.
                    Zielgruppen: Vertriebsmitarbeiter und Manager">help_outline</mat-icon>
                    <div fxFlex="1 0"></div>
                    <div fxFlexAlign="end" fxLayout="row">
                        <span i18n="@@TileOpen">Öffnen</span>
                        <mat-icon>open_in_new</mat-icon>
                    </div>
                </div>
            </div>
        </ng-template>

        <ng-template #mobileClient>
            <div fxLayout="column" fxFill (click)="configMobileSalesApp(tenant)" class="normalTile">
                <div class="tileheader">
                    Sales App
                </div>
                <div fxFlex="1 0" fxLayout="column" fxLayoutAlign="center center">
                    <mat-icon svgIcon="mobile" class="icon"></mat-icon>
                </div>
                <div fxLayout="row" class="tilefooter">
                    <mat-icon fxFlexAlign="start" i18n i18n-matTooltip="Mobile Sales App tooltip|Tile" matTooltip="Die SAP Business One Sales App läuft auf Android- und Apple-Geräten. Hier wird Ihnen gezeigt, wie Sie sich die App herunterladen und sich danach mit Ihrem SAP Business One System verbinden.
                    Zielgruppe: Vertriebsmitarbeiter (insbesondere Außendienst)">help_outline</mat-icon>
                    <div fxFlex="1 0"></div>
                    <div fxFlexAlign="end" fxLayout="row">
                        <span i18n="@@TileConfigure">Konf.</span>
                        <mat-icon>settings</mat-icon>
                    </div>
                </div>
            </div>
        </ng-template>

        <ng-template #mobilServiceClient>
            <div fxLayout="column" fxFill (click)="configMobileServiceApp(tenant)" class="normalTile">
                <div class="tileheader">
                    Service App
                </div>
                <div fxFlex="1 0" fxLayout="column" fxLayoutAlign="center center">
                    <mat-icon svgIcon="mobileService" class="icon"></mat-icon>
                </div>
                <div fxLayout="row" class="tilefooter">
                    <mat-icon fxFlexAlign="start" i18n i18n-matTooltip="Service App tooltip|Tile" matTooltip="Die SAP Business One Service App läuft auf Android- und Apple-Geräten. Hier wird Ihnen gezeigt, wie Sie sich die App herunterladen und sich danach mit Ihrem SAP Business One System verbinden.
                    Zielgruppe: Mitarbeiter im Service (insbesondere Außendienst)">help_outline</mat-icon>
                    <div fxFlex="1 0"></div>
                    <div fxFlexAlign="end" fxLayout="row">
                        <span i18n="@@TileConfigure">Konf.</span>
                        <mat-icon>settings</mat-icon>
                    </div>
                </div>
            </div>
        </ng-template>

        <ng-template #wms>
            <div fxLayout="column" fxFill (click)="configCobiWmsApp(tenant)" class="normalTile">
                <div class="tileheader">
                    Warehouse Management
                </div>
                <div fxFlex="1 0" fxLayout="column" fxLayoutAlign="center center">
                    <mat-icon svgIcon="wms" class="icon"></mat-icon>
                </div>
                <div fxLayout="row" class="tilefooter">
                    <mat-icon fxFlexAlign="start" i18n i18n-matTooltip="Warehouse Management App tooltip|Tile"
                        matTooltip="Die Warehouse Management App läuft auf Android Geräten. Hier wird Ihnen gezeigt, wie Sie sich die App herunterladen und sich danach mit Ihrem SAP Business One System verbinden.
                    Zielgruppe: Mitarbeiter im Lager">help_outline</mat-icon>
                    <div fxFlex="1 0"></div>
                    <div fxFlexAlign="end" fxLayout="row">
                        <span i18n="@@TileConfigure">Konf.</span>
                        <mat-icon>settings</mat-icon>
                    </div>
                </div>
            </div>
        </ng-template>

        <ng-template #startRDP>
            <div fxLayout="column" fxFill class="disabledTile">
                <div class="tileheader">
                    Desktop Client (deaktiviert)
                </div>
                <div fxFlex="1 0" fxLayout="column" fxLayoutAlign="center center">
                    <mat-icon svgIcon="desktop" class="icon"></mat-icon>
                </div>
                <div fxLayout="row" class="tilefooter">
                    <mat-icon fxFlexAlign="start" i18n i18n-matTooltip="Desktop Client tooltip|Tile"
                        matTooltip="Der Desktop Client erlaubt einen Zugriff mit der SAP GUI über eine Remote-Desktop- Verbindung. Dieser Zugriff kann erforderlich sein, wenn Add-Ons genutzt werden, welche die anderen Zugangsarten nicht unterstützen.
                    Zielgruppen: Vertriebsmitarbeiter, Einkäufer, Manager, Buchhalter, Produktions- und Lagermitarbeiter">help_outline</mat-icon>
                    <div fxFlex="1 0"></div>
                    <div fxFlexAlign="end" fxLayout="row">
                        <span i18n="@@TileOpen">Öffnen</span>
                        <mat-icon>open_in_new</mat-icon>
                    </div>
                </div>
            </div>
        </ng-template>

        <ng-template #rdpApplication let-tenant="tenant" let-app="app" let-tooltip="tooltip" let-svg="svgIcon">
            <div fxLayout="column" fxFill class="normalTile" (click)="startRdpApp(tenant,app)">
                <div class="tileheader">
                    {{ app.name }}
                </div>
                <div fxFlex="1 0" fxLayout="column" fxLayoutAlign="center center">
                    <mat-icon [svgIcon]="svg" class="icon"></mat-icon>
                </div>
                <div fxLayout="row" class="tilefooter">
                    <button mat-icon-button style="width: 24px; height: 24px; line-height:0px"
                        (click)="displayHelp(tenant,app); $event.stopPropagation(); false">
                        <mat-icon fxFlexAlign="start" [matTooltip]="tooltip">help_outline</mat-icon>
                    </button>
                    <div fxFlex="1 0"></div>
                    <div fxFlexAlign="end" fxLayout="row">
                        <span i18n="@@TileOpen">Öffnen</span>
                        <mat-icon>open_in_new</mat-icon>
                    </div>
                </div>
            </div>
        </ng-template>

        <ng-template #installApp let-tenant="tenant" let-app="app" let-tooltip="tooltip" let-svg="svgIcon"
            let-iconClass="iconClass" let-header="header">
            <div fxLayout="column" fxFill class="normalTile" (click)="install(tenant,app)">
                <div class="tileheader">
                    {{ header }}
                </div>
                <div fxFlex="1 0" fxLayout="column" fxLayoutAlign="center center" [ngClass]="iconClass">
                    <mat-icon *ngIf="svg !== undefined" [svgIcon]="svg" class="icon"></mat-icon>
                </div>
                <div fxLayout="row" class="tilefooter">
                    <mat-icon fxFlexAlign="start" [matTooltip]="tooltip">help_outline</mat-icon>
                    <div fxFlex="1 0"></div>
                    <div fxFlexAlign="end" fxLayout="row">
                        <span i18n="@@TileAdd">Hinzufügen</span>
                        <mat-icon>add_circle</mat-icon>
                    </div>
                </div>
            </div>
        </ng-template>

        <ng-template #manageApps let-tenant="tenant" let-tooltip="tooltip" let-svg="svgIcon"
            let-iconClass="iconClass" let-header="header">
            <div fxLayout="column" fxFill class="normalTile" (click)="showManageApps(tenant)">
                <div class="tileheader">
                    {{ header }}
                </div>
                <div fxFlex="1 0" fxLayout="column" fxLayoutAlign="center center" [ngClass]="iconClass">
                    <mat-icon *ngIf="svg !== undefined" [svgIcon]="svg" class="icon"></mat-icon>
                </div>
                <div fxLayout="row" class="tilefooter">
                    <mat-icon fxFlexAlign="start" [matTooltip]="tooltip">help_outline</mat-icon>
                    <div fxFlex="1 0"></div>
                    <div fxFlexAlign="end" fxLayout="row">
                        <span>Open</span>
                        <mat-icon>open_in_new</mat-icon>
                    </div>
                </div>
            </div>
        </ng-template>

        <ng-template #appRegistration let-tenant="tenant" let-app="app" let-reg="appReg" let-tooltip="tooltip"
            let-svg="svgIcon" let-iconClass="iconClass" let-header="header">
            <div fxLayout="column" fxFill class="normalTile" (click)="openApp(tenant,app,reg)">
                <div class="tileheader">
                    {{ header }}
                </div>
                <div fxFlex="1 0" fxLayout="column" fxLayoutAlign="center center" [ngClass]="iconClass">
                    <mat-icon *ngIf="svg !== undefined" [svgIcon]="svg" class="icon"></mat-icon>
                </div>
                <div fxLayout="row" class="tilefooter">
                    <mat-icon fxFlexAlign="start" [matTooltip]="tooltip">help_outline</mat-icon>
                    <div fxFlex="1 0"></div>
                    <div fxFlexAlign="end" fxLayout="row">
                        <span i18n="@@TileOpen">Öffnen</span>
                        <mat-icon>open_in_new</mat-icon>
                    </div>
                </div>
            </div>
        </ng-template>

        <ng-template #appExternal let-tenant="tenant" let-app="app" let-tooltip="tooltip" let-svg="svgIcon"
            let-header="header">
            <div fxLayout="column" fxFill class="normalTile" (click)="configureApp(tenant,app)">
                <div class="tileheader">
                    {{ header }}
                </div>
                <div fxFlex="1 0" fxLayout="column" fxLayoutAlign="center center">
                    <mat-icon [svgIcon]="svg" class="icon"></mat-icon>
                </div>
                <div fxLayout="row" class="tilefooter">
                    <mat-icon fxFlexAlign="start" [matTooltip]="tooltip">help_outline</mat-icon>
                    <div fxFlex="1 0"></div>
                    <div fxFlexAlign="end" fxLayout="row">
                        <span i18n="@@TileConfigure">Konf.</span>
                        <mat-icon>settings</mat-icon>
                    </div>
                </div>
            </div>
        </ng-template>

    </app-tiles>
</div>