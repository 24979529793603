<div fxLayout="column" style="height: 100%">
  <mat-toolbar fxLayout="column" color="primary">
    <mat-toolbar-row class="mat-elevation-z2">
      <button
        mat-icon-button
        (click)="sidenav.toggle()"
        fxShow="true"
        fxHide.gt-sm
      >
        <mat-icon>menu</mat-icon>
      </button>
      <a
        mat-button
        style="font-size: 22px"
        [routerLink]="'/'"
        fxShow="true"
        fxHide.lt-md
        >SAP Business One Cloud
      </a>
      <div fxShow="true" fxHide.lt-md>
        <a
          i18n="toolbar|files"
          *ngIf="organization != null"
          mat-button
          [routerLink]="'/files'"
          >Dateien</a
        >
        <a
          i18n="toolbar|first steps"
          *ngIf="organization != null"
          mat-button
          (click)="showFirstSteps()"
          >Erste Schritte</a
        >
        <a
          i18n="toolbar|support"
          *ngIf="organization != null"
          mat-button
          href="https://business-one-consulting.com/service/support/"
          target="_blank"
          >Support</a
        >
        <a i18n="toolbar|contact" mat-button [href]="contact" target="_blank"
          >Kontakt</a
        >
        <a
          i18n="toolbar|price"
          *ngIf="showPrice"
          mat-button
          href="https://business-one-consulting.com/kosten/"
          target="_blank"
          >Preise</a
        >
        <a *ngIf="isOperator" mat-button [routerLink]="'/tenants'">MANDANT</a>
        <a
          *ngIf="canMaintainOrganization"
          mat-button
          [routerLink]="'/organization'"
          >Organization</a
        >
      </div>
      <span class="menu-spacer"></span>

      <div>
        <button
          *ngIf="isLoggedIn && organization != null"
          mat-mini-fab
          #filterMenuTrigger
          [matMenuTriggerFor]="filterMenu"
          (menuOpened)="onFilterOpened()"
        >
          <mat-icon>filter_alt</mat-icon>
        </button>
        <mat-menu #filterMenu="matMenu" xPosition="before" yPosition="below">
          <div
            (click)="$event.stopPropagation()"
            fxLayout="column"
            style="margin-left: 16px; margin-right: 16px; padding-bottom: 16px"
          >
            <h4 i18n="toolbar|tenant">Mandant</h4>
            <!--
            <mat-checkbox [(checked)]="filter.tenantShowProduction">Produktiv</mat-checkbox>
            <mat-checkbox>Test</mat-checkbox>
            <mat-checkbox>Trial</mat-checkbox> -->
            <mat-checkbox
              [checked]="filter.tenantShowOnline"
              (change)="filter.tenantShowOnline = $event.checked"
              >Online
            </mat-checkbox>
          </div>
          <mat-divider></mat-divider>
          <div
            (click)="$event.stopPropagation()"
            fxLayout="column"
            style="margin: 16px"
          >
            <h4 i18n="toolbar|organization">Organisation</h4>
            <div fxLayout="row">
              <mat-icon>search</mat-icon
              ><input
                matInput
                #orgFilter
                type="text"
                placeholder="Suchen"
                autocomplete="off"
                (input)="onOrgSearchChange($event.target.value)"
              />
            </div>
            <mat-selection-list
              dense
              style="overflow: auto; height: 200px; width: 260px"
              [multiple]="false"
              (selectionChange)="onOrgSelected($event)"
            >
              <mat-list-option
                *ngFor="let org of filteredOrganizations"
                fxLayout="column"
                [value]="org"
              >
                <div matLine style="text-overflow: ellipsis">
                  {{ org.name }}
                </div>
                <div matLine style="text-overflow: ellipsis">
                  <small>{{ org.id }}</small>
                </div>
              </mat-list-option>
            </mat-selection-list>
          </div>
        </mat-menu>
      </div>
      <div style="margin-left: 10px"></div>
      <div>
        <button
          *ngIf="isLoggedIn && organization != null"
          mat-mini-fab
          #notificationsMenuTrigger
          [matMenuTriggerFor]="notificationsMenu"
          (menuOpened)="onNotificationsOpened()"
          [matBadge]="allOperations.length"
          matBadgeColor="warn"
          [matBadgeHidden]="allOperations.length === 0"
        >
          <mat-icon>notifications</mat-icon>
        </button>
        <mat-menu
          #notificationsMenu="matMenu"
          xPosition="before"
          yPosition="below"
        >
          <mat-list>
            <mat-list-item *ngFor="let o of allOperations.reverse()">
              <div fxLayout="row" fxLayoutAlign="start center">
                <mat-icon *ngIf="!o.status.isRunning && o.status.success"
                  >done</mat-icon
                >
                <mat-icon
                  color="warn"
                  *ngIf="!o.status.isRunning && o.status.failed"
                  >error</mat-icon
                >
                <mat-icon *ngIf="o.status.isRunning">run_circle</mat-icon>
                <div style="margin-left: 5px">{{ o.text }}</div>
              </div>
              <mat-divider></mat-divider>
            </mat-list-item>
          </mat-list>
        </mat-menu>
      </div>
      <div style="margin-left: 10px" *ngIf="features.ticketingEnabled"></div>
      <div *ngIf="features.ticketingEnabled">
        <button
          *ngIf="isLoggedIn && organization != null"
          mat-mini-fab
          [matBadge]="pendingSupportTickets" matBadgeColor="warn" 
          [matBadgeHidden]="pendingSupportTickets == 0"
          (click)="showSupportTickets()"         
        >
          <mat-icon>support</mat-icon>
        </button>
      </div>
      <div style="margin-left: 50px"></div>

      <div fxLayout="row" style="width: 50px">
        <mat-select
          [value]="this.locale"
          panelClass="language_panel"
          (selectionChange)="languageSelected($event)"
        >
          <mat-option value="de">DE</mat-option>
          <mat-option value="en">EN</mat-option>
          <mat-option value="hu">HU</mat-option>
        </mat-select>
      </div>

     

      <div style="margin-left: 20px"></div>

      <div
        class="signin"
        fxLayout="row"
        *ngIf="isLoggedIn"
        [matMenuTriggerFor]="accountMenu"
        fxLayoutAlign="center center"
      >
        <div fxLayout="column">
          <div class="mat-body">{{ account?.displayName }}</div>
          <div class="mat-body">{{ organization?.displayName }}</div>
        </div>
        <div style="margin-left: 10px"></div>
        <button mat-mini-fab>
          <mat-icon>person</mat-icon>
        </button>
        <mat-menu
          class="login-menu"
          #accountMenu="matMenu"
          xPosition="before"
          yPosition="below"
          (closed)="accountMenuClosed()"
        >
          <div
            (click)="$event.stopPropagation()"
            fxLayout="column"
            style="margin-left: 16px; margin-right: 16px"
          >
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <div fxFlex>{{ organization?.displayName }}</div>
              <button
                i18n="toolbar|logoff"
                mat-button
                color="primary"
                (click)="logout()"
              >
                Abmelden
              </button>
            </div>
            <div fxLayout="row" style="margin-top: 25px">
              <div fxFlex="30">
                <mat-icon style="font-size: 50px">account_circle</mat-icon>
              </div>
              <div fxFlex="70" fxLayout="column">
                <div style="font-size: large;padding: 0px 16px">{{ getAccountDisplayName(activeAccount) }}</div>
                <div style="font-size: small;padding: 0px 16px">{{ getAccountIdentity(activeAccount) }}</div>
                <a
                  i18n="toolbar|switch organization"
                  style="text-align: left;"
                  mat-button
                  [routerLink]="'/organizations'"
                  >Organisation wechseln</a
                >
              </div>
            </div>
          </div>
          <mat-divider></mat-divider>
          <div
            (click)="$event.stopPropagation()"
            fxLayout="column"
            style="margin: 16px"
          >
            <span i18n="toolbar|access id">Zugangs ID</span>
            <div fxLayout="row">
              <input
                matInput
                readonly="true"
                size="20"
                [value]="userName"
                style="height: 20px"
                fxFlexAlign="center"
              />
              <button mat-icon-button>
                <mat-icon>content_copy</mat-icon>
              </button>
            </div>
            <span i18n="toolbar|access key" style="margin-top: 5px"
              >Zugangsschlüssel</span
            >
            <div fxLayout="row">
              <input
                matInput
                readonly="true"
                size="12"
                [value]="accessKey"
                [type]="showAccessKey ? 'text' : 'password'"
                style="height: 20px"
                fxFlexAlign="center"
              />
              <button mat-icon-button (click)="toggleAccessKey()">
                <mat-icon>{{
                  showAccessKey ? "visibility_off" : "visibility"
                }}</mat-icon>
              </button>
              <button mat-icon-button (click)="copyAccessKey()">
                <mat-icon>content_copy</mat-icon>
              </button>
              <button mat-icon-button (click)="resetAccessKey()">
                <mat-icon>wifi_protected_setup</mat-icon>
              </button>
            </div>
          </div>
          <mat-divider></mat-divider>
          <div
            fxLayout="column"
            fxLayoutGap="10px"
            style="padding: 16px; background-color: ghostwhite"
          >
            <div>Switch account</div>            
            <div *ngFor="let account of availableAccounts" fxLayout="column" fxLayoutGap="10" >
              <div fxLayout="row" fxLayoutAlign="space-between center" (click)="loginAccount(account)">
                <div fxLayout="column" style="cursor: pointer;">
                  <div style="font-size: large; text-overflow: ellipsis">
                    {{ getAccountDisplayName(account) }}
                  </div>
                  <div style="text-overflow: ellipsis">
                    {{ getAccountIdentity(account) }}
                  </div>
                </div>
                <button
                  mat-icon-button
                  color="primary"
                  matTooltip="Forget"
                  aria-label="forget account"
                  (click)="removeAccount(account)"
                >
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
              <mat-divider></mat-divider>
            </div>
            <div fxLayout="column" style="padding-top: 10px;">
              <a style="cursor: pointer;" (click)="login()">Sign in with adifferent account</a>
            </div>
          </div>          
        </mat-menu>
      </div>

      <div
        class="signin"
        fxLayout="row"
        *ngIf="!isLoggedIn"
        fxLayoutAlign="center center"
      >
        <button i18n="toolbar|login" mat-button (click)="login()">
          Anmelden<mat-icon
            style="
              height: 80%;
              font-size: 32pt;
              margin-right: 20px;
              margin-left: 10px;
            "
            >person</mat-icon
          >
        </button>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
  <div fxLayout="column" class="contentarea">
    <mat-sidenav-container fxFill class="contentcontainer">
      <mat-sidenav #sidenav>
        <mat-nav-list>
          <a
            i18n="toolbar|support"
            *ngIf="organization != null"
            mat-list-item
            href="https://business-one-consulting.com/service/support/"
            target="_blank"
            >Support</a
          >
          <a
            i18n="toolbar|contact"
            mat-list-item
            [href]="contact"
            target="_blank"
            >Kontakt</a
          >
          <a
            i18n="toolbar|price"
            *ngIf="showPrice"
            mat-list-item
            href="https://business-one-consulting.com/kosten/"
            target="_blank"
            >Preise</a
          >
          <a
            i18n="toolbar|first steps"
            *ngIf="organization != null"
            mat-list-item
            (click)="showFirstSteps()"
            >Erste Schritte</a
          >
          <a
            *ngIf="isOperator"
            (click)="sidenav.toggle()"
            [routerLink]="'/tenants'"
            mat-list-item
            >MANDANT</a
          >
          <a
            *ngIf="isOperator"
            (click)="sidenav.toggle()"
            [routerLink]="'/organization'"
            mat-list-item
            >ORGANISATION</a
          >
        </mat-nav-list>
      </mat-sidenav>
      <mat-sidenav-content class="contentcontainer">
        <router-outlet></router-outlet>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</div>
