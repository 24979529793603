import { Component, OnInit, Inject, LOCALE_ID } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { AccountService } from '../account.service';
import { App, AppsService } from '../apps.service';
import { ContextService } from '../context.service';
import { AppRegistration, Database, DatabaseService } from '../database.service';
import { OrganizationService } from '../organization.service';
import { environment } from '../../environments/environment';

export interface IApp {
  id: string;
  variant?: string;
  svgIcon: string;
}

export interface IManageAppsContext {
  organization: string;
  tenant: Database;
}

const markeplace = "business-one.io-marketplace";
const crm = "business-one.io-crm";
const shipping = "business-one.io-shipping";


@Component({
  selector: 'app-manage-apps',
  templateUrl: './manage-apps.component.html',
  styleUrls: ['./manage-apps.component.css']
})
export class ManageAppsComponent implements OnInit {


  public busy: boolean = false;

  public appDocumentRecognition: IApp = {
    id: "business-one.io-document-recognition",
    svgIcon: "docrec"
  };

  public appMailer: IApp = {
    id: "business-one.io-mailer",
    svgIcon: "mailer"
  };

  public appDocumentArchive: IApp = {
    id: "business-one.io-document-archive",
    svgIcon: "documentarchive"
  };

  public appFulfillment: IApp = {
    id: "business-one.io-fulfillment",
    svgIcon: "whm-browser"
  };

  public appTimesheet: IApp = {
    id: "business-one.io-timesheet-manager",
    svgIcon: "time-recording"
  };

  public appHanaWorkbench: IApp = {
    id: "business-one.io-hana-workbench",
    svgIcon: "hanaworkbench"
  };

  public appIntegrationMonitor: IApp = {
    id: "business-one.io-integration-monitor",
    svgIcon: "integrationmonitor"
  };

  public appFXImport: IApp = {
    id: "business-one.io-fx-import",
    svgIcon: "fximport"
  };

  public appDatevImport: IApp = {
    id: "business-one.io-datev-import",
    svgIcon: "datevimport"
  };

  public appStatisticDoc: IApp = {
    id: "business-one.io-statistical-documents",
    svgIcon: "statisticdoc"
  };

  public appShopify: IApp = {
    id: markeplace,
    variant: "1",
    svgIcon: "shopify"
  };

  public appWoo: IApp = {
    id: markeplace,
    variant: "0",
    svgIcon: "woocommerce"
  };

  public appShopware: IApp = {
    id: markeplace,
    variant: "2",
    svgIcon: "shopware"
  };

  public appBig: IApp = {
    id: markeplace,
    variant: "3",
    svgIcon: "bigcommerce"
  };

  public appOxid: IApp = {
    id: markeplace,
    variant: "4",
    svgIcon: "oxid"
  };

  public appAmazon: IApp = {
    id: markeplace,
    variant: "5",
    svgIcon: "amazon"
  };

  public appHubspot: IApp = {
    id: crm,
    variant: "hubspot",
    svgIcon: "hubspot"
  };

  public appCrm: IApp = {
    id: crm,
    variant: "crm",
    svgIcon: "crm"
  };

  public appUPS: IApp = {
    id: shipping,
    variant: "UPS",
    svgIcon: "ups"
  };

  public appLabels: IApp = {
    id: "business-one.io-labels",
    svgIcon: "labels"
  };

  public appManufacturing: IApp = {
    id: "business-one.io-manufacturing",
    svgIcon: "manufacturing"
  };

  public appGoodsReceipt: IApp = {
    id: "business-one.io-goods-receipt",
    svgIcon: "whm"
  };

  public appQA: IApp = {
    id: "business-one.io-qa-app",
    svgIcon: "qa"
  };

  public appMetalSurcharge: IApp = {
    id: "business-one.io-metal-surcharge",
    svgIcon: "metalsurcharge"
  };

  public appEDI: IApp = {
    id: "business-one.io-edi",
    svgIcon: "edi"
  };

  public appDespatchOrder: IApp = {
    id: "business-one.io-despatchorder",
    svgIcon: "despatchorder"
  };

  public appApproval: IApp = {
    id: "business-one.io-approval",
    svgIcon: "appApproval"
  };

  public appService: IApp = {
    id: "business-one.io-service",
    svgIcon: "appService"
  };

  public appDeviceDiagnostics: IApp = {
    id: "business-one.io-device-diagnostics",
    svgIcon: "appDeviceDiagnostics"
  };

  public appEInvoice: IApp = {
    id: "business-one.io-einvoice",
    svgIcon: "einvoice"
  };

  public appInbox: IApp = {
    id: "business-one.io-inbox",
    svgIcon: "inboxApp"
  };

  public appTime: IApp = {
    id: "business-one.io-time",
    svgIcon: "timeApp"
  };


  public get isOperator(): boolean {
    return this.accountService.account?.isOperator ?? false;
  }


  constructor(
    public dialogRef: MatDialogRef<ManageAppsComponent>,
    private accountService: AccountService,
    private organizationService: OrganizationService,
    private appsService: AppsService,
    private tenantService: DatabaseService,
    private contextService: ContextService,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(MAT_DIALOG_DATA) public data: IManageAppsContext) {

  }

  ngOnInit(): void {
  }

  public isAppAvailable(app: IApp): boolean {
    const a = this.organizationService.activeOrganization?.availableApps.find((a) => a === app.id);
    return a != null;
  }

  public isAppInstalled(app: IApp): boolean {
    const a = this.data.tenant.appRegistrations.find((a) => a.applicationId === app.id && (app.variant === undefined || app.variant === a.variant));
    return a != null;
  }

  public canInstallApp(app: IApp): boolean {
    if (this.isAppInstalled(app)) {
      return false;
    }
    if (!this.isAppAvailable(app)) {
      return false;
    }
    if (app.id === markeplace) {
      return false;
    }
    return true;
  }

  public canUninstallApp(app: IApp): boolean {
    if (!this.isAppInstalled(app)) {
      return false;
    }
    if (!this.isAppAvailable(app)) {
      return false;
    }
    if (app.id === markeplace) {
      return false;
    }
    return true;
  }

  public getAppStatus(app: IApp): string {
    if (!this.isAppAvailable(app)) {
      return "On demand";
    }
    if (this.isAppInstalled(app)) {
      return "Installed";
    }
    return "Not installed";
  }

  private async reloadTenants(): Promise<void> {
    await this.contextService.reloadTenants();
    this.data.tenant = this.contextService.tenants.find(t => t.id == this.data.tenant.id);
  }

  private getInstallerBaseUrl(app: IApp): string | null {
    if (app.id === crm) {
      if (app.variant === "hubspot") {
        if (environment.staging) {
          return "https://hubspot-installe-staging.apps.staging.business-one.io?org=${organization}&tenant=${tenant}&reg=${appReg}&intent=${intent}&login_hint=${login_hint}&domain_hint=${domain_hint}";
        } else {
          return "https://hubspot-installer.apps.business-one.io?org=${organization}&tenant=${tenant}&reg=${appReg}&intent=${intent}&login_hint=${login_hint}&domain_hint=${domain_hint}";
        }
      }
    }
    if (app.id == shipping) {
      if (app.variant === "UPS") {
        if (environment.staging) {
          return "https://ups-installer-staging.apps.staging.business-one.io?org=${organization}&tenant=${tenant}&reg=${appReg}&intent=${intent}&login_hint=${login_hint}&lang=${lang}&domain_hint=${domain_hint}";
        } else {
          return "https://ups-installer.apps.business-one.io?org=${organization}&tenant=${tenant}&reg=${appReg}&intent=${intent}&login_hint=${login_hint}&lang=${lang}&domain_hint=${domain_hint}";
        }
      }
    }
    return null;
  }



  public async installApp(app: IApp): Promise<void> {

    try {
      this.busy = true;

      if (app.id === crm || app.id === shipping) {
        const baseUrl = this.getInstallerBaseUrl(app);
        if (baseUrl == null) {
          return;
        }
        this.openInstallerWindow(baseUrl, "install", app.id);
        return;
      }

      await this.tenantService.installApp({
        applicationId: app.id,
        organizationId: this.data.tenant.organizationId,
        tenantId: this.data.tenant.id
      } as AppRegistration);
      await this.reloadTenants();
    } catch (error) {
    } finally {
      this.busy = false;
    }
  }

  public async repairApp(app: AppRegistration): Promise<void> {

    try {
      this.busy = true;

      if (app.applicationId === crm || app.applicationId === shipping) {
        const baseUrl = this.getInstallerBaseUrl({
          id: app.applicationId,
          variant: app.variant,
          svgIcon: ""
        });
        if (baseUrl == null) {
          return;
        }
        this.openInstallerWindow(baseUrl, "repair");
        return;
      }

    } catch (error) {
    } finally {
      this.busy = false;
    }
  }

  private openInstallerWindow(installerUrl: string, itent: string, appReg?: string): void {

    let url =
      installerUrl.replace("${organization}", this.data.tenant.organizationId).
        replace("${tenant}", this.data.tenant.id).
        replace("${intent}", itent).
        replace("${appReg}", appReg ?? '').
        replace("${login_hint}", this.accountService.account.username).
        replace("${domain_hint}", this.accountService.account.domain ?? "").
        replace("${lang}", this.locale);
    let height = 800;
    let width = 1000;
    var left = (screen.width - width) / 2;
    var top = (screen.height - height) / 2;
    window.open(url, '_blank', `toolbar=0,location=0,menubar=0,width=${width},height=${height},top=${top},left=${left}`);
  }

  public async uninstallApp(app: AppRegistration): Promise<void> {
    try {
      this.busy = true;

      if (app.applicationId === crm || app.applicationId === shipping) {
        const baseUrl = this.getInstallerBaseUrl({
          id: app.applicationId,
          variant: app.variant,
          svgIcon: ""
        });
        if (baseUrl == null) {
          return;
        }

        this.openInstallerWindow(baseUrl, "uninstall", app.id);
        return;
      }

      await this.tenantService.uninstallApp(app);
      await this.reloadTenants();
    } catch (error) {
    } finally {
      this.busy = false;
    }
  }

  public async enableTestVersion(appReg: AppRegistration): Promise<void> {
    try {
      this.busy = true;
      await this.tenantService.enableTestVersion(appReg);
      await this.reloadTenants();
    } catch (error) {
    } finally {
      this.busy = false;
    }
  }

  public async disableTestVersion(appReg: AppRegistration): Promise<void> {
    try {
      this.busy = true;
      await this.tenantService.disableTestVersion(appReg);
      await this.reloadTenants();
    } catch (error) {
    } finally {
      this.busy = false;
    }
  }

  public async enableDiagnostics(appReg: AppRegistration): Promise<void> {
    try {
      this.busy = true;
      await this.tenantService.enableDiagnostics(appReg);
      await this.reloadTenants();
    }
    catch (error) {
    } finally {
      this.busy = false;
    }
  }

  public async disableDiagnostics(appReg: AppRegistration): Promise<void> {
    try {
      this.busy = true;
      await this.tenantService.disableDiagnostics(appReg);
      await this.reloadTenants();
    }
    catch (error) {
    } finally {
      this.busy = false;
    }
  }

}
