<p>administration works!</p>
<p>Backup scheduled: {{ backupScheduled }}</p>
<button (click)="scheduleBackup()">Schedule daily backup</button>
<button (click)="unscheduleBackup()">Unschedule daily backup</button>
<button (click)="cleanup()">Cleanup</button>
<button (click)="cleanupTrial()">Cleanup Trials</button>
<button (click)="checkServiceUnitQueues()">Check ServiceUnitQueues</button>
<button (click)="stopTenants()">Stop all Tenants</button>
<button (click)="startTenants()">Start all Tenants</button>
<div fxLayout="row">
<mat-form-field class="spacer">
    <mat-label>Azure Function Instance</mat-label>
    <input matInput [(ngModel)]="instanceId">
</mat-form-field>
<button (click)="terminateInstance()">Terminate Instance</button>
</div>