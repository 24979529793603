<mat-form-field style="width: 100%;">
    <mat-chip-list #chipList aria-label="Lizenzen auswählen">
      <mat-chip
        *ngFor="let e of selectedLicenses"
        (removed)="remove(e)">
        {{ e | licenseText }}                    
        <mat-icon *ngIf="enabled" matChipRemove>cancel</mat-icon>                    
      </mat-chip>
      <input
        placeholder="Lizenz"
        #licenseInput
        [formControl]="licensesCtrl"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (matChipInputTokenEnd)="add($event)"
        [disabled]="!enabled">
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
     <mat-option *ngFor="let a of filteredLicenses" [value]="a">
        {{ a | licenseText }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>