import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-first-steps-dialog',
    templateUrl: 'first-steps-dialog.html',
    styleUrls: ['./first-steps-dialog.css']
})
export class FirstStepsDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<FirstStepsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any, iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
        iconRegistry.addSvgIcon(
            'browser-access',
            sanitizer.bypassSecurityTrustResourceUrl('assets/Browser Access.svg'));
        iconRegistry.addSvgIcon(
            'web-client',
            sanitizer.bypassSecurityTrustResourceUrl('assets/Web Client.svg'));
        iconRegistry.addSvgIcon(
            'desktop',
            sanitizer.bypassSecurityTrustResourceUrl('assets/Desktop.svg'));
        iconRegistry.addSvgIcon(
            'mobile',
            sanitizer.bypassSecurityTrustResourceUrl('assets/Mobile Apps.svg'));
        iconRegistry.addSvgIcon(
            'mobile-service',
            sanitizer.bypassSecurityTrustResourceUrl('assets/ServiceApp.svg'));

    }
}
