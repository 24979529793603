import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MobileAccess } from '../database.service';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
    selector: 'app-config-cobiwms-dialog',
    templateUrl: 'config-cobiwms.component.html',
    styleUrls: ['./config-cobiwms.component.css']
})
export class ConfigCobiWmsDialogComponent {

    mobileAccess: MobileAccess;

    constructor(
        public dialogRef: MatDialogRef<ConfigCobiWmsDialogComponent>,
        private clipboard: Clipboard,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        const tenant = data.tenant as string;
        this.mobileAccess = new MobileAccess();
        this.mobileAccess.server = 'https://eu.business-one.io/b1s/v1';

    }

    copyServiceLayer() {
        this.clipboard.copy( this.mobileAccess.server );
    }

    copyTenant() {
        this.clipboard.copy( this.data.tenant );
    }

}
