<h1 mat-dialog-title>Datenbank kopieren von</h1>
<div mat-dialog-content fxLayout="column">
  <section fxLayout="column">
    <mat-form-field>
      <mat-label>Source organization</mat-label>
      <input
        type="text"
        placeholder="Select"
        aria-label="Organization"
        matInput
        [formControl]="orgControl"
        [matAutocomplete]="auto"
      />
      <mat-autocomplete
        autoActiveFirstOption
        #auto="matAutocomplete"
        (optionSelected)="selectOrg($event.option.value)"
        [displayWith]="displayOrg"
      >
        <mat-option *ngFor="let org of filteredOrgs | async" [value]="org">
          {{org.name}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Source tenant</mat-label>
      <input
        type="text"
        placeholder="Select"
        aria-label="Tenant"
        matInput
        [formControl]="tenantControl"
        [matAutocomplete]="autoTenant"
      />
      <mat-autocomplete
        autoActiveFirstOption
        #autoTenant="matAutocomplete"
        (optionSelected)="selectTenant($event.option.value)"
        [displayWith]="displayTenant"
      >
        <mat-option *ngFor="let t of filteredTenants | async" [value]="t">
          {{t.id}} - {{t.name}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Source backup</mat-label>
      <input
        type="text"
        placeholder="Select"
        aria-label="Backup"
        matInput
        [formControl]="backupControl"
        [matAutocomplete]="autoBackup"
      />
      <mat-autocomplete
        autoActiveFirstOption
        #autoBackup="matAutocomplete"
        (optionSelected)="selectBackup($event.option.value)"
        [displayWith]="displayBackup"
      >
        <mat-option *ngFor="let b of filteredBackups | async" [value]="b">
          {{b.createdOn | date }} - {{b.description}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </section>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Abbrechen</button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Ok</button>
</div>
