import { Component } from '@angular/core';

@Component({
    selector: 'app-imprint',
    templateUrl: 'imprint.html',
    styleUrls: ['./imprint.css']
})
export class ImprintComponent {

    constructor(
    ) {
    }
}
