import { Component, OnInit } from '@angular/core';
import { AdministrationService } from '../administration.service';

@Component({
  selector: 'app-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.css']
})
export class AdministrationComponent implements OnInit {

  constructor(private adminService: AdministrationService) { }

  backupScheduled: boolean;
  instanceId: string;

  ngOnInit(): void {
    this.adminService.getBackupSchedule().toPromise().then(s => this.backupScheduled = s.isScheduled);
  }

  scheduleBackup(): void {
    this.adminService.scheduleDailyBackup().toPromise().then();
  }

  unscheduleBackup(): void {
    this.adminService.unscheduleDailyBackup().toPromise().then();
  }

  cleanup(): void {
    this.adminService.cleanup().toPromise().then();
  }

  cleanupTrial(): void {
    this.adminService.cleanupTrial().toPromise().then();
  }

  stopTenants(): void {
    this.adminService.stopTenants().toPromise().then();
  }

  startTenants(): void {
    this.adminService.startTenants().toPromise().then();
  }

  checkServiceUnitQueues(): void {
    this.adminService.checkServiceUnitQueues().finally(() => { });
  }

  terminateInstance(): void {
    this.adminService.terminateInstance(this.instanceId).finally(() => { });
  }




}
