<div fxLayout="column" fxLayoutAlign="start start" class="contentarea" style="padding: 50px;" *ngIf="locale === 'de'">
    <section>
        <h1>Datenschutzerklärung</h1>
        <p>
            Wir freuen uns über Ihren Besuch auf unserer Webseite. Der Schutz Ihrer personenbezogenen Daten ist für uns
            ein wichtiges Anliegen und wir möchten, dass Sie sich beim Besuch unserer Webseite sicher fühlen. Wir
            schützen Ihre Privatsphäre und Ihre personenbezogenen Daten. Wir verarbeiten Ihre personenbezogenen Daten
            nach dem Inhalt dieser Datenschutzerklärung unter Beachtung der geltenden datenschutzrechtlichen
            Bestimmungen der Datenschutz-Grundverordnung (DSGVO) bzw. der nationalen Bestimmungen des
            Bundesdatenschutzgesetzes (BDSG).
        </p>
    </section>
    <section>
        <h2>Inhaltsverzeichnis</h2>
        <ul>
            <li (click)="scroll('Section1')" class="contentLink">Name und Kontaktdaten des Verantwortlichen</li>
            <li (click)="scroll('Section2')" class="contentLink">Kontakt zum Datenschutzbeauftragten</li>
            <li (click)="scroll('Section3')" class="contentLink">Was sind personenbezogene Daten?</li>
            <li (click)="scroll('Section4')" class="contentLink">Zwecke der Datenverarbeitung</li>
            <li (click)="scroll('Section5')" class="contentLink">Rechtsgrundlage der Datenverarbeitung</li>
            <li (click)="scroll('Section6')" class="contentLink">Nutzung unserer Webseite zu Informationszwecken</li>
            <li (click)="scroll('Section7')" class="contentLink">Nutzung unserer Webseite für weitere Leistungen</li>
            <li (click)="scroll('SectionInterfaces')" class="contentLink">Schnittstellen</li>
            <li (click)="scroll('Section8')" class="contentLink">SAP Business One Cloud - Testportal</li>
            <li (click)="scroll('E-Rechnung')" class="contentLink">E-Rechnungsviewer</li>
            <li (click)="scroll('Section9')" class="contentLink">Kontakt zu uns</li>
            <li (click)="scroll('Section10')" class="contentLink">Newsletter</li>
            <li (click)="scroll('Section11')" class="contentLink">Sicherheit</li>
            <li (click)="scroll('Section12')" class="contentLink">Cookies</li>
            <li (click)="scroll('Section13')" class="contentLink">Webanalyse</li>
            <li (click)="scroll('Section14')" class="contentLink">Social Media</li>
            <li (click)="scroll('Section15')" class="contentLink">Weitere Funktionen und Inhalte</li>
            <li (click)="scroll('Section16')" class="contentLink">Empfänger und Datenübermittlung</li>
            <li (click)="scroll('Section17')" class="contentLink">Datenübermittlung in Drittländer</li>
            <li (click)="scroll('Section18')" class="contentLink">Löschung Ihrer Daten</li>
            <li (click)="scroll('Section19')" class="contentLink">Ihre Rechte</li>
            <li (click)="scroll('Section20')" class="contentLink">Änderung unserer Datenschutzerklärung</li>
        </ul>
    </section>
    <section id="Section1">
        <h2>Name und Kontaktdaten des Verantwortlichen</h2>
        <p>Die conesprit GmbH, Hans-Gaugler-Weg 14, 71522 Backnang ist als Betreiberin der Webseite <a
                href="https://www.business-one.cloud/de/"></a> Verantwortliche im Sinne der DSGVO.</p>
    </section>
    <section id="Section2">
        <h2>Kontakt zu unserer Datenschutzbeauftragten</h2>
        <p>
            Sie können sich jederzeit mit allen datenschutzrechtlichen Belangen an unsere Datenschutzbeauftragte <a
                href="mailto:datenschutz@business-one-consulting.com">Katrin Douverne
                datenschutz@business-one-consulting.com</a> wenden.
        </p>
    </section>
    <section id="Setcion3">
        <h2>Was sind personenbezogene Daten?</h2>
        <p>
            Personenbezogene Daten sind alle Informationen, die sich auf eine identifizierte oder identifizierbare
            natürliche Person beziehen; als identifizierbar wird eine natürliche Person angesehen, die direkt oder
            indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu
            Standortdaten, zu einer Online-Kennung oder zu einem oder mehreren besonderen Merkmalen identifiziert werden
            kann, die Ausdruck der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen
            oder sozialen Identität dieser natürlichen Person sind.
        </p>
    </section>
    <section id="Section4">
        <h2>Zwecke der Datenverarbeitung</h2>
        <p>
            Umfang und Art der Erhebung, Verarbeitung und Nutzung Ihrer Daten unterscheidet sich danach, ob Sie unseren
            Internetauftritt lediglich zum Abruf von allgemein verfügbaren Informationen besuchen oder
            darüberhinausgehende Leistungen in Anspruch nehmen. Grundsätzlich verarbeiten wir Ihre personenbezogenen
            Daten im Rahmen unserer unternehmerischen Tätigkeit für vorvertragliche oder vertragliche Zwecke. Daneben
            kann die Ausübung unser berechtigtes Interesse oder die Einhaltung von gesetzlichen Vorgaben ebenfalls
            inhaltlicher Zweck einer Datenverarbeitung durch uns sein. Über die jeweiligen konkreten Zwecke der
            Datenverarbeitung informieren wir Sie in den nachfolgenden Bereichen.
        </p>
    </section>
    <section id="Section5">
        <h2>Rechtsgrundlage der Datenverarbeitung</h2>
        <p>
            Wir verarbeiten Ihre personenbezogenen Daten nach den folgenden Rechtsgrundlagen:
        </p>
        <ul>
            <li>Zur Erfüllung von vorvertraglichen oder vertraglichen Pflichten (Art. 6 Abs. 1b) DSGVO)</li>
            <li>Aufgrund Ihrer Einwilligung (Art. 6 Abs. 1a) DSGVO)</li>
            <li>Im Rahmen einer Interessenabwägung (Art. 6 Abs. 1f) DSGVO)</li>
            <li>Aufgrund gesetzlicher Vorgaben (Art. 6 Abs. 1c) DS-GVO)</li>
        </ul>
        <p>
            Daneben können je nach Art und Umfang der Verarbeitung weitere Rechtsgrundlagen nach landesspezifischen
            Bestimmungen bestehen. Über die konkreten Rechtsgrundlagen der Datenverarbeitung informieren wir Sie bei
            unseren jeweiligen Verarbeitungen.
        </p>
    </section>
    <section id="Section6">
        <h2>Nutzung unserer Webseite zu Informationszwecken</h2>
        <p>
            Für eine rein informatorische Nutzung unserer Webseite ist es grundsätzlich nicht erforderlich, dass Sie
            personenbezogene Daten angeben. Vielmehr erfassen wir in diesem Fall beim Aufruf unserer Webseiten nur
            diejenigen Daten, die uns Ihr Internetbrowser automatisch übermittelt, wie etwa:
        </p>
        <ul>
            <li>die IP-Adresse Ihres Computers</li>
            <li>Datum und Uhrzeit des Seitenaufrufs</li>
            <li>Ihren Browsertyp, die Browserversion sowie Ihre Browsereinstellungen</li>
            <li>das verwendete Betriebssystem (Windows, iOS, Linux etc.)</li>
            <li>die übertragene Datenmenge und Status von Übertragungen</li>
            <li>von welcher Website aus der Zugriff auf unsere Seite erfolgte</li>
            <li>sonstige ähnliche Daten und Informationen, welche der Gefahrenabwehr im Falle von Angriffen auf unsere
                informationstechnologischen Systeme dienen.</li>
        </ul>
        <p>
            Dies geschieht in der Regel durch die Verwendung von Logfiles. Zweck der Verarbeitung ist die Sicherstellung
            der Funktionalität und Kompatibilität unserer Webseite für eine technisch unproblematische Nutzung
            einschließlich Störungsbeseitigung sowie der Schutz vor technischen Angriffen und Missbrauch der Nutzung.
            Rechtsgrundlage für diese Verarbeitung ist unser berechtigtes Interesse nach Art. 6 Abs. 1 f) DSGVO. Unser
            berechtigtes Interesse liegt im ordnungsgemäßen Betrieb unserer Webseite. Die Logfiledaten werden gelöscht,
            wenn diese für den Zweck der Verarbeitung nicht mehr erforderlich sind.
        </p>
    </section>
    <section id="Section7">
        <h2>Nutzung unserer Webseite für weitere Leistungen</h2>
        <p>
            Soweit Sie über unsere Webseite weitere Leistungen unseres Unternehmens in Anspruch nehmen, ist es
            gegebenenfalls erforderlich, dass Sie hierzu personenbezogene Daten angeben. Welche personenbezogenen Daten
            zur Leistungserbringung erforderlich sind, ergibt sich aus der jeweiligen Eingabemaske bzw. Anwendung.
            Weitere Angaben können Sie freiwillig tätigen. Welche Angaben erforderlich und welche freiwillig sind,
            können Sie daran erkennen, dass die Pflichtangaben jeweils mit einem Sternchen (*) bzw. mit dem Hinweis
            „erforderlich“ gekennzeichnet sind. Die Verarbeitung Ihrer Daten erfolgt grundsätzlich ausschließlich zu dem
            Zweck, die von Ihnen gewünschte Leistung zu erbringen. Daneben können im Einzelfall zusätzliche Zwecke für
            die Datenverarbeitung bestehen. Den jeweiligen Zweck und die Rechtsgrundlage für die Verarbeitung Ihrer
            personenbezogenen Daten sowie die Angaben darüber wann Ihre personenbezogenen Daten gelöscht werden,
            erfahren Sie bei der Beschreibung der konkreten Leistungen.
        </p>
    </section>
    <section id="SectionInterfaces">
        <h2>Schnittstellen</h2>
        <p>
            Die SAP Business One Cloud unterstützt Schnittstellen zu den Webshop-Anbietern Shopify, Shopware, WooCommerce, OXID und BigCommerce sowie zum Amazon Marktplatz.
            Der Datenaustausch zwischen der SAP Business One Cloud und den Schnittstellenpartnern findet ausnahmslos verschlüsselt statt (HTTPS/TLS V1.2). Es werden Algorithmen mit starken Schlüsseln eingesetzt (AES-128- oder stärker). 
            Personenbezogene Daten werden in den Schnittstellen nur für Zwecke der Datenübertragung vorgehalten. Sie werden nicht an Drittanbieter übertragen (außer an die benannten Schnittstellenpartner). Eine Archivierung personenbezogener Daten erfolgt nicht. Sie werden spätestens nach 31 Tagen gelöscht.
        </p>
    </section>
    <section id="Section8">
        <h2>SAP Business One Cloud - Testportal</h2>
        <p>
            Auf unserer Webseite können Sie unsere Dienstleistung „SAP Business One Cloud“ als Demosystem testen. Für
            die Anmeldung zum Demosystem ist es erforderlich, dass Sie hierzu personenbezogene Daten angeben. Welche
            personenbezogenen Daten erforderlich sind, ergibt sich aus der jeweiligen Eingabemaske. Weitere Angaben
            können Sie freiwillig tätigen. Welche Angaben erforderlich und welche freiwillig sind, können Sie daran
            erkennen, dass die Pflichtangaben jeweils mit einem Sternchen (*) gekennzeichnet sind. Die Verarbeitung
            Ihrer Daten erfolgt zu dem Zweck Ihnen die Dienstleistung „SAP Business One Cloud“ als Demosystem zur
            Verfügung stellen zu können. Im Rahmen dieser Zweckbindung sind wir auch berechtigt, Ihrer personenbezogenen
            Daten an unser Partnerunternehmen SAP Deutschland SE & Co. KG, Hasso-Plattner-Ring 7, 69190 Walldorf,
            Deutschland weiterzuleiten. Rechtsgrundlage für diese Verarbeitung ist Art. 6 Abs. 1 b) DSGVO. Ihre
            personenbezogenen Daten werden gelöscht, wenn diese zur Zweckerfüllung der Nutzung unseres Testportales
            nicht mehr erforderlich sind.
        </p>
    </section>
    <section id="E-Rechnung">
        <h2>E-Rechnungsviewer (e-invoice viewer)</h2>
        <p>
            Auf unserer Webseite können Sie unter <a href="https://www.business-one.cloud/e-rechnung">https://www.business-one.cloud/e-rechnung</a> unsere Dienstleistung „E-Rechnungsviewer“ zur Lesbarkeitmachung von elektronischen Rechnungen „E-Rechnung“ nutzen. Eine E-Rechnung ist dabei ein nach genauen Vorgaben strukturierter Datensatz, der in einem elektronischen Format erstellt, übermittelt und empfangen wird. Dieser Datensatz muss der europäischen Norm EN16931 für die elektronische Rechnungsstellung entsprechen. Sie können Ihre E-Rechnung (z.B. invoice-exampel.xml) dazu einfach per Drag & Drop auf unseren „E-Rechnungsviewer“ ziehen und erhalten die Rechnungsinhalte in einer lesbaren Struktur. 
            Im Anschluss daran können Sie diese Rechnung z.B. speichern oder ausdrucken. 
            Es erfolgt keine Speicherung Ihrer E-Rechnung durch uns auf unseren lokalen Servern oder in einer Cloud. 
            Wir haben bei der Verarbeitung zur Lesbarkeitmachung der E-Rechnung auch zu keinem Zeitpunkt Zugriff oder Einsicht auf Ihre E-Rechnung. 
            Ein Einsatz von Dritten oder Auftragsverarbeitern erfolgt ebenfalls nicht. 
            Schließen Sie den „E-Rechnungsviewer“ löscht sich die E-Rechnung automatisch.
        </p>
    </section>
    <section id="Section9">
        <h2>Kontakt zu uns</h2>
        <h3>Kontaktformular</h3>
        <p>Auf unserer Webseite bieten wir Ihnen die Möglichkeit mittels eines Kontaktformulars mit uns in Kontakt zu
            treten. Wenn Sie den Button „Kontakt“ anklicken, werden Sie auf die Hauptseite unseres Unternehmens zum
            Kontaktbereich weitergeleitet. Die personenbezogenen Daten, die Sie aufgrund einer Kontaktaufnahme in das
            Kontaktformular eingeben, werden nur für den Zweck der Bearbeitung Ihrer Kontaktaufnahme verarbeitet. Eine
            Weitergabe an Dritte findet nur statt, wenn dies für den Zweck der Bearbeitung Ihrer Kontaktaufnahme
            erforderlich ist. Rechtsgrundlage für diese Verarbeitung ist Art. 6 Abs. 1 b) DSGVO. Ihre personenbezogenen
            Daten werden gelöscht, wenn diese zur Zweckerfüllung Ihrer Kontaktaufnahme nicht mehr erforderlich sind. Wir
            weisen darauf hin, dass Ihre Nachrichten gegebenenfalls im Rahmen gesetzlich bestehender
            Aufbewahrungspflichten aufbewahrt werden müssen. In diesem Falle ist die Rechtsgrundlage Art. 6 Abs. 1 c)
            DSGVO.</p>
        <h3>Kontakt per Mail</h3>
        <p>Auf unserer Webseite bieten wir Ihnen die Möglichkeit mit uns mittels E-Mail in Kontakt zu treten. Bitte
            beachten Sie dabei, dass eine unverschlüsselte Kommunikation mittels E-Mail unsicher ist. Es kann nicht
            ausgeschlossen werden, dass auf diesem Wege übermittelte Daten von Unbefugten gelesen, kopiert, verändert
            oder gelöscht werden. Die personenbezogenen Daten, die Sie aufgrund einer Kontaktaufnahme über eine
            E-Mail-Anfrage angeben, werden nur für den Zweck der Bearbeitung Ihrer E-Mail-Anfrage verarbeitet. Eine
            Weitergabe an Dritte findet nur statt, wenn dies für den Zweck der Bearbeitung dieser Kontaktaufnahme
            erforderlich ist. Rechtsgrundlage für diese Verarbeitung ist Art. 6 Abs. 1 b) DSGVO. Ihre personenbezogenen
            Daten werden gelöscht, wenn diese zur Zweckerfüllung Ihrer Kontaktaufnahme nicht mehr erforderlich sind. Wir
            weisen darauf hin, dass Ihre Nachrichten gegebenenfalls im Rahmen gesetzlich bestehender
            Aufbewahrungspflichten aufbewahrt werden müssen. In diesem Falle ist die Rechtsgrundlage Art. 6 Abs. 1 c)
            DSGVO.</p>
    </section>
    <section id="Section10">
        <h2>Newsletter</h2>
        <p>Ein Newslettereinsatz findet nicht statt.</p>
    </section>
    <section id="Section11">
        <h2>Sicherheit</h2>
        <p>Wir haben unsere Webseite und sonstigen Systeme durch technische und organisatorische Maßnahmen gegen
            Verlust, Zerstörung, Zugriff, Veränderung oder Verbreitung Ihrer Daten durch unbefugte Personen abgesichert.
            Insbesondere werden Ihre im Kontaktformular mitgeteilten personenbezogenen Daten verschlüsselt übertragen.
            Wir bedienen uns dabei des Codierungssystems TLS 1.2 (Transport Layer Security).</p>
    </section>
    <section id="Section12">
        <h2>Cookies</h2>
        <p>Wir setzen keine Cookies ein.</p>
    </section>
    <section id="Section13">
        <h2>Webanalyse</h2>
        <p>Wir setzen keine Webanalysetools ein.</p>
    </section>
    <section id="Section14">
        <h2>Social Media</h2>
        <p>Ein Einsatz von social media findet nicht statt.</p>
    </section>
    <section id="Section15">
        <h2>Weitere Funktionen und Inhalte</h2>
        <p>Sollten wir weitergehende Funktionen und Inhalte (z.B. Karten- oder Schriftdienste) auf unserer Webseite
            einsetzen, mittels deren wir oder der Anbieter der Dienste personenbezogene Daten von Ihnen verarbeiten,
            informieren wir Sie an dieser Stelle darüber.</p>
        <h3>Google Fonts</h3>
        <p>Auf unserer Webseite wird ein Service des Unternehmens Google Ireland Limited, Gordon House, Barrow Street,
            Dublin 4, Ireland eingesetzt („Google Fonts“). Google Fonts dient dem Einsatz von für unseren Webauftritt
            optimierten Schriftarten. Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs.1 f) DSGVO. Das
            berechtigte Interesse besteht in der Sicherstellung der Funktionalität unserer Internetseite. Dabei gibt Ihr
            Browser auch personenbezogene Daten an Google LLC. in die USA weiter. Nähere Informationen über die
            Datenverarbeitung durch Google finden Sie hier:
            <a href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</a>
        </p>
    </section>
    <section id="Section16">
        <h2>Empfänger und Datenübermittlung</h2>
        <p>
            Bestimmte Datenverarbeitungsvorgänge haben wir in unserer Unternehmung gebündelt. Diese können zentral durch
            unsere einzelnen Unternehmensbereiche z.B. zur Bearbeitung von Anfragen wahrgenommen werden. Zur
            Sicherstellung unserer Aufgaben und zur Vertragserfüllung können auch externe Auftragnehmer und
            Dienstleister (z.B. Logistikunternehmen oder IT-Dienstleister) zum Einsatz kommen. Darüber hinaus können
            Daten an Empfänger gehen, an die wir aufgrund vertraglicher oder gesetzlicher Pflichten oder aufgrund Ihrer
            Einwilligung zur Weitergabe verpflichtet oder berechtigt sind.
        </p>
    </section>
    <section id="Section17">
        <h2>Datenübermittlung in Drittländer</h2>
        <p>
            Eine Datenübermittlung in Drittstaaten (Staaten außerhalb der EU und des Europäischen Wirtschaftsraums EWR)
            findet nur statt, insoweit dies für Durchführung eines Vertrags/Auftrags/der Geschäftsbeziehung
            einschließlich der Anbahnung erforderlich oder durch unser berechtigtes Interesse bzw. aufgrund Ihrer
            Einwilligung zulässig ist und nur unter Beachtung der hierfür vorgeschriebenen datenschutzrechtlichen
            Voraussetzungen.
        </p>
        <h3 style="text-decoration: underline;">Hinweis zur Datenweitergabe in die USA</h3>
        <p>In unserem Internetauftritt sind Dienste von Unternehmen mit Sitz in den USA eingebunden. Im Rahmen des
            Einsatzes dieser Dienste können personenbezogenen Daten an US-Server der jeweiligen Dienstanbieter
            übermittelt werden. Wir weisen darauf hin, dass die USA kein sicherer Drittstaat im Sinne des
            EU-Datenschutzrechts sind. US-Unternehmen sind dazu verpflichtet, personenbezogene Daten an
            Sicherheitsbehörden herauszugeben, ohne dass Sie als Betroffener hiergegen ausreichend gerichtlich vorgehen
            könnten. Es kann daher nicht gänzlich ausgeschlossen werden, dass US-Behörden (z.B. Geheimdienste) Ihre auf
            US-Servern befindlichen Daten zu Überwachungszwecken verarbeiten, auswerten und dauerhaft speichern. Wir
            haben auf diese Verarbeitungstätigkeiten keinen Einfluss.</p>
    </section>
    <section id="Section18">
        <h2>Löschung Ihrer Daten</h2>
        <p>
            Wir verarbeiten Ihre personenbezogenen Daten nur so lange, wie dies zur jeweiligen Zweckerfüllung
            erforderlich ist, bzw. bis eine Rechtsgrundlage für die Verarbeitung (z.B. Widerruf einer Einwilligung zur
            Datenverarbeitung) nicht mehr besteht. Wir beachten dabei die bestehenden gesetzlichen Aufbewahrungs- und
            Speicherfristen.
        </p>
    </section>
    <section id="Section19">
        <h2>Ihre Rechte</h2>
        <p>Sie haben das Recht:</p>
        <ul>
            <li>unentgeltlich <strong>Auskunft</strong> über die von uns über Ihre Person gespeicherten
                personenbezogenen Daten zu
                erhalten (Auskunftsrecht)</li>
            <li>eine <strong>Bestätigung</strong> darüber zu verlangen, ob wir Sie betreffende personenbezogene Daten
                verarbeiten (Recht
                auf Bestätigung)</li>
            <li>von uns zu verlangen, dass die Sie betreffenden personenbezogenen Daten von uns unverzüglich gelöscht
                werden, sofern deren Verarbeitung nicht mehr erforderlich ist und auch die weiteren Voraussetzungen der
                DSGVO für eine <strong>Löschung</strong> erfüllt sind (Recht auf Löschung) </li>
            <li>die sofortige <strong>Berichtigung</strong> und <strong>Vervollständigung</strong> Sie betreffender
                unrichtiger personenbezogenen Daten
                zu verlangen (Recht auf Berichtigung)</li>
            <li>die <strong>Einschränkung</strong> der Verarbeitung Ihrer personenbezogenen Daten zu verlangen (Recht
                auf Einschränkung
                der Verarbeitung)</li>
            <li>die Sie betreffenden personenbezogenen Daten in einem strukturierten, gängigen und maschinenlesbaren
                Format zu erhalten (Recht auf <strong>Datenübertragbarkeit</strong>)</li>
            <li>der Verarbeitung Ihrer personenbezogenen Daten zu widersprechen (Recht auf <strong>Widerspruch</strong>)
            </li>
            <li>Sie haben das Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung – einschließlich
                Profiling – beruhenden Entscheidung unterworfen zu werden, die Ihnen gegenüber rechtliche Wirkung
                entfaltet oder Sie in ähnlicher Weise erheblich beeinträchtigt (<strong>Recht auf Entscheidung im
                    Einzelfall</strong>).
            </li>
            <li>jederzeit Ihre <strong>Einwilligung</strong> in die Verarbeitung Ihrer personenbezogenen Daten mit
                Wirkung für die
                Zukunft zu <strong>widerrufen.</strong></li>
            <li>sich bei der für den Datenschutz zuständigen Aufsichtsbehörde zu beschweren, wenn Sie der Ansicht sind,
                dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DSGVO verstößt
                (<strong>Beschwerderecht</strong>). </li>
        </ul>
        <p>
            Für weitergehenden Informationen zu Ihren Rechten wenden Sie sich bitte an unseren Datenschutzbeauftragten.
        </p>
    </section>
    <section id="Section20">
        <h2>Änderung unserer Datenschutzerklärung</h2>
        <p>
            Um zu gewährleisten, dass unsere Datenschutzerklärung stets den aktuellen gesetzlichen Vorgaben entspricht,
            behalten wir uns jederzeit Änderungen vor. Das gilt auch für den Fall, dass die Datenschutzerklärung
            aufgrund neuer oder überarbeiteter Leistungen, zum Beispiel neuer Serviceleistungen, angepasst werden muss.
            Die neue Datenschutzerklärung greift dann bei Ihrem nächsten Besuch auf unserem Angebot.
        </p>
    </section>
</div>

<div fxLayout="column" fxLayoutAlign="start start" class="contentarea" style="padding: 50px;" *ngIf="locale !== 'de'">

    <section>
        <h1>Privacy policy</h1>
        <p>
            We are pleased about your visit to our website. The protection of your personal data is an important concern
            for us and we want you to feel safe when you visit our website. We protect your privacy and your personal
            data. We process your personal data in accordance with the content of this privacy policy and in compliance
            with the applicable data protection regulations of the German Data Protection Act (DSGVO – German equivalent
            to GDPR) or the national provisions of the German Federal Data Protection Act (BDSG).
        </p>
    </section>
    <section>
        <h2>Table of contents</h2>
        <ul>
            <li (click)="scroll('Section1_en')" class="contentLink">Name and contact details of the person in charge
            </li>
            <li (click)="scroll('Section2_en')" class="contentLink">Contact the data protection officer</li>
            <li (click)="scroll('Section3_en')" class="contentLink">What are personal data?</li>
            <li (click)="scroll('Section4_en')" class="contentLink">Aims of data processing</li>
            <li (click)="scroll('Section5_en')" class="contentLink">Legal basis of the data processing</li>
            <li (click)="scroll('Section6_en')" class="contentLink">Use of our website for information purposes</li>
            <li (click)="scroll('Section7_en')" class="contentLink">Use of our website for other services</li>
            <li (click)="scroll('SectionInterfaces_en')" class="contentLink">Interfaces</li>
            <li (click)="scroll('Section8_en')" class="contentLink">SAP Business One Cloud - Test Portal</li>
            <li (click)="scroll('E-Rechnung_en')" class="contentLink">e-invoice viewer</li>
            <li (click)="scroll('Section9_en')" class="contentLink">Contact to us</li>
            <li (click)="scroll('Section10_en')" class="contentLink">Newsletter</li>
            <li (click)="scroll('Section11_en')" class="contentLink">Security</li>
            <li (click)="scroll('Section12_en')" class="contentLink">Cookies</li>
            <li (click)="scroll('Section13_en')" class="contentLink">Web analysis</li>
            <li (click)="scroll('Section14_en')" class="contentLink">Social media</li>
            <li (click)="scroll('Section15_en')" class="contentLink">Further functions and contents</li>
            <li (click)="scroll('Section16_en')" class="contentLink">Receiver and data transmission</li>
            <li (click)="scroll('Section17_en')" class="contentLink">Data transfer to third countries</li>
            <li (click)="scroll('Section18_en')" class="contentLink">Deletion of your data</li>
            <li (click)="scroll('Section19_en')" class="contentLink">Your rights</li>
            <li (click)="scroll('Section20_en')" class="contentLink">Amendment of our data protection declaration</li>
        </ul>
    </section>
    <section id="Section1_en">
        <h2>Name and contact details of the person in charge</h2>
        <p>
            The conesprit GmbH, Hans-Gaugler-Weg 14, 71522 Backnang is as operator of the website <a
                href="https://www.business-one.cloud/en/">https://www.business-one.cloud/en/</a> responsible in the
            sense of the DSGVO.
        </p>
    </section>
    <section id="Section2_en">
        <h2>Contact to our data protection officer</h2>
        <p>
            You can contact our data protection officer <a href="mailto:datenschutz@business-one-consulting.com">Katrin
                Douverne datenschutz@business-one-consulting.com</a> at any time with all data protection issues.
        </p>
    </section>
    <section id="Section3_en">
        <h2>What is personal data?</h2>
        <p>
            Personal data is any information relating to an identified or identifiable natural person; an identifiable
            person is one who can be identified, directly or indirectly, in particular by reference to an identifier
            such as a name, an identification number, location data, an online identifier, or one or more factors
            specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that
            natural person.
        </p>
    </section>
    <section id="Section4_en">
        <h2>Aims of data processing</h2>
        <p>The extent and nature of the collection, processing and use of your data differs depending on whether you
            visit our website merely to call up generally available information or to make use of additional services.
            In principle, we process your personal data within the scope of our business activities for pre-contractual
            or contractual purposes. In addition, the exercise of our legitimate interest or compliance with legal
            requirements may also be the purpose of data processing by us. We will inform you about the respective
            concrete purposes of data processing in the following sections.
        </p>
    </section>
    <section id="Section5_en">
        <h2>Legal basis of the data processing</h2>
        <p>We process your personal data according to the following legal principles:
        </p>
        <ul>
            <li>To fulfill pre-contractual or contractual obligations (Art. 6 para. 1b) DSGVO)</li>
            <li>Based on your consent (Art. 6 para. 1a) DSGVO)</li>
            <li>In the context of a balancing of interests (Art. 6 para. 1f) DSGVO)</li>
            <li>Due to legal requirements (Art. 6 para. 1c) DS-GVO)</li>
        </ul>
        <p>
            In addition, depending on the type and scope of processing, further legal bases may exist according to
            country-specific regulations. We will inform you about the specific legal basis of data processing when we
            process your data.
        </p>
    </section>
    <section id="Section6_en">
        <h2>Use of our website for information purposes</h2>
        <p>For a purely informational use of our website, it is generally not necessary for you to provide personal
            data. Rather, when you call up our website, we only record the data that your Internet browser automatically
            transmits to us, such as
        </p>
        <ul>
            <li>the IP address of your computer</li>
            <li>Date and time of page view</li>
            <li>Your browser type, the browser version and your browser settings</li>
            <li>the operating system used (Windows, iOS, Linux etc.)</li>
            <li>the transferred data volume and status of transfers</li>
            <li>from which website the access to our site was made</li>
            <li>other similar data and information which serve to avert danger in the event of attacks on our
                information technology systems.</li>
        </ul>
        <p>
            This is usually done through the use of log files. The purpose of the processing is to ensure the
            functionality and compatibility of our website for a technically unproblematic use including troubleshooting
            as well as the protection against technical attacks and misuse of the use. The legal basis for this
            processing is our legitimate interest according to Art. 6 para. 1 f) DSGVO. Our legitimate interest lies in
            the proper operation of our website. The logfile data will be deleted when they are no longer required for
            the purpose of processing.
        </p>
    </section>
    <section id="Section7_en">
        <h2>Use of our website for other services</h2>
        <p>If you make use of further services of our company via our website, it may be necessary for you to provide
            personal data. Which personal data is required to provide the service is determined by the respective input
            dialog or application. You can provide further information voluntarily. You can tell which information is
            required and which is voluntary by the fact that the mandatory information is marked with an asterisk (*) or
            with the note "required". The processing of your data is always carried out exclusively for the purpose of
            providing the service you have requested. In addition, additional purposes for data processing may exist in
            individual cases. The respective purpose and the legal basis for the processing of your personal data as
            well as information on when your personal data will be deleted will be provided in the description of the
            specific services.
        </p>
    </section>
    <section id="SectionInterfaces_en">
        <h2>Interfaces</h2>
        <p>
            The SAP Business One Cloud supports interfaces to the web shop providers Shopify, Shopware, WooCommerce, OXID and BigCommerce as well as to the Amazon marketplace.
            The exchange of data between the SAP Business One Cloud and the interface partners is always encrypted (HTTPS/TLS V1.2). Algorithms with strong keys are used (AES-128 or stronger).
            Personal data is only held in the interfaces for data transmission purposes. They are not transferred to third parties (other than to the named interface partners). Personal data is not archived. They will be deleted after 31 days at the latest.
        </p>
    </section>
    <section id="E-Rechnung_en">
        <h2>e-invoice viewer</h2>
        <p>
            On our website, you can use our service, the "e-invoice viewer": <a href="https://www.business-one.cloud/e-rechnung">https://www.business-one.cloud/e-rechnung</a>. 
            This tool enables the readability of electronic invoices, known as "e-invoices." 
            An e-Invoice is a structured data file created, transmitted, and received in an electronic format that adheres to specific standards. 
            This dataset must comply with the European standard EN16931 for electronic invoicing.
            You can simply drag and drop your E-Invoice (e.g., invoice-example.xml) into our "e-invoice viewer" to display its content in a readable format. 
            Afterward, you can save or print the invoice as needed.
            We do not store your e-invoice on our local servers or in the cloud. 
            At no point during the processing for readability purposes do we access or view your e-Invoice. Additionally, no third parties or processors are involved in the process. 
            Once you close the " e-invoice viewer," your e-invoice is automatically deleted.
        </p>
    </section>
    <section id="Section8_en">
        <h2>SAP Business One Cloud - Test Portal</h2>
        <p>
            On our website you can test our service "SAP Business One Cloud" as a demo system. To register for the demo
            system, it is necessary to enter personal data. Which personal data is required is determined by the
            respective input dialog. You can provide further information voluntarily. You can see which information is
            required and which is voluntary by the fact that the mandatory information is marked with an asterisk (*).
            Your data is processed for the purpose to provide to you the "SAP Business One Cloud" service as a demo
            system. Within the scope of this earmarking, we are also entitled to transfer your personal data to our
            partner company SAP Deutschland SE & Co. KG, Hasso-Plattner-Ring 7, 69190 Walldorf, Germany. The legal basis
            for this processing is Art. 6 para. 1 b) DSGVO. Your personal data will be deleted when they are no longer
            required to fulfill the purpose of using our test portal.
        </p>
    </section>
    <section id="Section9_en">
        <h2>Contact to us</h2>
        <h3>Contact form</h3>
        <p>On our website we offer you the possibility to get in contact with us by means of a contact form. If you
            click on the button "Contact", you will be redirected to the contact area on the main page of our company.
            The personal data that you enter into the contact form as a result of contacting us will only be processed
            for the purpose of processing your contact. Data will only be passed on to third parties if this is
            necessary for the purpose of processing your contact. The legal basis for this processing is Art. 6 para. 1
            b) DSGVO. Your personal data will be deleted if they are no longer required for the purpose of processing
            your contact. We would like to point out that your messages may have to be stored within the scope of
            legally existing storage obligations. In this case the legal basis is Art. 6 para. 1 c) DSGVO.
        </p>
        <h3>Contact by mail</h3>
        <p>
            On our website we offer you the possibility to contact us by e-mail. Please note that unencrypted
            communication via e-mail is not secure. It cannot be excluded that data transmitted in this way may be read,
            copied, changed or deleted by unauthorized persons. The personal data that you provide when contacting us
            via e-mail will only be processed for the purpose of processing your e-mail request. Data will only be
            passed on to third parties if this is necessary for the purpose of processing this contact. The legal basis
            for this processing is Art. 6 para. 1 b) DSGVO. Your personal data will be deleted if they are no longer
            required for the purpose of fulfilling your contact. We would like to point out that your messages may have
            to be stored within the scope of legally existing storage obligations. In this case the legal basis is Art.
            6 para. 1 c) DSGVO.
        </p>
    </section>
    <section id="Section10_en">
        <h2>Newsletter</h2>
        <p>No newsletter exists.
        </p>
    </section>
    <section id="Section11_en">
        <h2>Security</h2>
        <p>We have secured our website and other systems by technical and organizational measures against loss,
            destruction, access, modification or distribution of your data by unauthorized persons. In particular, your
            personal data provided in the contact form will be transmitted in encrypted form. We use the coding system
            TLS 1.2 (Transport Layer Security).
        </p>
    </section>
    <section id="Section12_en">
        <h2>Cookies</h2>
        <p>We do not use cookies.
        </p>
    </section>
    <section id="Section13_en">
        <h2>Web Analysis</h2>
        <p>We do not use web analytics tools.
        </p>
    </section>
    <section id="Section14_en">
        <h2>Social media</h2>
        <p>A use of social media does not take place.
        </p>
    </section>
    <section id="Section15_en">
        <h2>Further functions and contents</h2>
        <p>Should we use further functions and contents (e.g. map or writing services) on our website, by means of which
            we or the provider of the services process personal data from you, we will inform you about this here.
        </p>
        <h3>Google Fonts</h3>
        <p>
            Our website uses a service provided by Google Ireland Limited, Gordon House, Barrow Street, Dublin 4,
            Ireland ("Google Fonts"). Google Fonts is used for the use of fonts optimized for our website. The legal
            basis for data processing is Art. 6 para. 1 f) DSGVO. The legitimate interest is to ensure the functionality
            of our website. In doing so, your browser also passes on personal data to Google LLC. in the USA. You can
            find more information about data processing by Google here: <a
                href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</a>
        </p>
    </section>
    <section id="Section16_en">
        <h2>Receiver and data transmission</h2>
        <p>We have bundled certain data processing procedures in our company. These can be carried out centrally by our
            individual company divisions, e.g. to process inquiries. External contractors and service providers (e.g.
            logistics companies or IT service providers) may also be used to ensure our tasks and to fulfill contracts.
            In addition, data may be passed on to recipients to whom we are obliged or entitled to pass on data due to
            contractual or legal obligations or due to your consent.
        </p>
    </section>
    <section id="Section17_en">
        <h2>Transfer of data to third countries</h2>
        <p>Data will only be transferred to third countries (countries outside the EU and the European Economic Area
            EEA) if this is necessary for the execution of a contract/order/business relationship, including the
            initiation of such a relationship, or if it is permitted by our legitimate interest or with your consent,
            and only in compliance with the data protection requirements prescribed for this purpose.
        </p>
        <h3 style="text-decoration: underline;">Note on data transfer to the USA</h3>
        <p>
            Services of companies based in the USA are integrated into our Internet presence. Within the scope of using
            these services, personal data may be transmitted to US servers of the respective service providers. We would
            like to point out that the USA is not a safe third country in terms of EU data protection law. US companies
            are obliged to release personal data to security authorities without you as the person concerned being able
            to take sufficient legal action against this. Therefore, it cannot be completely excluded that US
            authorities (e.g. secret services) may process, evaluate and permanently store your data on US servers for
            monitoring purposes. We have no influence on these processing activities.
        </p>
    </section>
    <section id="Section18_en">
        <h2>Deletion of your data</h2>
        <p>We process your personal data only as long as it is necessary to fulfil the respective purpose or until a
            legal basis for the processing (e.g. revocation of a consent for data processing) no longer exists. We obey
            the existing legal retention and storage periods.
        </p>
    </section>
    <section id="Section19_en">
        <h2>Your rights</h2>
        <p>You have the right:
        </p>
        <ul>
            <li>to receive <strong>information</strong> free of charge about the personal data we have stored about you
                (right to
                information)</li>
            <li>to request <strong>confirmation</strong> as to whether we are processing personal data concerning you
                (right to
                confirmation)</li>
            <li>to demand from us that the personal data relating to you be deleted by us without delay, insofar as
                processing of such data is no longer necessary and the other requirements of the DSGVO for
                <strong>deletion</strong> are
                also fulfilled (right to deletion) </li>
            <li>to demand the immediate <strong>correction</strong> and <strong>completion</strong> of incorrect
                personal data concerning you (right to
                correction)</li>
            <li>to request the <strong>limitation</strong> of the processing of your personal data (right to limit the
                processing)</li>
            <li>to receive the personal data concerning you in a structured, common and machine-readable format (right
                to <strong>data transferability</strong>)</li>
            <li>to object to the processing of your personal data (<strong>right to object</strong>)</li>
            <li>You have the right not to be subjected to a decision based solely on automated processing - including
                profiling - which has legal effect on you or which affects you significantly in a similar way
                (<strong>right to
                    decision in individual cases</strong>).</li>
            <li>to <strong>withdraw</strong> your <strong>consent</strong> to the processing of your personal data at
                any time with effect for the
                future.</li>
            <li>to complain to the supervisory authority responsible for data protection if you are of the opinion that
                the processing of your personal data violates the DSGVO (<strong>right to complain</strong>). </li>
        </ul>
        <p>
            For further information on your rights, please contact our data protection officer.
        </p>
    </section>
    <section id="Section20_en">
        <h2>Amendment of our data protection declaration</h2>
        <p>In order to ensure that our data protection declaration always complies with the current legal requirements,
            we reserve the right to make changes at any time. This also applies in the event that the data protection
            declaration has to be adapted due to new or revised services, for example new services. The new data
            protection declaration will then take effect the next time you visit our website.
        </p>
    </section>
</div>